import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Box, Select, MenuItem, Checkbox, Grid } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import { useMutation, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import { ALL_REGIONS } from 'utils/constants';

const EditUser = ({ isOpen, onClose, accountId, user, loggedInUser }) => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email || '');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedAbilities, setSelectedAbilities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const { setToast } = useContext(ToastDataContext);

  const availableAbilities = (() => {
    if (user.availableAbilities?.length > 0) {
      return [...user.availableAbilities].sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  })();

  const assignableRoles = (() => {
    if (loggedInUser.assignableRoles?.length > 0) {
      return [...loggedInUser.assignableRoles].sort((a, b) => a.description.localeCompare(b.description));
    }
    return [];
  })();

  const isAllRegionsSelected = (list) => {
    for (let i = 0; i < list.length; i += 1) {
      if (list[i].id === ALL_REGIONS) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const regionsToUse = user.availableRegions.concat();
    regionsToUse.unshift({ id: ALL_REGIONS, name: 'All', nativeName: 'All Regions' });
    setRegions(regionsToUse);

    const chosenRegions = [];
    for (let i = 0; i < user.configuredRegions?.length; i += 1) {
      for (let j = 0; j < regionsToUse.length; j += 1) {
        if (regionsToUse[j].id === user.configuredRegions[i].id) {
          chosenRegions.push(regionsToUse[j]);
          break;
        }
      }
    }
    setSelectedRegions(chosenRegions);
  }, []);

  const UPDATE_USER_MUTATION = gql`
    mutation UpdateUser($id: ID!, $accountId: ID!, $email: String, $firstName: String, $lastName: String, $roles: [Int], $regions: [String], $abilities: [String]) {
      updateUserInAccount(id: $id, accountId: $accountId, email: $email, firstName: $firstName, lastName: $lastName, roles: $roles, regions: $regions, abilities: $abilities) 
    }
  `;

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  // validate form values
  const validateForm = () => {
    const errors = [];
    if (
      email.length < 6 ||
      !/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      errors.push({ email: 'Invalid email address format' });
    }
    if (firstName.length > 25) {
      errors.push({ firstName: 'First name must be under 25 characters' });
    }
    if (lastName.length > 25) {
      errors.push({ lastName: 'Last name must be under 25 characters' });
    }
    return errors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // validate form input
    const errors = validateForm();
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));

    const roles = [];
    let updatedRoles = [];
    for (let i = 0; i < selectedRoles.length; i += 1) {
      for (let j = 0; j < assignableRoles.length; j += 1) {
        if (assignableRoles[j].id === selectedRoles[i]) {
          roles.push(assignableRoles[j].id);
          updatedRoles.push(assignableRoles[j]);
          break;
        }
      }
    }
    // Now add roles to the updatedRules that this user isn't allowed to touch
    for (let i = 0; i < user.roles.length; i += 1) {
      let found = false;
      for (let j = 0; j < assignableRoles?.length; j += 1) {
        if (assignableRoles[j].id === user.roles[i].id) {
          found = true;
          break;
        }
      }
      if (!found) {
        updatedRoles.push(user.roles[i]);
        break;
      }
    }

    updatedRoles = [...updatedRoles].sort((a, b) => a.description.localeCompare(b.description));
    const updatedRegions = isAllRegionsSelected(selectedRegions) ? { id: ALL_REGIONS } : selectedRegions;
    const updatedAbilities = availableAbilities.filter(a => {
      let found = false;
      for (let i = 0; i < selectedAbilities.length; i += 1) {
        if (a.id === selectedAbilities[i]) {
          found = true;
          break;
        }
      }
      return found;
    });

    if (errors.length === 0) {
      try {
        const regionsToUse = isAllRegionsSelected(selectedRegions) ? [ALL_REGIONS] : selectedRegions.map(r => r.id);
        updateUser({
          variables: {
            id: user.id,
            accountId,
            email,
            firstName,
            lastName,
            roles,
            regions: regionsToUse,
            abilities: selectedAbilities,
          },
          onCompleted: () => {
            setLoading(false);
            setToast({ type: 'success', message: "User updated" });
            onClose();
          },
          update(cache) {
            cache.modify({
              id: cache.identify(user),
              fields: {
                email() {
                  return email;
                },
                firstName() {
                  return firstName;
                },
                lastName() {
                  return lastName;
                },
                roles() {
                  return updatedRoles;
                },
                configuredAbilities() {
                  return updatedAbilities;
                },
                configuredRegions() {
                  return updatedRegions;
                }
              },
            });
          },
        });
        setLoading(true);
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const roles = [];
    for (let i = 0; i < assignableRoles?.length; i += 1) {
      for (let j = 0; j < user.roles?.length; j += 1) {
        if (assignableRoles[i].id === user.roles[j].id) {
          roles.push(user.roles[j].id);
          break;
        }
      }
    }
    setSelectedRoles(roles);
    const abilities = [];
    for (let i = 0; i < availableAbilities?.length; i += 1) {
      for (let j = 0; j < user.configuredAbilities?.length; j += 1) {
        if (availableAbilities[i].id === user.configuredAbilities[j].id) {
          abilities.push(user.configuredAbilities[j].id);
          break;
        }
      }
    }
    setSelectedAbilities(abilities);
  }, []);

  return (
    <CustomDialog
      title="Edit User"
      open={isOpen}
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      content={
        <CustomDialogContent>
          <form id="asin-price" onSubmit={handleSubmit}>
            {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Grid container sx={12} spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    disabled={loading}
                    required
                    id="user-profile-firstname"
                    data-cy="profile_firstname"
                    label="First Name"
                    type="text"
                    value={firstName || ''}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={Boolean(formErrors.firstName)}
                    helperText={formErrors.firstName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    disabled={loading}
                    required
                    id="user-profile-lastname"
                    data-cy="profile_lastname"
                    label="Last Name"
                    type="text"
                    value={lastName || ''}
                    onChange={(e) => setLastName(e.target.value)}
                    error={Boolean(formErrors.lastName)}
                    helperText={formErrors.lastName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={loading}
                    required
                    id="user-profile-email"
                    data-cy="profile_email"
                    label="Email"
                    type="text"
                    value={email || ''}
                    onChange={(e) => setEmail(e.target?.value?.trim())}
                    error={Boolean(formErrors.email)}
                    helperText={formErrors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  {assignableRoles?.length > 0 && (
                    <Box>
                      <Box sx={{ color: 'colors.black', fontWeight: '600', mb: 0.5, mt: -0.5 }}>Roles</Box>
                      <Select
                        disabled={loading}
                        displayEmpty
                        multiple
                        value={selectedRoles}
                        fullWidth
                        sx={{ minWidth: '15vw', marginTop: '0px' }}
                        renderValue={(selected) => {
                          const values = [];
                          for (let i = 0; i < selected.length; i += 1) {
                            for (let j = 0; j < assignableRoles.length; j += 1) {
                              if (assignableRoles[j].id === selected[i]) {
                                values.push(assignableRoles[j].description);
                                break;
                              }
                            }
                          }
                          if (values.length === 0) {
                            return 'Default';
                          }
                          return values.join(', ');
                        }}
                        onChange={(e) => {
                          const { value } = e.target;
                          setSelectedRoles(value);
                        }}
                        data-cy="profile_roles"
                      >
                        {assignableRoles.map((r) => (
                          <MenuItem key={r.id} value={r.id}>
                            <Checkbox
                              checked={selectedRoles.includes(r.id)}
                            />
                            {r.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {availableAbilities?.length > 0 && (
                    <Box>
                      <Box sx={{ color: 'colors.black', fontWeight: '600', mb: 0.5, mt: -0.5 }}>Abilities</Box>
                      <Select
                        disabled={loading}
                        displayEmpty
                        multiple
                        value={selectedAbilities}
                        fullWidth
                        sx={{ minWidth: '15vw', marginTop: '0px' }}
                        renderValue={(selected) => {
                          const values = [];
                          for (let i = 0; i < selected.length; i += 1) {
                            for (let j = 0; j < availableAbilities.length; j += 1) {
                              if (availableAbilities[j].id === selected[i]) {
                                values.push(availableAbilities[j].name);
                                break;
                              }
                            }
                          }
                          if (values.length === 0) {
                            return 'Default';
                          }
                          values.sort((a, b) => a.localeCompare(b));
                          return values.join(', ');
                        }}
                        onChange={(e) => {
                          const { value } = e.target;
                          setSelectedAbilities(value);
                        }}
                        data-cy="profile_roles"
                      >
                        {availableAbilities.map((a) => (
                          <MenuItem key={a.id} value={a.id}>
                            <Checkbox
                              checked={selectedAbilities.includes(a.id)}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Box sx={{fontWeight: 500}}>
                                {a.name}
                              </Box>
                              <Box sx={{ fontSize: '11px' }}>
                                {a.description}
                              </Box>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black', fontWeight: '600', mb: .5, mt: -0.5 }}>Regions</Box>
                  <Select
                    disabled={loading}
                    displayEmpty
                    multiple
                    value={selectedRegions}
                    fullWidth
                    sx={{ minWidth: '15vw', marginTop: '0px' }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return 'None';
                      }
                      if (isAllRegionsSelected(selectedRegions)) {
                        return 'All Available Regions';
                      }
                      return selected.map(r => `${r.name}`).join(', ');
                    }}
                    onChange={(e) => {
                      const { value } = e.target;
                      setSelectedRegions(value);
                    }}
                    data-cy="profile_roles"
                  >
                    {regions.map((r) => (
                      <MenuItem key={r.id} value={r} disabled={isAllRegionsSelected(selectedRegions) && r.id !== ALL_REGIONS} divider={r.id === ALL_REGIONS}>
                        <Checkbox
                          size="small"
                          sx={{ p: 0.5, m: 0 }}
                          checked={selectedRegions.includes(r)}
                        />
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          {r.id !== ALL_REGIONS && (
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                              <img
                                style={{ minWidth: '16px', minHeight: '16px', filter: 'drop-shadow(0px 0px 0px black)' }}
                                alt={`${r.id} flag`}
                                src={`https://flagsapi.com/${r.iso}/flat/16.png`}
                              />
                            </Box>
                          )}
                          <Box sx={{ fontWeight: r.id !== ALL_REGIONS ? 400 : 700 }}>
                            {r.id === ALL_REGIONS && 'All Available Regions'}
                            {r.id !== ALL_REGIONS && `${r.name} (${r.nativeName})`}
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>
          </form>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading} variant="outlined" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <LoadingButton form="asin-price" loading={loading} variant="contained" type="submit" data-cy="save_user_button">
            Save User
          </LoadingButton>
        </>
      }
    />
  );
};

EditUser.defaultProps = {
  isOpen: false,
};

EditUser.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loggedInUser: PropTypes.oneOfType([PropTypes.object]).isRequired,
  accountId: PropTypes.number.isRequired,
};

export default EditUser;
