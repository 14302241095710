import { Auth } from 'aws-amplify';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { LOCAL_STORAGE_AMAZON_REGION_SUFFIX } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` });

const authLink = setContext(async (_, { headers }) => {
  const token = (await Auth.currentSession()).getIdToken();
  const payload = token?.payload;
  if (payload) {
    const context = JSON.stringify(payload);
    const key = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: payload["custom:UserID"], suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX });
    let amazonRegion = localStorage.getItem(key);
    if (process.env.REACT_APP_TEST_RUNNING === "yes") {
      if (amazonRegion === null) {
        amazonRegion = "USA"
      }
    }
    return {
      headers: {
        ...headers,
        AmazonRegion: amazonRegion,
        Authorization: token?.jwtToken,
        context,
      },
    };
  }
  return {};
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

const client = new ApolloClient({
  link: authLink.concat(ApolloLink.from([errorLink, httpLink])),
  cache: new InMemoryCache({
    typePolicies: {
      AlertRule: {
        keyFields: ["id", "alertRuleTypeId"],
      },
      SellerType: {
        keyFields: ["uniqueId"],
      },
      Account: {
        fields: {
          users: {
            merge(existing, incoming) {
              return incoming;
            },
          }
        }
      },
      SellerList: {
        fields: {
          currentScore: {
            merge(existing, incoming) {
              return incoming;
            },
          }
        }
      },
      DiscoveryASIN: {
        fields: {
          removeCriteria: {
            merge(existing, incoming) {
              return incoming;
            },
          }
        }
      },
      Query: {
        fields: {
          getCategoryList: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getAsinsForCategory: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getAmazonOrders: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getSellerAddresses: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getEnforcementQueueItems: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getDiscoveryAsins: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getDiscoveryRemovalCriteria: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getAllAsinsAndCategories: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          getSellersSimple: {
            merge(existing, incoming) {
              return incoming;
            },
          }
        },
      },
    },
  }),
  defaultOptions,
});

export default client;
