const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_WEB_URL}/`,
      redirectSignOut: `${process.env.REACT_APP_WEB_URL}/`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  cookieStorage: {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    secure: process.env.REACT_APP_COOKIE_SECURE === 'true',
    path: '/',
    expires: 365,
  },
};

export default awsmobile;
