import React, { useState, useEffect } from 'react';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import {
  DialogContentText,
  Button,
  Grid,
  Link,
  InputAdornment,
  Checkbox,
  TextField,
  Box,
  Select,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import highlightWords from 'highlight-words';
import productService from 'services/product-service';
import accountService from 'services/account-service';

const OnboardingAsinLookup = ({ isOpen = false, onClose = () => {}, onAddAsins = () => {} }) => {
  const [open, setOpen] = useState(isOpen);
  const [previousOpen, setPreviousOpen] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [sortBy, setSortBy] = useState('featured');
  const [storeId, setStoreId] = useState(null);
  const [searchPagination, setSearchPagination] = useState(null);
  const [existingAsins, setExistingAsins] = useState([]);
  const theme = useTheme();

  const itemsSelected = searchResults.reduce((a, b) => (a + (b.selected || false) ? 1 : 0), 0);

  const handleClose = () => {
    setFormErrors({});
    setSearchResults([]);
    setSearch('');
    onClose();
  };

  if (isOpen !== previousOpen) {
    setOpen(isOpen);
    setPreviousOpen(isOpen);
  }

  const loadExistingAsins = async () => {
    setExistingAsins(await accountService.getAsinNumbersForAccount());
  };

  useEffect(async () => {
    loadExistingAsins();
  }, []);

  // validate form values
  const validateForm = ({ validateSearch }) => {
    const errors = [];
    if (!validateSearch) {
      errors.push({ search: 'Brand Product Name Required' });
    }
    if (validateSearch.length > 100) {
      errors.push({ search: 'Brand Product Name must be 100 characters or less.' });
    }
    return errors;
  };

  const setHighlightChunks = (searchResultsToChunk, query) => {
    for (let i = 0; i < searchResultsToChunk.length; i += 1) {
      const searchResult = searchResultsToChunk[i];
      const chunks = highlightWords({
        text: searchResult.title || '(No Title Available)',
        query,
        matchExactly: false,
        clipBy: false,
      });
      searchResult.chunks = chunks;
    }
  };

  const searchProducts = async ({ pageNum = 1 }) => {
    // clear results if pagenum =1
    if (pageNum === 1) setSearchResults([]);
    const errors = validateForm({ validateSearch: search });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        const productSearchResult = await productService.productSearch({
          search,
          pageNum,
          sortBy,
        });
        setStoreId(productSearchResult?.storeId);
        if (pageNum === 1) {
          // rainforest api result sets cannot be trusted
          const results = productSearchResult?.searchResults.reduce((acc, sr) => {
            if (!acc.find((newSr) => newSr.asin === sr.asin)) {
              acc.push(sr);
            }
            return acc;
          }, []);
          setHighlightChunks(results, search);
          setSearchResults(results);
        } else {
          // rainforest api result sets cannot be trusted
          const moreResults = productSearchResult?.searchResults.filter(
            (newResult) =>
              !searchResults.find((existingResult) => existingResult.asin === newResult.asin),
          );
          const results = [...searchResults, ...moreResults];
          setHighlightChunks(results, search);
          setSearchResults(results);
        }

        const { currentPage, totalPages, totalResults } = productSearchResult;
        if (currentPage && totalPages && totalResults && currentPage < totalPages) {
          setSearchPagination({ currentPage, totalPages, totalResults });
        } else {
          setSearchPagination({ totalResults: productSearchResult?.searchResults?.length });
        }
        setLoading(false);
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <CustomDialog
      sx={{ '& .MuiDialog-paper': { minHeight: '80vh', maxHeight: '80vh' } }}
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
      scroll="paper"
      title="Amazon ASIN Lookup"
      subtitle={
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box component="p" sx={{ fontSize: '14px' }}>
            <Typography>
              Enter product name, keywords, or your Store ID ...{' '}
              <i>(Store ID lookup may take a few minutes)</i>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }} data-cy="Amazon_ASIN_search">
            <TextField
              disabled={loading}
              autoFocus
              required
              fullWidth
              id="search"
              type="text"
              placeholder="e.g. Titleist TruFeel Golf Balls or A2C7AAA9-148F-4FD5-9D52-C4946527CBC2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              error={Boolean(formErrors.search)}
              helperText={formErrors.search}
              form="lookup-form"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mb: '1px' }}>
                    <SearchIcon fill={theme.palette.primary.main} />
                  </InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                form: 'lookup-form',
                autoFocus: true,
              }}
            />
            <LoadingButton
              sx={{ height: 'auto' }}
              loading={loading || saving}
              type="submit"
              form="lookup-form"
              data-cy="Amazon_ASIN_submit"
            >
              Lookup
            </LoadingButton>
          </Box>
        </Box>
      }
      content={
        <CustomDialogContent>
          <form
            id="lookup-form"
            onSubmit={async (e) => {
              e.preventDefault();
              searchProducts({ pageNum: 1 });
            }}
          >
            <Select value={sortBy} onChange={(e) => setSortBy(e?.target?.value)}>
              <MenuItem value="featured">Featured</MenuItem>
              <MenuItem value="price_low_to_high">Price: Low to High</MenuItem>
              <MenuItem value="price_high_to_low">Price: High to Low</MenuItem>
              <MenuItem value="average_review">Avg. Customer Review</MenuItem>
              <MenuItem value="most_recent">Newest Arrivals</MenuItem>
            </Select>
            <DialogContentText component="div" id="alert-dialog-slide-description">
              {formErrors.formError && <Box>{formErrors.formError}</Box>}
              {searchResults.length > 0 && (
                <Grid container spacing={0}>
                  <h4>
                    {searchPagination?.totalResults === 1
                      ? '1 Result'
                      : `${searchPagination?.totalResults} Results (Listed as presented on Amazon)`}
                  </h4>
                  <Grid container spacing={1} sx={{ color: 'greys.black' }}>
                    {!storeId && (
                      <>
                        <Grid item xs={1} />
                        <Grid item xs={1} />
                      </>
                    )}
                    <Grid item xs={storeId ? 9 : 7}>
                      <h4>Name</h4>
                    </Grid>
                    <Grid item xs={2}>
                      <h4>ASIN</h4>
                    </Grid>
                    <Grid item xs={1}>
                      <h4>Price</h4>
                    </Grid>
                  </Grid>
                  {searchResults &&
                    searchResults.map((searchResult) => (
                      <Grid
                        key={searchResult.asin}
                        container
                        spacing={1}
                        sx={{ alignItems: 'center' }}
                      >
                        {!storeId && (
                          <>
                            <Grid item xs={1}>
                              {!existingAsins.includes(searchResult.asin) && (
                                <Checkbox
                                  color="default"
                                  data-cy="Amazon_ASIN_checkbox"
                                  onChange={(e) => {
                                    setSearchResults(
                                      searchResults.map((newSearchResult) => {
                                        if (newSearchResult.asin === searchResult.asin) {
                                          return { ...newSearchResult, selected: e.target.checked };
                                        }
                                        return newSearchResult;
                                      }),
                                    );
                                  }}
                                  sx={{ color: 'primary.main' }}
                                />
                              )}
                              {existingAsins.includes(searchResult.asin) && (
                                <Checkbox
                                  color="default"
                                  checked
                                  disabled
                                  sx={{ color: 'primary.main' }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={1}>
                              {searchResult.image && (
                                <Box
                                  component="img"
                                  sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    border: `solid 5px ${theme.palette.greys.lightGrey}`,
                                  }}
                                  src={searchResult.image}
                                  alt={searchResult.title}
                                />
                              )}
                            </Grid>
                          </>
                        )}
                        <Grid item xs={storeId ? 9 : 7}>
                          <h4>
                            <Link
                              href={`https://www.amazon.com/dp/${searchResult.asin}`}
                              target="_blank"
                              underline="hover"
                            >
                              {searchResult.sponsored && <em>(Sponsored)&nbsp;</em>}
                              {searchResult.chunks.map(({ text, match, key }) =>
                                match ? (
                                  <Box
                                    component="span"
                                    sx={{
                                      backgroundColor: '#ffffe0',
                                      color: 'black',
                                    }}
                                    key={key}
                                  >
                                    {text}
                                  </Box>
                                ) : (
                                  <span key={key}>{text}</span>
                                ),
                              )}
                            </Link>
                          </h4>
                        </Grid>
                        <Grid item xs={2}>
                          <h4>{searchResult.asin}</h4>
                        </Grid>
                        <Grid item xs={1}>
                          <h4>{searchResult.price}</h4>
                        </Grid>
                      </Grid>
                    ))}
                  {searchPagination && searchPagination.currentPage && (
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                      <LoadingButton
                        loading={loading}
                        variant="outlined"
                        endIcon={<MoreHorizIcon />}
                        onClick={() => {
                          const pageNum = Number(searchPagination.currentPage) + 1;
                          searchProducts({ pageNum });
                        }}
                      >
                        Load more
                      </LoadingButton>
                    </Grid>
                  )}
                </Grid>
              )}
            </DialogContentText>
          </form>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading || saving} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={itemsSelected === 0 && !storeId}
            loading={saving}
            type="button"
            data-cy="Amazon_ASIN_add"
            onClick={async (e) => {
              setSaving(true);
              e.preventDefault();
              onAddAsins(searchResults.filter((searchResult) => searchResult.selected || storeId));
              setSaving(false);
              handleClose();
            }}
          >
            Add Asins
          </LoadingButton>
        </>
      }
    />
  );
};

export default OnboardingAsinLookup;
