import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import 'assets/style.css';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import mainTheme from 'assets/theme';
import { ThemeProvider, StyledEngineProvider, CssBaseline, createTheme, Box, Button } from '@mui/material';
import { deepmerge } from '@mui/utils';
import Settings from 'pages/Settings/Settings';
import Toast from 'components/Toast/Toast';
import ImageViewer from 'pages/Common/ImageViewer';
import Login from 'pages/Login/Login';
import Dashboard from 'pages/Dashboard/Dashboard';
import Sellers from 'pages/Sellers/Sellers';
import Clients from 'pages/Clients/Clients';
import Tenants from 'pages/Tenants/Tenants';
import Tenant from 'pages/Tenants/Tenant';
import AsinManager from 'pages/AsinManager/AsinManager';
import AsinDiscovery from 'pages/AsinDiscovery/AsinDiscovery';
import Enforcements from 'pages/Enforcements/Enforcements';
import Analytics from 'pages/Analytics/Analytics'; 
import Profile from 'pages/Account/Profile/Profile';
import { AuthDataProvider, ToastDataProvider, RegionDataProvider } from 'contexts';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import awsconfig from 'aws-exports';
import Accounts from 'pages/Account/Team/Team';
import TestBuys from 'pages/TestBuys/TestBuyList/TestBuys';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import ErrorBoundary from 'utils/ErrorBoundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Color from 'color';
import axios from 'axios';
import AmazonSnapshot from 'pages/Enforcements/AmazonSnapshot';
import { useTracking } from 'react-tracking';
import { useMutation, gql } from '@apollo/client';
import { useUpdateCheck } from 'react-update-notification';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

Amplify.configure(awsconfig);

const App = () => {
  const history = useHistory();
  const [tenants, setTenants] = useState(null);

  const PING_EVENT_MUTATION = gql`
    mutation PingEvent($tag: String!, $description: String!) {
      pingEvent(tag: $tag, description: $description)
    }
  `;

  const [pingEvent] = useMutation(PING_EVENT_MUTATION, {
    onError: (e) => {
      console.error('Ping Event Error: ', e.message);
    },
  });

  const handleTracking = (data) => {
    if (data.tag && data.description) {
      pingEvent({ variables: { tag: data.tag, description: data.description } });
    }
  };

  const { Track } = useTracking({}, { dispatch: handleTracking });

  const graphql = `${process.env.REACT_APP_API_URL}/graphql`;
  const TIMEOUT = 300000; // 5 minute timeout
  const graphQLFetch = (query) =>
    axios({
      url: graphql,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({ query }),
      timeout: TIMEOUT,
    })
      .then((r) => r.data)
      .then((data) => {
        if (data.errors?.length > 0) {
          throw new Error(data.errors[0].message);
        } else {
          return data?.data;
        }
      });

  const processData = (d) => {
    const data = d.getTenants;
    const result = data.map((t) => {
      if (t.tenant === 'ipsecure') {
        return { path: '', ...t };
      }
      return { path: t.tenant, ...t };
    });
    setTenants(result);
  };

  const getTenant = () => {
    if (tenants) {
      const tenantId = window.location.pathname.split('/')[1] || null;
      for (let i = 0; i < tenants.length; i += 1) {
        const item = tenants[i];
        if (item.tenant === tenantId) {
          return item;
        }
      }
      return tenants[0];
    }
    return null;
  };

  const TENANTS_QUERY = `
    query {
      getTenants {
        id
        tenant
        title
        buttonColor
        navColor
        loginLogo
        navLogo
        asinLimit
        configuredFeatures {
          id
          name
          description
        }
        configuredRegions {
          id
          iso
          name
          nativeName
        }
        allRegions {
          baseUrl
          id
          currency
          iso
          name
          nativeName
        }
      }
    }`;

  useEffect(() => {
    graphQLFetch(TENANTS_QUERY).then((result) => processData(result));
  }, []);

  const path = getTenant()?.path;

  const makeTenantThame = () => {
    const t = getTenant();
    const disableColor = Color(t.buttonColor).lighten(0.6).hex();
    if (t) {
      return {
        title: t.title,
        windowTitle: `${t.title} - Amazon Brand Management`,
        logo: {
          light: t.navLogo,
          dark: t.loginLogo,
        },
        palette: {
          primary: {
            main: t.buttonColor,
          },
          tenant: {
            bgSizeNav: t.navColor,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              contained: {
                '&.Mui-disabled': {
                  backgroundColor: disableColor,
                },
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  color: t.buttonColor,
                },
              },
            },
          },
        },
      };
    }
    return {};
  };

  const theme = getTenant() ? createTheme(deepmerge(mainTheme, makeTenantThame())) : null;

  if (tenants === null) return null;

  const AppUpdateAvailableChecker = () => {
    const { status, reloadPage } = useUpdateCheck({
      type: 'interval',
      interval: 10000,
    });
    if (status === 'checking' || status === 'current') {
      return null;
    }

    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          borderRadius: '10px',
          bgcolor: 'white',
          p: 2,
          display: 'flex',
          maxWidth: "calc(100vw 50px)",
          width: '280px',
          boxShadow: '0 -1px 40px rgba(0, 0, 0, .10), 0 1px 15px rgba(0, 0, 0, .25)',
          gap: 1.5,
          zIndex: '99 !important;'
        }}
      >
        <RefreshRoundedIcon sx={{width: '30px', height: '30px', color: 'greys.silver'}} />
        <Box>
          <Box sx={{ fontSize: '16px', fontWeight: 600, mb: 1, color: 'greys.black' }}>App update available</Box>
          <Box sx={{ fontSize: '14px', fontWeight: 400, mb: 2, color: 'greys.silver' }}>Please reload this page to use the latest version of the app.</Box>
          <Button sx={{ mb: 1 }} onClick={reloadPage}>Reload now</Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {tenants !== null && (
        <HelmetProvider>
          <Helmet>
            <title>{theme.windowTitle}</title>
            <link rel="icon" type="image/png" href={theme.favIcon} sizes="16x16" />
          </Helmet>
          <BrowserRouter basename={path}>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Box sx={{ fontFamily: theme.typography.fontFamily }}>
                  <ErrorBoundary history={history}>
                    <ToastDataProvider>
                      <AuthDataProvider tenant={getTenant()}>
                        <RegionDataProvider>
                          <AuthWrapper>
                            <AppUpdateAvailableChecker />
                            <Track>
                              <Switch>
                                <Route path="/login" component={Login} />
                                <Route path="/resetpass/:code" component={Login} />
                                <Route path="/invite/:code" component={Login} />
                                <Route path="/create" render={() => <Login createSubtenant />} />
                                <PrivateRoute path="/profile" component={Profile} />
                                <PrivateRoute path="/accounts" component={Accounts} />
                                <PrivateRoute path="/asins/:categoryId" component={AsinManager} />
                                <PrivateRoute path="/asins" component={AsinManager} />
                                <PrivateRoute path="/discovery" component={AsinDiscovery} />
                                <Route
                                  path="/enforcements/snapshot/:regionId/:asin/:offerId"
                                  component={AmazonSnapshot}
                                />
                                <Route path="/image/:id" component={ImageViewer} />
                                <PrivateRoute path="/enforcements" component={Enforcements} />
                                <PrivateRoute path="/clients" component={Clients} />
                                <PrivateRoute path="/tenants/:id/:routeTab" component={Tenant} />
                                <PrivateRoute path="/tenants/:id" component={Tenant} />
                                <PrivateRoute path="/tenants" component={Tenants} />
                                <PrivateRoute path="/testbuys/list" component={TestBuys} />
                                <PrivateRoute path="/analytics/:routeTab" component={Analytics} />
                                <PrivateRoute path="/analytics" component={Analytics} />
                                <PrivateRoute
                                  path="/settings/alerts"
                                  render={() => <Settings routeTab="alerts" />}
                                />
                                <PrivateRoute
                                  path="/settings/addresses"
                                  render={() => <Settings routeTab="addresses" />}
                                />
                                <PrivateRoute
                                  path="/settings/letters"
                                  render={() => <Settings routeTab="letters" />}
                                />
                                <PrivateRoute
                                  path="/settings/plan"
                                  render={() => <Settings routeTab="plan" />}
                                />
                                <PrivateRoute
                                  path="/settings/payments"
                                  render={() => <Settings routeTab="payments" />}
                                />
                                <PrivateRoute
                                  path="/settings/paymentHistory"
                                  render={() => <Settings routeTab="paymentHistory" />}
                                />
                                <PrivateRoute
                                  path="/settings/amazon"
                                  render={() => <Settings routeTab="amazon" />}
                                />
                                <PrivateRoute
                                  path="/settings/notifications"
                                  render={() => <Settings routeTab="notifications" />}
                                />
                                <PrivateRoute
                                  path="/settings/rules/:param?/:id?"
                                  render={() => <Settings routeTab="rules" />}
                                />
                                <PrivateRoute path="/settings" component={Settings} />
                                <PrivateRoute
                                  path="/sellers/all"
                                  render={() => <Sellers routeTab="all" />}
                                />
                                <PrivateRoute exact path="/sellers/:id" component={Sellers} />
                                <PrivateRoute path="/sellers" component={Sellers} />
                                <PrivateRoute path="/dashboard" component={Dashboard} />
                                <Route exact path="*">
                                  <Redirect to="/dashboard" />
                                </Route>
                              </Switch>
                              <Toast />
                            </Track>
                          </AuthWrapper>
                        </RegionDataProvider>
                      </AuthDataProvider>
                    </ToastDataProvider>
                  </ErrorBoundary>
                </Box>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </HelmetProvider>
      )}
    </>
  );
};

export default App;
