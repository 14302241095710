import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Box,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
} from '@mui/material';
import { Loading, ConfirmationDialog, ChipContainer, Chip } from 'components';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { useMutation, gql } from '@apollo/client';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { formatDate } from 'utils/dates';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
});

const ActionsMenu = ({ onEdit, onDisable, onEnable, onDelete, item, disableMode, enableMode, deleteMode }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  return (
    <>
      <IconButton size="small" onClick={handleMenu}>
        <MoreHorizIcon fill={theme.palette.greys.silver} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onEdit(item);
          }}
        >
          <EditRoundedIcon style={{ width: '20px', height: '20px' }} />
          Edit
        </MenuItem>
        <Box
          sx={{
            width: '100%',
            m: 0,
            p: 0,
            borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
          }}
        />
        {disableMode && (
          <MenuItem
            onClick={() => {
              handleClose();
              onDisable(item);
            }}
            data-cy="client_disable"
            sx={{ color: theme.colors.red }}
          >
            <BlockRoundedIcon style={{ color: theme.colors.red, width: '20px', height: '20px' }} />
            Disable Client Account
          </MenuItem>
        )}
        {enableMode && (
          <MenuItem
            onClick={() => {
              handleClose();
              onEnable(item);
            }}
            data-cy="client_enable"
          >
            <CheckRoundedIcon style={{ width: '20px', height: '20px' }} />
            Enable Client Account
          </MenuItem>
        )}
        {deleteMode && (
          <Box>
            <Box
              sx={{
                width: '100%',
                m: 0,
                p: 0,
                borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
              }}
            />
            <MenuItem
              onClick={() => {
                handleClose();
                onDelete(item);
              }}
              data-cy="client_disable"
              sx={{ color: theme.colors.red }}
            >
              <DeleteForeverRoundedIcon style={{ color: theme.colors.red, width: '20px', height: '20px' }} />
              Delete Client Account
            </MenuItem>
          </Box>
        )}
      </Menu>
    </>
  );
};

const ClientRow = ({ client, index, onShowClient, onDelete, tenant }) => {

  const [disableConfirmationClient, setDisableConfirmationClient] = useState(null);
  const [disablingClient, setDisablingClient] = useState(false);
  const [busy, setBusy] = useState(false);
  const { setToast } = useContext(ToastDataContext);
  const { account, hasPermission } = useContext(AuthDataContext);
  const theme = useTheme();

  const UPDATE_CLIENT_MUTATION = gql`
    mutation UpdateClient($id: ID!, $disabled: Boolean) {
      updateAccount(id: $id, disabled: $disabled) 
    }
  `;

  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION, {
    onError: (e) => {
      setBusy(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleEnable = (c) => {
    setBusy(true);
    updateClient({
      variables: {
        id: c.id,
        disabled: false,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: "Client enabled." });
        setBusy(false);
      },
      update(cache) {
        cache.modify({
          id: cache.identify(c),
          fields: {
            disabled() {
              return false;
            },
          },
        });
      },
    });
  }

  const handleDisable = (c) => {
    setDisablingClient(true);
    updateClient({
      variables: {
        id: c.id,
        disabled: true,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: "Client disabled." });
        setDisablingClient(false);
        setDisableConfirmationClient(null);
      },
      update(cache) {
        cache.modify({
          id: cache.identify(c),
          fields: {
            disabled() {
              return true;
            },
          },
        });
      },
    });
  }

  const emailAddresses = client.users.map(c => `${c.email} ${c.firstName ? ` (${c.firstName} ${c.lastName})` : ''}`);

  const getLastLoggedIn = (() => {
    let lastLoggedIn = null;
    for (let i = 0; i < client.users?.length; i += 1) {
      const user = client.users[i];
      if (user.lastLoggedIn) {
        if (!lastLoggedIn) {
          lastLoggedIn = new Date(user.lastLoggedIn);
        }
        const userLastLoggedIn = new Date(user.lastLoggedIn);
        if (!lastLoggedIn || userLastLoggedIn > lastLoggedIn) {
          lastLoggedIn = userLastLoggedIn;
        }
      }
    }
    return lastLoggedIn;
  })();

  const howLongSinceLastLogin = (() => {
    if (getLastLoggedIn) {
      const nowDate = new Date();
      const timeDiff = nowDate.getTime() - getLastLoggedIn.getTime();
      return timeDiff / (1000 * 3600 * 24);
    }
    return 999;
  })();

  const clientFeatures = client.configuredFeatures.filter(f => tenant.configuredFeatures.filter(item => item.id === f.id).length === 0);

  return (
    <TableRow
      sx={{ bgcolor: index % 2 === 1 ? 'greys.backgroundGrey' : '', }}
      key={client.id}
    >
      <TableCell data-cy="client_name">
        <Box
          sx={{ display: 'flex', gap: 1, width: '100%', alignItems: 'center', opacity: client.disabled ? 0.5 : 1.0 }}
          onClick={() => onShowClient(client)}
        >
          <Box
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.linkBlue,
              textDecoration: 'underline',
              fontWeight: 500,
            }}
            onClick={() => {
              onShowClient(client);
            }}
          >
            {client.name}
          </Box>
          {client.disabled ? (<Box sx={{ fontWeight: 500 }}>(disabled)</Box>) : ''}
          {client.demo ? (
            <Chip
              sx={{ fontSize: '11px', fontWeight: 600, mr: '5px', py: '1px', mb: '2px', mt: '4px' }}
              label="DEMO"
              backgroundColor={theme.palette.chips.green.color}
              color='white'
            />
          ) : ''}
          {client.id === account.id && (<Box sx={{ fontWeight: 700 }}>(current)</Box>)}
          {client.logo && (
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, background: tenant.navColor, borderRadius: '8px', cursor: 'pointer' }}>
              <Box>
                <Box
                  component="img"
                  sx={{ maxWidth: '90px', maxHeight: '25px', m: 1, objectFit: 'contain' }}
                  src={client.logo}
                />
              </Box>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell data-cy="client_userCount" align="center">
        <Box sx={{ opacity: client.disabled ? 0.5 : 1.0 }}>
          {client?.users?.length && (
            <CustomWidthTooltip
              title={(
                <Box sx={{ mt: '5px' }}>
                  {emailAddresses.map(e => <Box key={e} sx={{ mb: '5px' }}>{e}</Box>)}
                </Box>
              )}
              arrow
              placement="bottom"
              style={{ cursor: 'default' }}
            >
              <Box>
                {client.users.length}
              </Box>
            </CustomWidthTooltip>
          )}
        </Box>
      </TableCell>
      <TableCell data-cy="client_userCount" align="center">
        <Box sx={{ opacity: client.disabled ? 0.5 : 1.0 }}>
          {client.asinCount?.toLocaleString('en-US')}
        </Box>
      </TableCell>
      <TableCell data-cy="client_userCount" align="center">
        <Box sx={{ opacity: client.disabled ? 0.5 : 1.0 }}>
          {client.asinLimit?.toLocaleString('en-US')}
        </Box>
      </TableCell>
      <TableCell data-cy="client_userCount" align="center">
        <Box
          sx={{
            color: howLongSinceLastLogin > 30 && !client.disabled ? 'red' : 'colors.black',
            fontWeight: howLongSinceLastLogin > 30 && !client.disabled ? '700' : '400',
            opacity: client.disabled ? 0.5 : 1.0
          }}
        >
          {getLastLoggedIn ? formatDate(getLastLoggedIn) : 'None'}
        </Box>
      </TableCell>
      <TableCell data-cy="client_userCount" align="center">
        <Box sx={{ opacity: client.disabled ? 0.5 : 1.0 }}>
          {hasPermission('clientManagement') && (client.fetchInventory === true ? 'Yes' : '—')}
        </Box>
      </TableCell>
      <TableCell data-cy="tenant_features" align="left">
        <Box sx={{ opacity: client.disabled ? 0.5 : 1.0 }}>
          {tenant.configuredFeatures?.length > 0 && (
            <Box sx={{ mb: clientFeatures.length > 0 ? '3px' : 0 }}>
              <ChipContainer
                labels={tenant.configuredFeatures?.map(f => f.description)}
                backgroundColor={theme.palette.chips.silver.backgroundColor}
                color={theme.palette.chips.silver.color}
              />
            </Box>
          )}
          {clientFeatures.length > 0 && (
            <ChipContainer
              labels={clientFeatures.map(f => f.description)}
              backgroundColor={theme.palette.chips.purple.backgroundColor}
              color={theme.palette.chips.purple.color}
            />
          )}
        </Box>
      </TableCell>
      <TableCell align="right" data-cy="settings_letter_actions">
        <Box sx={{ minHeight: '30px', maxHeight: '30px' }}>
          {busy && <Loading small />}
          {!busy && (
            <ActionsMenu
              onEdit={onShowClient}
              item={client}
              disableMode={!client.disabled && client.id !== account.id}
              enableMode={client.disabled && client.id !== account.id}
              deleteMode={hasPermission('clientManagement')}
              onEnable={handleEnable}
              onDisable={setDisableConfirmationClient}
              onDelete={onDelete}
            />
          )}
        </Box>
      </TableCell>
      {disableConfirmationClient && (
        <ConfirmationDialog
          open
          title="Disable Client"
          message={`Are you sure you wish to disable ${disableConfirmationClient?.name}?`}
          busy={disablingClient}
          okTitle="Disable"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              setDisablingClient(true);
              handleDisable(disableConfirmationClient);
            } else {
              setDisableConfirmationClient(null);
            }
          }}
        />
      )}
    </TableRow>
  )
};

ClientRow.propTypes = {
  tenant: PropTypes.oneOfType([PropTypes.object]).isRequired,
  client: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  onShowClient: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ClientRow;
