import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Box } from '@mui/material';
import mapOptions from './map-options';
import MapMarker from './MapMarker';

const ClusterNode = ({
  cluster,
  latitude,
  longitude,
  supercluster,
  mapRef,
  color,
  points,
  pointCount,
}) => (
  <Box>
    <Box
      sx={{
        fontSize: '11px',
        fontWeight: '700',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid transparent',
        backgroundColor: color,
        borderRadius: '50%',
        cursor: 'pointer',
        width: `${20 + (pointCount / points.length) * 30}px`,
        height: `${20 + (pointCount / points.length) * 30}px`,
      }}
      onClick={() => {
        const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
        mapRef.current.setZoom(expansionZoom);
        mapRef.current.panTo({ lat: latitude, lng: longitude });
      }}
    >
      {pointCount}
    </Box>
  </Box>
);

const MapGoogle = ({ markers, height, center, zoom: centerZoom }) => {
  const [activeInfoWindow, setActiveInfoWindow] = useState(-1);
  const [boundsState, setBoundsState] = useState(null);
  const [zoomState, setZoomState] = useState(10);
  const mapRef = useRef();

  const points = markers
    .filter((m) => m.type !== 'reference')
    .map((marker, index) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        count: 0,
        bad: 0,
        good: 0,
        index: `${index}${marker.lng}${marker.lat}`,
        marker,
        type: marker.type,
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(marker.lng), parseFloat(marker.lat)],
      },
    }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: boundsState,
    zoom: zoomState,
    options: {
      radius: 40,
      maxZoom: 16,
    },
  });

  const defaultProps = {
    center,
    zoom: centerZoom,
  };

  return (
    <Box sx={{ height: `${height}`, width: `100%` }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}` }}
        options={mapOptions}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoomState(zoom);
          setBoundsState([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            const type = supercluster.getLeaves(cluster.id).reduce((acc, curr) => {
              if (!acc) {
                return curr.properties.marker.type;
              }
              if (acc !== curr.properties.marker.type) {
                return 'both';
              }
              return acc;
            }, null);
            let color;
            if (type === 'good') {
              color = '#306FD3';
            } else if (type === 'bad') {
              color = '#EA564E';
            } else {
              color = '#b25e76';
            }

            return (
              <ClusterNode
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                cluster={cluster}
                color={color}
                mapRef={mapRef}
                supercluster={supercluster}
                pointCount={pointCount}
                points={points}
                latitude={latitude}
                longitude={longitude}
              />
            );
          }
          const { marker } = cluster.properties;
          return (
            <MapMarker
              type={marker.type}
              key={`single-${marker.amazonId}-${marker.lat}-${marker.lng}`}
              lat={marker.lat}
              lng={marker.lng}
              icon={marker?.icon}
              title={marker?.title}
              name={marker?.name}
              index={index}
              activeInfoWindow={activeInfoWindow}
              toggleActiveInfoWindow={setActiveInfoWindow}
              infoWindowContent={marker?.popup}
              onClick={marker?.onClick}
              infoWindowWidth={marker?.popupWidth}
            />
          );
        })}
        {markers
          .filter((m) => m.type === 'reference')
          .map((marker, index) => (
            <MapMarker
              key={`${marker.amazonId}-${marker.lat},${marker.lng}`}
              lat={marker.lat}
              lng={marker.lng}
              icon={marker?.icon}
              title={marker?.title}
              name={marker?.name}
              data-cy={`map_marker_${marker?.name}`}
              index={index}
              activeInfoWindow={activeInfoWindow}
              toggleActiveInfoWindow={setActiveInfoWindow}
              infoWindowContent={marker?.popup}
              onClick={marker?.onClick}
              infoWindowWidth={marker?.popupWidth}
            />
          ))}
      </GoogleMapReact>
    </Box>
  );
};

MapGoogle.defaultProps = {
  markers: [],
  height: '400px',
};

MapGoogle.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
};

export default MapGoogle;
