import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Tooltip, Box, useTheme, Typography } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { ReactComponent as BuyBoxWinnerIcon } from 'assets/buy-box-winner.svg';
import { Chip, ChipContainer, SellerName } from 'components';
import { AuthDataContext, RegionDataContext } from 'contexts';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';
import { formatDate } from 'utils/dates';

const SellerRow = ({ seller, index, onShowSeller }) => {
  const theme = useTheme();
  const { region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);

  const rowBackgroundColor = () => {
    if (seller.currentScore?.score < 0) {
      return '#F9EEF1';
    }
    return index % 2 === 1 ? 'greys.backgroundGrey' : '';
  };

  return (
    <TableRow sx={{ bgcolor: rowBackgroundColor() }} key={seller.id} data-cy="sellers_row_id">
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1, width: '100%', alignItems: 'center' }}>
          {seller.buyBoxWinRate > 0 && seller.currentScore?.score < 0 && (
            <Tooltip title="Seller has at least one item that is a buy box winner.">
              <Box>
                <BuyBoxWinnerIcon
                  fill={theme.palette.error.main}
                  style={{
                    color: theme.palette.error.main,
                    minWidth: '22px',
                    minHeight: '22px',
                    maxWidth: '22px',
                    maxHeight: '22px',
                  }}
                />
              </Box>
            </Tooltip>
          )}
          {seller.buyBoxWinRate > 0 && seller.currentScore?.score >= 0 && (
            <Tooltip title="Seller has at least one item that is a buy box winner.">
              <Box>
                <BuyBoxWinnerIcon
                  fill={theme.palette.chips.turquoise.color}
                  style={{
                    color: theme.palette.error.main,
                    minWidth: '22px',
                    minHeight: '22px',
                    maxWidth: '22px',
                    maxHeight: '22px',
                  }}
                />
              </Box>
            </Tooltip>
          )}
          {seller.currentScore?.score < 0 && seller.buyBoxWinRate === 0 && (
            <ErrorRoundedIcon
              style={{
                color: theme.palette.error.main,
                minWidth: '22px',
                minHeight: '22px',
                maxWidth: '22px',
                maxHeight: '22px',
              }}
            />
          )}
          {seller.currentScore?.score >= 0 && seller.buyBoxWinRate === 0 && (
            <Box
              sx={{
                color: 'error.main',
                minWidth: '22px',
                minHeight: '22px',
                maxWidth: '22px',
                maxHeight: '22px',
              }}
            />
          )}
          <Box
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.linkBlue,
              textDecoration: 'underline',
            }}
            onClick={() => {
              onShowSeller(seller.id);
            }}
          >
            <SellerName
              sellerName={seller.name || ''}
              sx={{
                display: 'block',
                fontWeight: '600',
                fontSize: '14px',
                color: '#0071DA',
                textDecoration: 'underline',
                lineHeight: '18px',
                maxHeight: '36px',
                overflow: 'hidden',
              }}
            />
          </Box>
          <SellerAuthorizationState
            globalAuth={seller.globalAuth}
            partialAuth={seller.authorized}
          />
          {seller.actionsTaken > 0 && (
            <Chip
              sx={{ ml: '5px', cursor: 'default' }}
              label={
                <Tooltip
                  title={
                    <Box>Last action taken on {formatDate(seller.lastActionTakenDate)}</Box>
                  }
                  arrow
                  placement="bottom"
                  sx={{ cursor: 'default', fontFamily: theme.typography.fontFamily }}
                >
                  <Box sx={{ textAlign: 'center', fontSize: '12px', fontWeight: '600' }}>
                    {seller.actionsTaken}
                  </Box>
                </Tooltip>
              }
              backgroundColor={theme.palette.chips.red.backgroundColor}
              color={theme.palette.chips.red.color}
            />
          )}
        </Box>
      </TableCell>
      <TableCell data-cy="seller_categories">
        {seller.categories && seller.categories?.length > 1 && (
          <Chip
            label={
              <Tooltip
                title={seller.categories.map((a) => (
                  <Typography>{a.name}</Typography>
                ))}
                arrow
                placement="bottom"
                sx={{ cursor: 'default', fontFamily: theme.typography.fontFamily }}
              >
                <Box sx={{ textAlign: 'center', fontSize: '12px', fontWeight: '600' }}>
                  {seller.categories.length.toString()}
                </Box>
              </Tooltip>
            }
            backgroundColor={theme.palette.chips.purple.backgroundColor}
            color={theme.palette.chips.purple.color}
          />
        )}
        {seller.categories && seller.categories?.length === 1 && (
          <Chip
            sx={{ fontSize: '12px' }}
            backgroundColor={theme.palette.chips.purple.backgroundColor}
            color={theme.palette.chips.purple.color}
            label={seller.categories[0].name}
          />
        )}
      </TableCell>
      <TableCell>
        <ChipContainer
          sx={{ fontSize: '12px' }}
          labels={seller.tags}
          backgroundColor={theme.palette.chips.silver.backgroundColor}
          color={theme.palette.chips.silver.color}
        />
      </TableCell>
      <TableCell data-cy="seller_buyBoxWinRate">{seller.buyBoxWinRate ?? 0}%</TableCell>
      <TableCell data-cy="seller_score">{seller.currentScore?.score}</TableCell>
      <TableCell data-cy="seller_asins">{seller.asins?.length}</TableCell>
      <TableCell>
        {seller.lostSales !== undefined && (
          <Box>
            {Intl.NumberFormat(region, {
              style: 'currency',
              currency: regionCurrency,
              maximumFractionDigits: 0,
            }).format(seller.lostSales)}
          </Box>
        )}
      </TableCell>
      <TableCell>
        <SellerAndOfferMenu seller={seller} />
      </TableCell>
    </TableRow>
  );
};

SellerRow.propTypes = {
  seller: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  onShowSeller: PropTypes.func.isRequired,
};

export default SellerRow;
