import React, { useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { AuthDataContext } from 'contexts';

function SwitchSignUp() {
  const theme = useTheme();
  const { tenant } = useContext(AuthDataContext);

  return (
    <>
      {tenant.tenant === 'ipsecure' && (
        <Box sx={{ display: 'inline-block' }}>
          <Typography sx={{ ...theme.custom.login.switch, mr: 1 }}>
            Don&apos;t have an account?
          </Typography>
          <Box sx={{ display: 'flex', mt: 1 }}>
            <Typography sx={{ ...theme.custom.login.switch, mr: 1 }}>
              Email us for access at
            </Typography>
            <Typography
              sx={{ ...theme.custom.login.switchLink, color: theme.palette.primary.main }}
            >
              newuser@ipsecure.com
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

export default SwitchSignUp;
