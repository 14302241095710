import React, { useState, useContext, lazy, Suspense } from 'react';
import { Content, Top, Header, Loading } from 'components';
import { Tab, Box } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import AddAsins from 'pages/Common/AddAsins';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';

const All = lazy(() => import('pages/AsinManager/All/All'));
const Categories = lazy(() => import('pages/AsinManager/Categories/Categories'));

const AsinManager = () => {
  const [tab, setTab] = useState('1');
  const { account, updateAccount } = useContext(AuthDataContext);
  const [addAsinOpen, setAddAsinOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [updateChildFunction, setUpdateChildFunction] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);

  const handleTabChange = (newValue) => {
    setSelectedCategory(null);
    setTab(newValue);
  };

  const handleUpdateChild = () => {
    if (updateChildFunction) {
      updateChildFunction.fn({});
    }
    updateAccount();
  };

  const handleSetUpdateFunction = (fn) => {
    setUpdateChildFunction({ fn });
  };

  const processCategoryData = (dataToProcess) => {
    if (dataToProcess) {
      const sortedList = dataToProcess.getCategoryList
        .concat()
        .sort((a, b) => a.name.localeCompare(b.name));
      setCategories(sortedList);
      setLoading(false);
    }
  };

  const GET_CATEGORY_LIST_QUERY = gql`
    query GetCategoryList {
      getCategoryList {
        id
        name
      }
    }
  `;

  const { refetch } = useQuery(GET_CATEGORY_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processCategoryData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleNewCategoryAdded = (category) => {
    if (updateChildFunction) {
      updateChildFunction.fn({ category });
    }
    updateAccount();
    refetch();
  };

  const handleCategoriesChanged = () => {
    refetch();
  };

  return (
    <Content transparent>
      {loading && <Loading />}
      {!loading && (
        <TabContext value={tab}>
          <Top sx={tab === '2' ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : null}>
            <Header
              title="ASIN Manager"
              subtitle={
                account.asinCount !== undefined
                  ? `Managing ${account.asinCount?.toLocaleString('en-US')} ASIN${
                      account.asinCount !== 1 ? 's' : ''
                    }`
                  : `\u2003`
              }
              showAddAsin
              onAddAsin={() => {
                setAddAsinOpen(true);
              }}
              showCredits
            >
              <TabList
                sx={{ mt: 2 }}
                color="primary"
                onChange={(_event, value) => handleTabChange(value)}
              >
                <Tab label="Categories" value="1" data-cy="categories_tab" />
                <Tab label="All" value="2" data-cy="all_tab" />
              </TabList>
            </Header>
          </Top>
          <Box sx={{ overflow: 'hidden', display: 'flex', height: '100%' }}>
            <Suspense fallback={<div />}>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '1' ? '100%' : '',
                }}
                value="1"
              >
                <Categories
                  setUpdateFunction={handleSetUpdateFunction}
                  setSelectedCategory={setSelectedCategory}
                  onCategoriesChanged={handleCategoriesChanged}
                />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '2' ? '100%' : '',
                }}
                value="2"
              >
                <All setUpdateFunction={handleSetUpdateFunction} />
              </TabPanel>
            </Suspense>
          </Box>
          {addAsinOpen && (
            <AddAsins
              categories={categories}
              category={selectedCategory}
              isOpen={addAsinOpen}
              addAsinsMode
              categoryId={selectedCategory ? selectedCategory.id : null}
              onClose={(result) => {
                if (result?.isNewCategory && result?.category) {
                  handleNewCategoryAdded(result?.category);
                } else if (result?.category) {
                  handleUpdateChild();
                }
                setAddAsinOpen(false);
              }}
            />
          )}
        </TabContext>
      )}
    </Content>
  );
};

export default AsinManager;
