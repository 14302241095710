import React, { useState } from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import { SellerName, Chip } from 'components';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { formatDate } from 'utils/dates';

const TestBuyItem = ({ testBuy, setSelectedTestBuy, onRefetch }) => {
  const [sellerProfileOpen, setSellerProfileOpen] = useState(false);
  const [sellerProfile, setSellerProfile] = useState(null);
  const theme = useTheme();

  return (
    <Grid
      sx={{ cursor: 'pointer', justifyContent: 'center' }}
      item
      container
      xs={12}
      onClick={() => setSelectedTestBuy(testBuy)}
    >
      <Grid container item xs={3} justifyContent="center">
        <Grid item>
          {testBuy.productImage && (
            <Box
              component="img"
              src={`${testBuy.productImage}`}
              alt="product"
              sx={{
                borderRadius: '6px',
                minWidth: '60px',
                maxWidth: '60px',
                maxHeight: '60px',
              }}
              data-cy="test_buy_img"
            />
          )}
          {!testBuy.productImage && (
            <BlankImage
              style={{
                borderRadius: '6px',
                minWidth: '60px',
                maxWidth: '60px',
                maxHeight: '60px',
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs={6}>
          <Box
            tabIndex={0}
            role="button"
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            onKeyDown={() => {
              setSellerProfile({
                sellerId: testBuy.sellerId,
                sellerName: testBuy.sellerName,
              });
              setSellerProfileOpen(true);
            }}
            onClick={() => {
              setSellerProfile({
                sellerId: testBuy.sellerId,
                sellerName: testBuy.sellerName,
              });
              setSellerProfileOpen(true);
            }}
          >
            <SellerName
              sellerName={testBuy.sellerName}
              sx={{
                display: 'block',
                fontWeight: '500',
                fontSize: '14px',
                color: '#0071DA',
                textDecoration: 'underline',
                lineHeight: '18px',
                maxHeight: '36px',
                overflow: 'hidden',
              }}
            />
            {/* <SellerAuthorizationState globalAuth={enforcement.offers[0].globalAuth} partialAuth={enforcement.offers[0].sellerAuthorized} /> */}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ ml: 1, textAlign: 'right' }} data-cy="testbuy_orderstatus">
            <Chip
              sx={{ textTransform: 'capitalize', fontSize: '12px', fontWeight: 600, py: '1px' }}
              label={testBuy.orderStatusName}
              color={
                testBuy.orderStatusName === 'error'
                  ? theme.palette.chips.red.color
                  : theme.palette.chips.blue.color
              }
              backgroundColor={
                testBuy.orderStatusName === 'error'
                  ? theme.palette.chips.red.backgroundColor
                  : theme.palette.chips.blue.backgroundColor
              }
            />
            {testBuy?.orderStatusError && (
              <Box sx={{ mt: 1, fontSize: '12px' }}>{testBuy.orderStatusError}</Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: 'greys.silver' }} variant="body1">
              {testBuy.asin}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <Typography sx={{ color: 'greys.silver' }} variant="body1">
            {formatDate(new Date(testBuy.orderDate))}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ color: 'greys.darkGrey', fontWeight: 'bold' }}>
            {testBuy.productTitle}
          </Typography>
        </Grid>
      </Grid>
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfileOpen}
        onClose={() => {
          setSellerProfileOpen(false);
          onRefetch();
        }}
      />
    </Grid>
  );
};

export default TestBuyItem;
