import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/clear.svg';

const MapMarker = ({
  index,
  icon,
  title,
  name,
  infoWindowContent,
  activeInfoWindow,
  toggleActiveInfoWindow,
  infoWindowWidth,
  onClick,
}) => {
  const onMarkerClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const onInfoWindowClose = () => {
    toggleActiveInfoWindow(-1);
  };

  return (
    <Box
      sx={{
        '& > button': {
          background: 'none',
          display: 'block',
          border: '0px',
          m: 0,
          p: 0,
          textTransform: 'none',
          appearance: 'none',
          cursor: 'pointer',
          userSelect: 'none',
          width: '26px',
          height: '26px',
        },
        '& > button img': {
          maxWidth: '26px',
          maxHeight: '26px',
        },
      }}
    >
      <Tooltip
        title={
          <>
            {name && (
              <>
                <b>{name}</b>
                <br />
              </>
            )}
            {title}
          </>
        }
        data-cy="map_marker_tooltip"
      >
        <IconButton data-cy={`map_marker_button_${name}`} onClick={onMarkerClick}>
          <img src={icon} alt={title} />
        </IconButton>
      </Tooltip>
      {activeInfoWindow === index && infoWindowContent && (
        <Box
          sx={{
            width: infoWindowWidth,
            bgcolor: '#FFFFFF',
            transform: 'translate3d(-50%,-100%,0)',
            borderRadius: '8px',
            p: '12px',
            boxShadow: '0 2px 7px 1px rgb(0 0 0 / 30%)',
            mt: '-38px',
            ml: '13px',
            zIndex: '9',
            position: 'relative',
            '& .arrow': {
              background:
                'linear-gradient(45deg,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%)',
              boxShadow: '-2px 2px 2px 0 rgb(178 178 178 / 40%)',
              height: '15px',
              bottom: '-15px',
              left: '50%',
              position: 'absolute',
              transform: 'translate(-50%,-50%) rotate(-45deg)',
              width: '15px',
            },
            '& .close': {
              background: 'none',
              display: 'block',
              border: '0px',
              margin: '0px',
              padding: '0px',
              textTransform: 'none',
              appearance: 'none',
              position: 'absolute',
              cursor: 'pointer',
              userSelect: 'none',
              top: '-6px',
              right: '-6px',
              width: '30px',
              height: '30px',
            },
            '& .close > img': {
              pointerEvents: 'none',
              display: 'block',
              width: '14px',
              height: '14px',
              margin: '8px',
            },
          }}
        >
          <IconButton onClick={onInfoWindowClose}>
            <CloseIcon />
          </IconButton>
          {infoWindowContent}
          <Box className="arrow" />
        </Box>
      )}
    </Box>
  );
};

MapMarker.defaultProps = {
  icon: null,
  infoWindowContent: null,
  title: '',
  activeInfoWindow: -1,
  infoWindowWidth: '300px',
};

MapMarker.propTypes = {
  index: PropTypes.number.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  infoWindowContent: PropTypes.any,
  activeInfoWindow: PropTypes.number,
  toggleActiveInfoWindow: PropTypes.func.isRequired,
  infoWindowWidth: PropTypes.string,
};

export default MapMarker;
