import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  Drawer,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableSortLabel,
  Checkbox,
  Box,
  useTheme,
} from '@mui/material';
import { Loading, Scrollable, LinkButton } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import { ReactComponent as MailIcon } from 'assets/mail.svg';
import { ReactComponent as SendLetterIcon } from 'assets/send-letter.svg';
import { CSVLink } from 'react-csv';
import LetterEditor from 'pages/Common/LetterEditor';
import { cloneDeep } from 'lodash';
import { ToastDataContext, RegionDataContext, AuthDataContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';
import { formatStringForCSV } from 'utils/strings';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import LostBBOfferRow from './LostBBOfferRow';

const wideDrawerWidth = 'calc(100vw - 194px)';

const LostBBSellerDrawer = ({ open, rangeLabel, dateStart, dateEnd, seller, onClose }) => {
  const scrollPageLength = 20;
  const { region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const [limit, setLimit] = useState(scrollPageLength);
  const [orderBy, setOrderBy] = useState('tag');
  const [sortOrder, setOrder] = useState('desc');
  const [asins, setAsins] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const [sellerId, setSellerId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [letterOffers, setLetterOffers] = useState(null);
  const [emailOffers, setEmailOffers] = useState(null);
  const theme = useTheme();

  const headCells = [
    { id: 'checkbox', label: '', numeric: false, sortable: false, width: '5%' },
    {
      id: 'title',
      label: 'Product',
      numeric: false,
      sortable: true,
      width: '30%',
      wide: true,
    },
    { id: 'asin', label: 'ASIN', numeric: false, sortable: true, width: '10%', wide: false },
    {
      id: 'averagePrice',
      label: 'Avg. Seller Price',
      numeric: true,
      sortable: true,
      width: '10%',
      wide: true,
    },
    {
      id: 'mapPrice',
      label: 'Map Price',
      numeric: true,
      sortable: true,
      width: '10%',
      wide: true,
    },
    {
      id: 'averageStock',
      label: 'Avg. Inventory',
      numeric: true,
      sortable: true,
      width: '10%',
      wide: false,
    },
    {
      id: 'buyBoxWinPercentage',
      label: 'Avg. BB Win Rate',
      numeric: true,
      sortable: true,
      width: '15%',
      wide: false,
    },
    {
      id: 'lostSales',
      label: 'Lost Sales',
      numeric: true,
      sortable: true,
      width: '15%',
      wide: true,
    },
    { id: 'action', label: '', numeric: false, sortable: false, width: '5%' },
  ];

  useEffect(() => {
    if (!open) {
      setSelectAll(false);
      setSelectedCount(0);
    }
  }, [open]);

  const exportAsins = (asinsToExport) => {
    const csvData = [
      [
        'Product',
        'ASIN',
        'Avg. Seller Price',
        'Map Price',
        'Avg. Inventory',
        'Avg. BB Win Rate',
        'Lost Sales',
      ],
    ];
    for (let i = 0; i < asinsToExport.length; i += 1) {
      const item = asinsToExport[i];
      const avgPrice = item.averagePrice
        ? `${Intl.NumberFormat(region, {
            style: 'currency',
            currency: regionCurrency,
          }).format(item.averagePrice.toFixed(2))}`
        : '-';
      const mapPrice = item.mapPrice
        ? `${Intl.NumberFormat(region, {
            style: 'currency',
            currency: regionCurrency,
          }).format(item.mapPrice.toFixed(2))}`
        : '-';
      const avgStock = item.averageStock ? item.averageStock.toFixed(0) : '-';
      const bbWin = item.buyBoxWinPercentage ? `${item.buyBoxWinPercentage.toFixed(2)}%` : '-';
      const lostSales = item.lostSales
        ? `${Intl.NumberFormat(region, {
            style: 'currency',
            currency: regionCurrency,
          }).format(item.lostSales.toFixed(2))}`
        : '-';
      csvData.push([
        formatStringForCSV(item.title),
        item.asin,
        avgPrice,
        mapPrice,
        avgStock,
        bbWin,
        lostSales,
      ]);
    }
    return csvData;
  };

  const sortHandler = (headCell, dataToProcess, order, currentOrderBy) => {
    if (!headCell.sortable) {
      return;
    }
    const isAsc = currentOrderBy === headCell.id && order === 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(headCell.id);
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (headCell.numeric) {
        if (!isAsc) {
          return b[headCell.id] - a[headCell.id];
        }
        return a[headCell.id] - b[headCell.id];
      }
      if (!isAsc) {
        return b[headCell.id].localeCompare(a[headCell.id]);
      }
      return a[headCell.id].localeCompare(b[headCell.id]);
    });
    setAsins(sorted);
  };

  const processData = (dataToProcess) => {
    const dataArray = cloneDeep(dataToProcess.getSellerReport).map((a) => ({
      name: a.title,
      ...a,
      sellerId: seller.sellerId,
      sellerName: seller.sellerName,
    }));
    setAsins(dataArray);
    // Do the initial sorting
    const defaultSort = { cell: headCells[6], order: 'desc' };
    setOrder(defaultSort.order);
    setOrderBy(defaultSort.cell.id);
    sortHandler(defaultSort.cell, dataArray, defaultSort.order, defaultSort.cell.id);
  };

  const GET_SELLER_REPORT_QUERY = gql`
    query GetSellerReport($sellerId: String!, $dateStart: DateTime, $dateEnd: DateTime) {
      getSellerReport(sellerId: $sellerId, dateStart: $dateStart, dateEnd: $dateEnd) {
        asin
        averagePrice
        averageStock
        buyBoxWinPercentage
        imageUrl
        lostSales
        sellerId
        parentAsin
        mapPrice
        title
        offerId
        uncontested
        variantTitle
        categoryId
      }
    }
  `;

  const { loading, refetch } = useQuery(GET_SELLER_REPORT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !seller,
    variables: {
      sellerId: seller?.sellerId,
      dateStart,
      dateEnd,
    },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const loadMore = () => {
    if (limit < asins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > asins.length) {
        newLimit = asins.length;
      }
      setLimit(newLimit);
    }
  };

  const headerContent = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        fontSize: '14px',
        fontWeight: '400',
        color: 'greys.silver',
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Box>
        <b>Estimated Lost Sales</b> - {rangeLabel}
      </Box>
    </Box>
  );

  const changeChecked = (asin) => {
    let newAsins = [];
    newAsins = asins.map((a) => (a.asin === asin ? { ...a, selected: !a.selected } : a));
    setAsins(newAsins);
    const allSelected = newAsins.filter((a) => a.selected === true).length === asins.length;
    setSelectAll(allSelected);
    setSelectedCount(newAsins.filter((a) => a.selected === true).length);
  };

  const updateChecked = (newSelectAll) => {
    let newAsins = [];
    if (newSelectAll) {
      newAsins = asins.map((a) => ({ ...a, selected: true }));
    } else {
      newAsins = asins.map((a) => ({ ...a, selected: false }));
    }
    setAsins(newAsins);
    const allSelected = newAsins.filter((a) => a.selected === true).length === asins.length;
    setSelectAll(allSelected);
    setSelectedCount(newAsins.filter((a) => a.selected === true).length);
  };

  const handleSelectAllTable = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    updateChecked(newSelectAll);
  };

  const handleSend = ({ email, letter }) => {
    const selected = asins.filter((a) => a.selected === true);
    if (email) {
      setEmailOffers(selected);
    } else if (letter) {
      setLetterOffers(selected);
    }
  };

  return (
    <Drawer
      data-cy="seller_insights_drawer"
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        width: wideDrawerWidth,
        flexShrink: 0,
        borderTopLeftRadius: theme.borderRadius,
        borderBottomLeftRadius: theme.borderRadius,
        '& .MuiDrawer-paper': {
          width: wideDrawerWidth,
          bgcolor: 'greys.white',
          overflow: 'hidden',
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            bgcolor: 'greys.backgroundGrey',
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            maxHeight: '44px',
            minHeight: '44px',
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>{headerContent}</Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              data-cy="seller_insights_drawer_close_button"
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <ClearIcon fill={theme.palette.greys.silver} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            px: 3,
            pb: 2,
            borderRadius: theme.borderRadius,
            bgcolor: 'greys.backgroundGrey',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 44px)',
          }}
        >
          <Box sx={{ mb: 2 }}>
            {selectedCount > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  fullWidth: '100%',
                  ml: 0,
                  px: 2,
                  pt: 1,
                  pb: 1,
                  backgroundColor: 'chips.purple.backgroundColor',
                  height: '45px',
                  borderRadius: '6px',
                  verticalAlign: 'middle',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      data-cy="seller_drawer_close_button"
                      onClick={() => {
                        setSelectAll(false);
                        updateChecked(false);
                      }}
                      size="large"
                    >
                      <ClearIcon fill={theme.palette.greys.silver} />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '14px',
                      fontWeight: '600',
                      ml: 1.5,
                      color: 'greys.silver',
                    }}
                  >
                    {selectedCount} Selected
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '12px',
                      ml: 1.5,
                      color: 'greys.silver',
                    }}
                  >
                    Bulk Enforcements:
                  </Box>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<MailIcon fill={theme.palette.greys.darkGrey} />}
                    sx={{
                      justifyContent: 'flex-start',
                      pl: 3,
                      pr: 3,
                      pt: '2px',
                      pb: '2px',
                      '&:hover': {
                        backgroundColor: 'chips.blue.backgroundColor',
                      },
                    }}
                    onClick={() => handleSend({ letter: true })}
                  >
                    Send Letter
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<SendLetterIcon fill={theme.palette.greys.darkGrey} />}
                    sx={{
                      justifyContent: 'flex-start',
                      pl: 3,
                      pr: 3,
                      pt: '2px',
                      pb: '2px',
                      '&:hover': {
                        backgroundColor: 'chips.blue.backgroundColor',
                      },
                    }}
                    onClick={() => handleSend({ email: true })}
                  >
                    Send Email
                  </Button>
                </Box>
              </Box>
            )}
            {selectedCount === 0 && !loading && asins.length > 0 && (
              <Box sx={{ display: 'flex', gap: 2, mb: 1, mt: 1, justifyContent: 'space-between' }}>
                <LinkButton
                  sx={{ fontSize: '18px', fontWeight: '700', ml: 1 }}
                  hyperStyle
                  onClick={() => {
                    setSellerId(seller.sellerId);
                  }}
                >
                  {seller?.sellerName}
                </LinkButton>
                {!loading && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <CSVLink
                      target="_blank"
                      filename={`LostBB-${seller?.sellerName}(${rangeLabel}).csv`}
                      data={exportAsins(asins)}
                    >
                      <Button
                        size="small"
                        sx={{ borderColor: 'greys.lightGrey' }}
                        variant="outlined"
                        startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                      >
                        Download
                      </Button>
                    </CSVLink>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {loading && <Loading />}
          {!loading && (
            <Scrollable transparent sx={{ borderRadius: '0' }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={true || false}
                loader={limit < asins.length && <Loading key={0} />}
                useWindow={false}
              >
                <TableContainer>
                  <Table data-cy="seller_insights_drawer_table">
                    <TableHead>
                      <TableRow
                        sx={{
                          borderTop: `1px solid ${theme.palette.greys.backgroundGrey}`,
                          borderLeft: `1px solid ${theme.palette.greys.backgroundGrey}`,
                          borderRight: `1px solid ${theme.palette.greys.backgroundGrey}`,
                        }}
                      >
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? sortOrder : false}
                            width={headCell.width}
                          >
                            {headCell.id === 'checkbox' && (
                              <Box sx={{ ml: '-4px' }}>
                                <Checkbox
                                  data-cy="asin_checkbox_all"
                                  indeterminate={selectedCount > 0 && !selectAll}
                                  checked={selectAll}
                                  onChange={handleSelectAllTable}
                                />
                                {!selectAll && ' '}
                              </Box>
                            )}
                            {headCell.id !== 'checkbox' && (
                              <TableSortLabel
                                hideSortIcon={!headCell.sortable}
                                data-cy={headCell.label}
                                active={headCell.sortable && orderBy === headCell.id}
                                direction={orderBy === headCell.id ? sortOrder : 'asc'}
                                onClick={() => {
                                  sortHandler(
                                    headCell,
                                    asins,
                                    sortOrder === 'asc' ? 'desc' : 'asc',
                                    orderBy,
                                  );
                                }}
                              >
                                {headCell.label}
                              </TableSortLabel>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {asins.slice(0, limit).map((asin) => (
                        <LostBBOfferRow
                          key={asin.asin}
                          offer={asin}
                          seller={seller}
                          onActionTaken={refetch}
                          changeChecked={changeChecked}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            </Scrollable>
          )}
        </Box>
      </Box>
      <SellerDrawer
        id={sellerId}
        open={sellerId !== null}
        onClose={() => setSellerId(null)}
        large
      />
      {letterOffers && (
        <LetterEditor
          isOpen
          sellerId={seller.sellerId}
          sellerName={seller.sellerName}
          offers={letterOffers}
          sendMode
          onClose={(sent) => {
            if (sent) {
              updateChecked(false);
            }
            setLetterOffers(null);
          }}
        />
      )}
      {emailOffers && (
        <LetterEditor
          isOpen
          sellerId={seller.sellerId}
          sellerName={seller.sellerName}
          offers={emailOffers}
          emailMode
          sellerEmail="testing@gmail.com"
          onClose={(sent) => {
            if (sent) {
              updateChecked(false);
            }
            setEmailOffers(null);
          }}
        />
      )}
    </Drawer>
  );
};

LostBBSellerDrawer.propTypes = {
  seller: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

LostBBSellerDrawer.defaultProps = {
  open: false,
  seller: null,
};

export default LostBBSellerDrawer;
