import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  Grid,
  Tooltip,
  InputAdornment,
  Card,
  Box,
  styled,
  useTheme,
} from '@mui/material';
import { LoadingButton, LinkButton } from 'components';
import accountService from 'services/account-service';
import { ReactComponent as Search } from 'assets/search.svg';
import { ReactComponent as HelperArrow } from 'assets/onboarding-arrow.svg';
import ImportAsins from 'pages/Common/ImportAsins';
import { ReactComponent as LockIcon } from 'assets/lock-closed.svg';
import { AuthDataContext, RegionDataContext } from 'contexts';
import DIALOG_ZINDEX from 'utils/z-index';
import { useHistory } from 'react-router-dom';
import OnboardingAsinLookup from './OnboardingAsinLookup';

const HelperCard = styled(Card)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(2),
}));

const AddProducts = ({ onProductsAdded, scrollTop }) => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState(null);
  const [asinLookupOpen, setAsinLookupOpen] = useState(false);
  const [showImportAsins, setShowImportAsins] = useState(false);
  const { account, tenant, region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const theme = useTheme();
  const history = useHistory();

  const defaultAsins = [
    { id: '1', number: '', price: '', title: '' },
    { id: '2', number: '', price: '', title: '' },
    { id: '3', number: '', price: '', title: '' },
  ];
  const [asins, setAsins] = useState(defaultAsins);

  const filledAsinCount = () => {
    let count = 0;
    for (let i = 0; i < asins.length; i += 1) {
      if (asins[i].number.length > 0) {
        count += 1;
      }
    }
    return count;
  };

  const maxExceeded = filledAsinCount() + account.asinCount >= (account.asinLimit || 10);

  const asinReducer = (asin) => ({
    asin: asin.number,
    price: asin.price ? parseFloat(asin.price) : null,
  });

  const validateForm = () => {
    const errors = asins.reduce((acc, asin) => {
      if (asin.number && !/^([\dA-Za-z]{10})$/.test(asin.number)) {
        const err = {};
        err[`asin.${asin.id}`] = 'Invalid ASIN (should be formatted like B00005N5PF)';
        acc.push(err);
      }
      return acc;
    }, []);

    return errors;
  };

  useEffect(async () => {}, []);

  const handleOnFocus = (index) => {
    if (index === asins.length - 1) {
      if (filledAsinCount() + account.asinCount + 1 >= account.asinLimit) {
        return;
      }
      setAsins([
        ...asins,
        {
          id: `a${asins.length + 1}`,
          number: '',
          price: '',
          title: '',
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        const validAsins = asins.filter((valid) => Boolean(valid.number)).map(asinReducer);
        const result = await accountService.addCategory({
          name: categoryName,
          asins: [],
          addVariants: false,
        });
        await accountService.addAsinsToCategory({
          categoryId: result.addCategory.id,
          asins: validAsins,
          addVariants: false,
        });
        onProductsAdded();
      } catch (err) {
        setFormErrors({ formError: err.message });
        setLoading(false);
      }
    }
  };

  return (
    <Box style={{ fontFamily: theme.typography.fontFamily }}>
      <Box>
        <Typography variant="h1" sx={{ mb: 3.5, textAlign: 'center' }}>
          {`Upload & Categorize ASINs`}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3.5, textAlign: 'left' }}>
          {`Every Amazon product listing is a 24/7 virtual bazaar 
          with sellers coming and going. Insert at least one ASIN 
          and its price, upload a file of ASINs, or use our ASIN Lookup tool to 
          curate a list of top selling ASINs or import by Store ID.`}
        </Typography>
        {formErrors.formError && <Box sx={{ color: 'error.main' }}>{formErrors.formError}</Box>}
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              disabled={loading}
              id="category"
              type="text"
              placeholder="Enter category name"
              fullWidth
              value={categoryName || ''}
              onChange={(e) => setCategoryName(e.target.value)}
              error={Boolean(formErrors.categoryName)}
              helperText={formErrors.categoryName}
              required
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: '20px',
                mb: '4px',
              }}
            >
              <Typography variant="h4">ASIN Info</Typography>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '200px',
                      transform: `translateX(220px) translateY(${-75 - scrollTop}px)`,
                      zIndex: DIALOG_ZINDEX - 2,
                    }}
                  >
                    <HelperCard>
                      <Typography variant="body1">
                        <em>Build your ASIN list by keyword or Store ID</em>
                      </Typography>
                    </HelperCard>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', ml: 2 }}>
                      <HelperArrow
                        style={{
                          '-webkit-transform': 'rotate(180deg)',
                          '-moz-transform': 'rotate(180deg)',
                          '-ms-transform': 'rotate(180deg)',
                          '-o-transform': 'rotate(180deg)',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <LinkButton
                  sx={{
                    m: 0,
                    p: 0,
                    color: 'secondary.main',
                    textDecoration: 'underline',
                    '&:hover': {
                      textDecoration: 'underline',
                      bgcolor: 'transparent',
                    },
                  }}
                  type="button"
                  data-cy="Amazon_ASIN_lookup"
                  startIcon={<Search fill={theme.palette.secondary.main} />}
                  onClick={() => setAsinLookupOpen(true)}
                >
                  Amazon ASIN Lookup
                </LinkButton>
              </Box>
            </Box>
            {maxExceeded && (
              <Box
                sx={{
                  borderRadius: theme.borderRadius,
                  bgcolor: '#EBF7FF',
                  width: '100%',
                  p: '10px 10px 7px 10px',
                  mt: '9px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontWeight: '600',
                  color: 'greys.silver',
                }}
              >
                <Box>
                  <LockIcon />
                </Box>
                <Box>
                  {tenant.tenant === 'ipsecure' && (
                    <>
                      {`You've reached the ASIN limit on your current plan (${account.asinLimit} ASINs). Get access to add more ASINs by `}
                      <LinkButton
                        hyperStyle
                        sx={{ pb: '3px' }}
                        onClick={() => history.push('/settings/plan')}
                      >
                        upgrading now
                      </LinkButton>
                    </>
                  )}
                  {tenant.tenant !== 'ipsecure' && (
                    <>
                      You have reached the ASIN limit ({account.asinLimit} ASINs). Get access to add
                      more ASINs by contacting {tenant.title}.
                    </>
                  )}
                </Box>
              </Box>
            )}
            <Box sx={{ maxHeight: `${Math.min(236, asins.length * 48)}px`, overflow: 'scroll' }}>
              <Box
                sx={{
                  position: 'absolute',
                  width: '230px',
                  transform: `translateX(-250px) translateY(${10 - scrollTop}px)`,
                  zIndex: DIALOG_ZINDEX - 2,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
                  <HelperArrow />
                </Box>
                <HelperCard>
                  <Typography variant="body1">
                    <em>Enter ASIN numbers to begin scoring all Seller Offers</em>
                  </Typography>
                </HelperCard>
              </Box>
              {asins.map((asin, index) => (
                <Box key={asin.id} sx={{ mb: '8px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Tooltip title={asin.title || ''}>
                        <TextField
                          fullWidth
                          required={index === 0}
                          variant="standard"
                          placeholder="Enter ASIN №"
                          data-cy="register_asin_number"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={asin.number || ''}
                          key={`asin.${asin.id}`}
                          error={Boolean(formErrors[`asin.${asin.id}`])}
                          helperText={formErrors[`asin.${asin.id}`]}
                          onChange={(e) => {
                            setAsins(
                              asins.map((newAsin) => {
                                if (asin.id === newAsin.id) {
                                  return {
                                    id: newAsin.id,
                                    number: e.target.value,
                                    price: newAsin.price,
                                    title: '',
                                  };
                                }
                                return newAsin;
                              }),
                            );
                          }}
                          onFocus={() => handleOnFocus(index)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        placeholder="MAP Price"
                        data-cy="register_map_price"
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start" sx={{ mb: '1px' }}>
                              $
                            </InputAdornment>
                          ),
                        }}
                        value={asin.price || ''}
                        key={`price.${asin.id}`}
                        error={Boolean(formErrors[`price.${asin.id}`])}
                        helperText={formErrors[`price.${asin.id}`]}
                        onChange={(e) => {
                          setAsins(
                            asins.map((newAsin) => {
                              if (asin.id === newAsin.id) {
                                return {
                                  id: newAsin.id,
                                  number: newAsin.number,
                                  price: e.target.value,
                                  title: '',
                                };
                              }
                              return newAsin;
                            }),
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    display: 'flex',
                    position: 'absolute',
                    width: '260px',
                    transform: `translateX(-40px) translateY(${24 - scrollTop}px)`,
                    zIndex: DIALOG_ZINDEX - 2,
                  }}
                >
                  <HelperCard>
                    <Typography variant="body1">
                      <em>Have a MAP policy? Include ideal retail price</em>
                    </Typography>
                  </HelperCard>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', ml: 2 }}>
                    <HelperArrow
                      style={{
                        '-webkit-transform': 'rotate(90deg) scaleX(-1)',
                        '-moz-transform': 'rotate(90deg) scaleX(-1)',
                        '-ms-transform': 'rotate(90deg) scaleX(-1)',
                        '-o-transform': 'rotate(90deg) scaleX(-1)',
                        transform: 'rotate(90deg) scaleX(-1)',
                        marginLeft: '-29px',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <LoadingButton fullWidth type="submit" loading={loading}>
              Save
            </LoadingButton>
          </Box>
        </form>
        {showImportAsins && (
          <ImportAsins
            onboarding
            isOpen={showImportAsins}
            onClose={(selectedProducts) => {
              if (selectedProducts) {
                const existingAsins = asins.filter((asin) => asin.number);
                const newAsins = selectedProducts.map((selectedProduct, index) => {
                  const price = selectedProduct?.price
                    ? Intl.NumberFormat(region, {
                        style: 'currency',
                        currency: regionCurrency,
                      })
                        .format(selectedProduct?.price?.replace(/[^0-9.-]+/g, ''))
                        .toFixed(2)
                    : null;
                  return {
                    id: `${existingAsins?.length + index}`,
                    number: selectedProduct?.asin,
                    price,
                    title: selectedProduct?.title,
                  };
                });
                setAsins([...existingAsins, ...newAsins]);
              }
              setShowImportAsins(false);
            }}
          />
        )}
        {asinLookupOpen && (
          <OnboardingAsinLookup
            onClose={() => {
              setAsinLookupOpen(false);
            }}
            onAddAsins={(selectedProducts) => {
              const existingAsins = asins.filter((asin) => asin.number);
              const newAsins = selectedProducts.map((selectedProduct, index) => {
                const price = selectedProduct?.price
                  ? Intl.NumberFormat(region, {
                      style: 'currency',
                      currency: regionCurrency,
                    })
                      .format(selectedProduct?.price?.replace(/[^0-9.-]+/g, ''))
                      .toFixed(2)
                  : null;
                return {
                  id: `${existingAsins?.length + index}`,
                  number: selectedProduct?.asin,
                  price,
                  title: selectedProduct?.title,
                };
              });
              setAsins([...existingAsins, ...newAsins]);
            }}
            isOpen={asinLookupOpen}
          />
        )}
      </Box>
    </Box>
  );
};

AddProducts.propTypes = {
  onProductsAdded: PropTypes.func.isRequired,
  scrollTop: PropTypes.number.isRequired,
};

export default AddProducts;
