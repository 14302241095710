/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Card,
  IconButton,
  Box,
  FormControl,
  InputBase,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Editor } from '@tinymce/tinymce-react';
import { ToastDataContext, AuthDataContext, RegionDataContext } from 'contexts';
import { formatDate } from 'utils/dates';
import {
  CustomDialog,
  CustomDialogContent,
  Loading,
  LoadingButton,
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from 'components';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import { KeyboardArrowRightRounded } from '@mui/icons-material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import LetterDialog from 'pages/Settings/Letters/LetterPaywall';
import AddressEditor from 'pages/Settings/Addresses/AddressEditor';
import LetterSuccessDialog from 'pages/Settings/Letters/LetterSuccessDialog';
import { cloneDeep } from 'lodash';
import letterService from 'services/letter-service';
import addressService from 'services/address-service';
import actionService from 'services/action-service';
import { useTracking } from 'react-tracking';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

const CustomInputBase = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:Mui-focus': {
      backgroundColor: 'transparent',
    },
  },
}));

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    minHeight: `calc(100vh - 150px)`,
    maxHeight: `calc(100vh - 150px)`,
    minWidth: `calc(100vw - 150px)`,
    maxWidth: `calc(100vw - 150px)`,
  },
  dialogNormalButtons: {
    paddingLeft: '24px',
    paddingRight: '24px',
    justifyContent: 'space-between',
  },
  trailingButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  leadingButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogTitle: {
    padding: '0px 24px',
  },
  dialogContent: {
    padding: '0px 24px',
  },
  input: {
    padding: '0px 10px 0px 10px',
    minHeight: '50px',
    fontSize: '16px',
    fontWeight: '600',
    backgroundColor: theme.palette.greys.lightGrey,
    borderRadius: '8px',
  },
  ruleContainer: {
    marginTop: '1rem',
  },
  inlineValue: {
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    width: '75px',
    padding: '5px 10px 2px 10px',
    backgroundColor: '#EAEAEA',
    borderRadius: '8px',
  },
  formLabel: {
    fontWeight: '600',
    textTransform: 'uppercase',
    color: theme.palette.greys.black,
    '&.Mui-focused': {
      color: theme.palette.greys.black,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '3px 4px 1px 4px',
    textTransform: 'uppercase',
    padding: '7px 1px 7px 1px',
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: '.7rem',
    display: 'inline-block',
    alignItems: 'center',
    backgroundColor: 'rgba(14, 82, 176, 0.1)',
    color: 'rgba(14, 82, 176, 1)',
    cursor: 'pointer',
  },
  select: {
    backgroundColor: `${theme.palette.greys.lightGrey} !important`,
  },
  menuSelected: {
    backgroundColor: `${theme.palette.greys.lightGrey} !important`,
  },
  dialogEditButtons: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  templateName: {
    marginBottom: '20px',
  },
  caretDownIcon: {
    fill: theme.palette.greys.greys,
  },
  addIcon: {
    fill: theme.palette.greys.grey,
  },
  greyButton: {
    borderColor: theme.palette.greys.lightGrey,
    '&:hover': {
      backgroundColor: theme.palette.greys.backgroundGrey,
      borderColor: theme.palette.greys.lightGrey,
    },
  },
  formDiv: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    height: '100%',
  },
  subtitle: {
    color: theme.palette.greys.silver,
  },
  templateDropdownEmpty: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.greys.white,
    fill: theme.palette.greys.white,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
    },
    flex: '1 1 0px',
  },
  templateDropdownSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.greys.white,
    flex: '1 1 0px',
  },
  hr: {
    width: '100%',
    margin: 0,
    padding: 0,
    borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
  },
  newTemplateIcon: {
    color: theme.palette.greys.silver,
  },
  card: {
    borderRadius: '6px',
    border: '1px solid #DDE2E4',
    padding: '6px',
    cursor: 'pointer',
    boxShadow: 'none',
    fontSize: '12px',
    '&:hover': {
      background: theme.palette.greys.backgroundGrey,
    },
    flex: '1 1 0px',
  },

  tagItem: {
    backgroundColor: theme.palette.greys.lightGrey,
    fontSize: '14px',
    borderRadius: '30px',
    height: '30px',
    padding: '0 4px 0 1rem',
    display: 'inline-flex',
    alignItems: 'center',
    margin: '0 0.3rem 0.3rem 0',
  },

  tagItemButton: {
    backgroundColor: theme.palette.greys.white,
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    font: 'inherit',
    marginLeft: '10px',
    fontWeight: 'bold',
    padding: 0,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const LetterEditor = ({
  classes,
  isOpen,
  onClose,
  addMode,
  editMode,
  editTemplate,
  sendMode,
  emailMode,
  sellerId,
  sellerName,
  enforcementId,
  offers,
}) => {
  const contentSizeLimit = 200000; // Images that are too large in the HTML can cause 3rd parties like PostGrid to fail
  const editorRef = useRef(null);
  const subjectRef = useRef();
  const [subjectHasFocus, setSubjectHasFocus] = useState(false);
  const [editorHasFocus, setEditorHasFocus] = useState(true);
  const [open, setOpen] = useState(isOpen);
  const [previousOpen, setPreviousOpen] = useState();
  const [templateName, setTemplateName] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [sendTemplate, setSendTemplate] = useState('');
  const [sendTemplates, setSendTemplates] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fromAddress, setFromAddress] = useState(null);
  const [toAddress, setToAddress] = useState(null);
  const [toEmail, setToEmail] = useState(null);
  const [fromEmail, setFromEmail] = useState(null);
  const [subjectLine, setSubjectLine] = useState(null);
  const [toEmailError, setToEmailError] = useState(null);
  const [contentError, setContentError] = useState(null);
  const [fromEmailError, setFromEmailError] = useState(null);
  const [subjectLineError, setSubjectLineError] = useState(null);
  const [cc, setCC] = useState([]);
  const [ccValue, setCCValue] = useState('');
  const [ccError, setCCError] = useState(null);
  const [showCC, setShowCC] = useState(false);
  const [bcc, setBCC] = useState([]);
  const [bccValue, setBCCValue] = useState('');
  const [bccError, setBCCError] = useState(null);
  const [showBCC, setShowBCC] = useState(false);
  const [toName, setToName] = useState(sellerName);
  const [letterType, setLetterType] = useState(null);
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [returnAddressError, setReturnAddressError] = useState(false);
  const [toAddressError, setToAddressError] = useState(null);
  const [fatalToAddressError, setFatalToAddressError] = useState(false);
  const [localSendMode, setLocalSendMode] = useState(false);
  const [success, setSuccess] = useState(false);
  const [addressEditorOpen, setAddressEditorOpen] = useState(false);
  const [returnAddressEditorOpen, setReturnAddressEditorOpen] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [returnAddressToEdit, setReturnAddressToEdit] = useState(null);
  const [followUp, setFollowUp] = useState(0);
  const [fromEmailAddresses, setFromEmailAddresses] = useState([]);
  const [toEmailAddresses, setToEmailAddresses] = useState([]);
  const DROPDOWN_NONE_VALUE = '_DROPDOWN_NONE_VALUE_';
  const { user, region, account } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const keyPressedArray = ['Enter', 'Tab', ','];
  const editorPlaceholder = emailMode
    ? 'Please enter email content here...'
    : 'Please enter letter content here...';
  const [templateVariables, setTemplateVariables] = useState([
    {
      type: 'item',
      id: 'DATE',
      description: 'Date',
    },
    {
      type: 'item',
      id: 'FROM_ADDRESS',
      description: 'From Address',
    },
    {
      type: 'item',
      id: 'FROM_EMAIL',
      description: 'From Email',
    },
    {
      type: 'item',
      id: 'FROM_NAME',
      description: 'From Name',
    },
    {
      type: 'item',
      id: 'SELLER_NAME',
      description: 'Seller Name',
    },
    {
      type: 'item',
      id: 'BUSINESS_NAME',
      description: 'Seller Business Name',
    },
    {
      type: 'item',
      id: 'SELLER_ADDRESS',
      description: 'Seller Address',
    },
    {
      type: 'group',
      id: 'OFFERS_LIST',
      description: 'Offers List',
      items: [
        {
          type: 'item',
          id: 'OFFERS_GROUP_BEGIN',
          description: '<Offers Group Start>',
        },
        {
          type: 'indentedItem',
          id: 'OFFER_DATE',
          description: 'Offer Date',
        },
        {
          type: 'indentedItem',
          id: 'OFFER_PRICE',
          description: 'Offer Price',
        },
        {
          type: 'indentedItem',
          id: 'OFFER_MAP',
          description: 'Offer MAP',
        },
        {
          type: 'indentedItem',
          id: 'OFFER_PRODUCT',
          description: 'Offer Product Name',
        },
        {
          type: 'indentedItem',
          id: 'OFFER_CATEGORY',
          description: 'Offer Category',
        },
        {
          type: 'indentedItem',
          id: 'OFFER_ASIN',
          description: 'Offer ASIN',
        },
        {
          type: 'item',
          id: 'OFFERS_GROUP_END',
          description: '<Offers Group End>',
        },
      ],
    },
    {
      type: 'group',
      id: 'PERMITTED_ASINS_LIST',
      description: 'Permitted ASINs List',
      items: [
        {
          type: 'item',
          id: 'PERMITTED_ASINS_GROUP_BEGIN',
          description: '<Permitted ASINs Group Start>',
        },
        {
          type: 'indentedItem',
          id: 'PERMITTED_ASIN_ID',
          description: 'Permitted ASIN',
        },
        {
          type: 'indentedItem',
          id: 'PERMITTED_ASIN_PRODUCT',
          description: 'Permitted Product',
        },
        {
          type: 'item',
          id: 'PERMITTED_ASINS_GROUP_END',
          description: '<Permitted ASINs Group End>',
        },
      ],
    },
    {
      type: 'group',
      id: 'UNPERMITTED_ASINS_LIST',
      description: 'Unpermitted ASINs List',
      items: [
        {
          type: 'item',
          id: 'UNPERMITTED_ASINS_GROUP_BEGIN',
          description: '<Unpermitted ASINs Group Start>',
        },
        {
          type: 'indentedItem',
          id: 'UNPERMITTED_ASIN_ID',
          description: 'Unpermitted ASIN',
        },
        {
          type: 'indentedItem',
          id: 'UNPERMITTED_ASIN_PRODUCT',
          description: 'Unpermitted Product',
        },
        {
          type: 'item',
          id: 'UNPERMITTED_ASINS_GROUP_END',
          description: '<Unpermitted ASINs Group End>',
        },
      ],
    },
  ]);
  const { setToast } = useContext(ToastDataContext);
  const { trackEvent } = useTracking();
  const permittedDomainMode = !!((account && account.permittedDomains && account?.permittedDomains?.length > 0 && fromEmailAddresses.length > 0));

  const handleClose = () => {
    onClose();
  };

  const handlePayDialogClose = () => {
    setOpenPayDialog(false);
  };

  const handleSuccessClose = () => {
    setSuccess(false);
    onClose(true);
  };

  const resetEmailFormErrors = () => {
    setToEmailError(null);
    setFromEmailError(null);
    setContentError(null);
    setSubjectLineError(null);
  };

  const isEmail = (email) => {
    const emailRegEx = /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/;
    return emailRegEx.test(email);
  };

  const resetForm = () => {
    if (editMode && editTemplate) {
      setTemplateName(editTemplate.name);
      setSubjectLine(editTemplate.subject);
      setFollowUp(editTemplate.followUpDays);
    } else if (localSendMode && sellerId) {
      setLoading(true);
    } else {
      resetEmailFormErrors();
      setTemplateName(null);
      setSubjectLine(null);
      setFollowUp(0);
      editorRef?.current?.setContent('');
    }
  };

  if (isOpen !== previousOpen) {
    if (isOpen) {
      resetForm();
    }
    setOpen(isOpen);
    setPreviousOpen(isOpen);
  }

  const validateForm = () => {
    const errors = [];
    if (editMode) {
      if (editorRef.current.getContent().length === 0) {
        errors.push('Please enter a letter template.');
      }
    }
    if (sendMode) {
      if (editorRef.current.getContent().length === 0) {
        errors.push('Please select a letter template or write your own message.');
      }
    }
    if (editorRef.current.getContent().length > contentSizeLimit) {
      errors.push(
        'Content too large. This is likely due to an image that is too large. Please resize the image outside of the letter editor, and insert the new smaller image into the document.',
      );
    }
    return errors;
  };

  const validateEmailForm = () => {
    resetEmailFormErrors();
    let errors = 0;
    if (editorRef.current.getContent().length === 0) {
      setContentError('Please enter email content.');
      errors = 1;
    }
    if (editorRef.current.getContent().length > contentSizeLimit) {
      setContentError(
        'Content too large. This is likely due to an image that is too large. Please resize the image outside of the letter editor, and insert the new smaller image into the document.',
      );
      errors = 1;
    }
    if (toEmail === '' || !toEmail) {
      setToEmailError('Please enter a recipient email address.');
      errors = 1;
    } else if (!isEmail(toEmail)) {
      setToEmailError(`${toEmail} is not a valid email address.`);
      errors = 1;
    }
    if (fromEmail === '' || !fromEmail || fromEmail === DROPDOWN_NONE_VALUE) {
      setFromEmailError('Please enter a from email address.');
      errors = 1;
    } else if (!isEmail(fromEmail)) {
      setFromEmailError(`${fromEmail} is not a valid email address.`);
      errors = 1;
    }
    if (subjectLine === '' || !subjectLine) {
      setSubjectLineError('Please enter a subject for the email.');
      errors = 1;
    }

    return errors;
  };

  const replaceSellerAddress = (address, variables) => {
    const newVariables = cloneDeep(variables);
    if (address) {
      const line2 = address.addressLine2 ? `${address.addressLine2}\n` : '';
      const prettyAddress = `${address.addressLine1 || ''}\n${line2}${address.city || ''}, ${
        address.state || ''
      } ${address.zip || ''}`;
      for (let i = 0; i < variables.length; i += 1) {
        const item = variables[i];
        if (item && item.id === 'SELLER_ADDRESS') {
          item.replacement = prettyAddress;
          newVariables[i] = item;
          break;
        }
      }
    }
    return newVariables;
  };

  const verifyAddress = async (a) => {
    const addressToVerify = `${a.addressLine1 || ''}, ${a.addressLine2 || ''}, ${a.city || ''}, ${
      a.state || ''
    } ${a.zip || ''}`;
    const verifiedAddress = await addressService.getVerifiedAddress({
      address: addressToVerify,
    });
    if (!emailMode) {
      if (!verifiedAddress || !verifiedAddress?.deliverable) {
        let fatalError = false;
        let errors = {};
        if (verifiedAddress?.errors.length > 2) {
          errors = JSON.parse(verifiedAddress?.errors);
        }
        setToAddressError([
          <>
            <b>This seller&apos;s address may not be deliverable:</b>
            <br />
            {addressToVerify}
            <br />
            {verifiedAddress?.errors.length > 2 && (
              <Box>
                <b>Issues:</b>
                <br />
              </Box>
            )}
            {Object.keys(errors).map((k) =>
              errors[k].map((i) => (
                <li>
                  {k}: {i}
                </li>
              )),
            )}
            {!a?.addressLine1 && <li>Missing address line 1</li>}
            {!a?.city && <li>Missing city/town</li>}
            {!a?.state && <li>Missing state/province/region</li>}
            {!a?.zip && <li>Missing zip/postal code</li>}
          </>,
        ]);
        if (!verifiedAddress?.state || !verifiedAddress?.zip || !verifiedAddress?.city) {
          if (!a.state && !a.state && !a.zip) {
            fatalError = true;
          }
        }
        setFatalToAddressError(fatalError);
      } else {
        setToAddressError(null);
        setToAddress(verifiedAddress);
        setFatalToAddressError(false);
      }
    }
    setSaving(false);
  };

  const verifyReturnAddress = (a) => {
    if (!a?.name || !a?.addressLine1 || !a?.city || !a?.state || !a?.zip || !a) {
      setReturnAddressError(true);
      setFormErrors([
        'A return address is required. Please enter your address in the profile or a return address in Settings.',
      ]);
    } else {
      setFormErrors([]);
      setReturnAddressError(false);
    }
  };

  const GET_LETTER_DATA_QUERY = gql`
    query GetLetterDataQuery($id: String!, $attachedIdString: String) {
      Account {
        id
        letterTemplates {
          id
          name
          template
          subject
          createdDate
          createdBy
          followUpDays
        }
      }
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
        coordinates
      }
      getEmailAddresses {
        id
        name
        address
      }
      getSellerSimple(id: $id) {
        id
        businessDetails
        businessAddress
        businessName
      }
      getCategories {
        id
        name
      }
      getSellerAuthorizedAsins(id: $id, includeCategories: true) {
        id
        name
        imageUrl
      }
      getSellerUnauthorizedAsins(id: $id) {
        id
        name
        imageUrl
      }
      getCustomFieldsAndValues(areas: ["sellerProfile"], attachedIdString: $attachedIdString, valuesOnly: true) {
        id
        idString
        type
        valueString
        valueAddress {
          addressLine1
          addressLine2
          city
          state
          zip
          country
        }
      }
    }
  `;

  const loadSendData = async (dataToProcess) => {
    if (!dataToProcess) {
      return;
    }
    let templates = dataToProcess.Account.letterTemplates;
    templates = templates.concat().sort((l, r) => l.name.localeCompare(r.name));
    const businessAddresses = dataToProcess.getAddresses.concat();
    const emailAddresses = dataToProcess.getEmailAddresses;
    setFromEmailAddresses(emailAddresses);
    const sellerResult = dataToProcess.getSellerSimple;
    const userProfile = user.profile;
    const categories = dataToProcess.getCategories;
    const fields = dataToProcess.getCustomFieldsAndValues;
    const toEmails = [];
    let businessAddressToCheck = sellerResult.businessAddress ?? '';
    let customFieldName = null;
    for (let i = 0; i < fields?.length; i += 1) {
      const field = fields[i];
      if (field.idString === 'sellerProfileEmail') {
        toEmails.push(field.valueString);
      }
      if (field.idString === 'sellerProfileAlternateAddress') {
        const valueAddress = field.valueAddress ?? {};
        businessAddressToCheck = `${valueAddress.addressLine1}, ${valueAddress.addressLine2}, ${valueAddress.city}, ${valueAddress.state}, ${valueAddress.zip}, ${valueAddress.country}`;
      }
      if (field.idString === 'sellerProfileName') {
        customFieldName = field.valueString;
      }
    }
    toEmails.sort((l, r) => l.localeCompare(r));
    setToEmailAddresses(toEmails);



    const verifiedAddress = await addressService.getVerifiedAddress({
      address: businessAddressToCheck,
    });

    let from = null;
    let prettyAddress = '';
    if (businessAddresses) {
      // Sort so the return type addresses are first
      const sorted = businessAddresses.sort((a, b) => a.addressTypeId < b.addressTypeId);
      for (let i = 0; i < sorted.length; i += 1) {
        const addr = businessAddresses[i];
        // Default it to a return type first, and if no return type if found, return the account address
        if (addr.addressTypeId === 1) {
          const line2 = addr.addressLine2 ? `${addr.addressLine2}\n` : '';
          prettyAddress = `${addr.addressLine1}\n${line2}${addr.city}, ${addr.state} ${addr.zip}`;
          setFromAddress(addr);
          from = addr;
          break;
        } else if (addr.addressTypeId === 3) {
          const line2 = addr.addressLine2 ? `${addr.addressLine2}\n` : '';
          prettyAddress = `${addr.addressLine1}\n${line2}${addr.city}, ${addr.state} ${addr.zip}`;
          setFromAddress(addr);
          from = addr;
        }
      }
    }

    if (account.permittedDomains?.length === 0 || emailAddresses.length === 0) {
      setFromEmail(userProfile?.email);
    } else {
      setFromEmail(DROPDOWN_NONE_VALUE);
    }
    setCCError(null);
    setBCCError(null);

    if (!emailMode) {
      if (!verifiedAddress || !verifiedAddress?.deliverable) {
        let errors = {};
        if (verifiedAddress?.errors.length > 2) {
          errors = JSON.parse(verifiedAddress?.errors);
        }
        setToAddressError(
          <>
            <b>This seller&apos;s address may not be deliverable:</b>
            <br />
            {businessAddressToCheck}
            {!businessAddressToCheck && <Box>NO ADDRESS</Box>}
            <br />
            {verifiedAddress?.errors.length > 2 && (
              <Box>
                <b>Issues:</b>
                <br />
              </Box>
            )}
            {Object.keys(errors).map((k) =>
              errors[k].map((i) => (
                <li>
                  {k}: {i}
                </li>
              )),
            )}
            {!verifiedAddress?.addressLine1 && <li>Missing address line 1 in verified address</li>}
            {!verifiedAddress?.city && <li>Missing city/town in verified address</li>}
            {!verifiedAddress?.state && <li>Missing state/province/region in verified address</li>}
            {!verifiedAddress?.zip && <li>Missing zip/postal code in verified address</li>}
          </>,
        );
        if (!verifiedAddress?.state || !verifiedAddress?.zip || !verifiedAddress?.city) {
          setFatalToAddressError(true);
        }
      }
      setToAddress(verifiedAddress);
      verifyReturnAddress(from);
    }

    setSendTemplates(templates);

    const companyName = customFieldName ?? (sellerResult?.businessName ?? '');
    if (companyName) {
      setBusinessName(companyName);
      setToName(companyName);
    }

    const modifiedOffers = offers.map((o) => {
      if (o.category?.name) {
        return {
          ...o,
          categoryName: o.category.name,
        };
      }

      let categoryName = '';
      for (let i = 0; i < categories.length; i += 1) {
        const category = categories[i];
        if (category.id === o.categoryId) {
          categoryName = category.name;
          break;
        }
      }
      return {
        ...o,
        categoryName,
      };
    });

    const formatPrice = (amount) => {
      if (amount) {
        return `${Intl.NumberFormat(region, {
          style: 'currency',
          currency: regionCurrency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount)}`;
      }
      return '—';
    };

    const offerDates = modifiedOffers.map((o) => formatDate(new Date(o.harvestDate)));
    const offerProductNames = modifiedOffers.map((o) => o.name);
    const offerPrices = modifiedOffers.map((o) => formatPrice(o.price));
    const offerMaps = modifiedOffers.map((o) => formatPrice(o.mapPrice));
    const offerCategories = modifiedOffers.map((o) => o.categoryName);
    const offerAsins = modifiedOffers.map((o) => o.asin);
    const permittedAsins = dataToProcess.getSellerAuthorizedAsins;
    const unpermittedAsins = dataToProcess.getSellerUnauthorizedAsins;

    const firstOffer = modifiedOffers[0];

    const variables = [
      { id: 'ASIN', description: 'ASIN', replacement: firstOffer.asin }, // Keeping for backwards compatability
      { id: 'ASIN_CATEGORY', description: 'Category', replacement: `${firstOffer.categoryName}` }, // Keeping for backwards compatability
      {
        id: 'BUSINESS_NAME',
        description: 'Business Name',
        replacement: companyName,
      },
      { id: 'DATE', description: 'Date', replacement: formatDate(new Date()) },
      {
        id: 'FROM_ADDRESS',
        description: 'From Address',
        replacement: prettyAddress || 'You do not have an address set up for this account.',
      },
      {
        id: 'FROM_EMAIL',
        description: 'From Email',
        replacement: userProfile.email,
      },
      {
        id: 'FROM_NAME',
        description: 'From Name',
        replacement: `${userProfile.firstName} ${userProfile.lastName}`,
      },
      {
        id: 'OFFER_DATE',
        description: 'Offer Date',
        replacement: offerDates,
      },
      {
        id: 'OFFER_PRICE',
        description: 'Offer Price',
        replacement: offerPrices,
      },
      {
        id: 'OFFER_PRODUCT',
        description: 'Product Name',
        replacement: offerProductNames,
      },
      {
        id: 'OFFER_ASIN',
        description: 'Offer ASIN',
        replacement: offerAsins,
      },
      {
        id: 'OFFER_MAP',
        description: 'Offer MAP',
        replacement: offerMaps,
      },
      {
        id: 'OFFER_CATEGORY',
        description: 'Offer Category',
        replacement: offerCategories,
      },
      {
        id: 'SELLER_ADDRESS',
        description: 'Seller Address',
        replacement: '',
      },
      { id: 'SELLER_NAME', description: 'Seller Name', replacement: sellerName },
      { id: 'PERMITTED_ASIN_ID', replacement: permittedAsins.map((a) => a.id) },
      { id: 'PERMITTED_ASIN_PRODUCT', replacement: permittedAsins.map((a) => a.name) },
      { id: 'UNPERMITTED_ASIN_ID', replacement: unpermittedAsins.map((a) => a.id) },
      { id: 'UNPERMITTED_ASIN_PRODUCT', replacement: unpermittedAsins.map((a) => a.name) },
    ];

    setTemplateVariables(replaceSellerAddress(verifiedAddress, variables));
    setLoading(false);
  };


  const getDisplayNameForEmail = (email) => {
    for (let i = 0; i < fromEmailAddresses.length; i += 1) {
      const item = fromEmailAddresses[i];
      if (item.address === email) {
        return item.name;
      }
    }
    return null;
  }

  const [fetchData] = useLazyQuery(GET_LETTER_DATA_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: loadSendData,
    variables: { id: sellerId, attachedIdString: sellerId },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processTemplateLoop = ({
    content,
    subject,
    beginVariable,
    endVariable,
    keyLoopLengthVariable,
  }) => {
    const beginString = `{${beginVariable}}`;
    const endString = `{${endVariable}}`;
    const loopPresent = content.search(new RegExp(beginString, 'g')) !== -1;
    if (loopPresent) {
      let newSubject = cloneDeep(subject);
      let contentToChange = cloneDeep(content);
      const begin = contentToChange.search(new RegExp(beginString, 'g'));
      const end = contentToChange.search(new RegExp(endString, 'g'));
      const loopContent = contentToChange.substr(
        begin + beginString.length,
        end - begin - endString.length - 2,
      );
      const lastTrBeginSt = loopContent.match(/<tr[^>]*>/g)?.pop();
      const lastTrEnStr = loopContent.match(/<\/tr[^>]*>/g)?.pop();
      const trBegin = loopContent.lastIndexOf(lastTrBeginSt);
      const trEnd = loopContent.lastIndexOf(lastTrEnStr);
      let loopLength = 0;
      for (let i = 0; i < templateVariables.length; i += 1) {
        const variable = templateVariables[i];
        if (variable.id === keyLoopLengthVariable) {
          loopLength = variable.replacement.length;
          break;
        }
      }
      // We have a looping table row, let's grab the last row and assume that's where the data is, and repeat it.
      if (trBegin !== -1 && trEnd !== -1) {
        let newContent = loopContent.substr(0, trBegin);
        const bottom = loopContent.substr(trEnd + lastTrEnStr.length);
        const row = loopContent.substr(trBegin, trEnd - trBegin + lastTrEnStr.length);
        for (let j = 0; j < loopLength; j += 1) {
          let newRow = row.slice();
          for (let i = 0; i < templateVariables.length; i += 1) {
            const variable = templateVariables[i];
            if (Array.isArray(variable.replacement)) {
              newRow = newRow.replace(new RegExp(`{${variable.id}}`, 'g'), variable.replacement[j]);
              newSubject = newSubject.replace(
                new RegExp(`{${variable.id}}`, 'g'),
                variable.replacement[j],
              );
            } else {
              newRow = newRow.replace(new RegExp(`{${variable.id}}`, 'g'), variable.replacement);
              newSubject = newSubject.replace(
                new RegExp(`{${variable.id}}`, 'g'),
                variable.replacement,
              );
            }
          }
          newContent += newRow;
        }
        newContent += bottom;
        contentToChange =
          contentToChange.substr(0, begin) +
          newContent +
          contentToChange.substr(end + endString.length);
      } else {
        let newContent = '';
        const row = loopContent.slice();
        for (let j = 0; j < loopLength; j += 1) {
          let newRow = row.slice();
          for (let i = 0; i < templateVariables.length; i += 1) {
            const variable = templateVariables[i];
            if (Array.isArray(variable.replacement)) {
              newRow = newRow.replace(new RegExp(`{${variable.id}}`, 'g'), variable.replacement[j]);
              newSubject = newSubject.replace(
                new RegExp(`{${variable.id}}`, 'g'),
                variable.replacement[j],
              );
            } else {
              newRow = newRow.replace(new RegExp(`{${variable.id}}`, 'g'), variable.replacement);
              newSubject = newSubject.replace(
                new RegExp(`{${variable.id}}`, 'g'),
                variable.replacement,
              );
            }
          }
          newContent += newRow;
        }
        contentToChange =
          contentToChange.substr(0, begin) +
          newContent +
          contentToChange.substr(end + endString.length);
      }
      return { content: contentToChange, subject: newSubject };
    }
    return { content, subject };
  };

  const replaceTemplateVariables = (template) => {
    let content = template.template;
    let newSubject = template.subject;
    setFollowUp(template.followUpDays ?? 0);
    ({ content, subject: newSubject } = processTemplateLoop({
      content,
      subject: newSubject,
      beginVariable: 'OFFERS_GROUP_BEGIN',
      endVariable: 'OFFERS_GROUP_END',
      keyLoopLengthVariable: 'OFFER_ASIN',
    }));
    ({ content, subject: newSubject } = processTemplateLoop({
      content,
      subject: newSubject,
      beginVariable: 'PERMITTED_ASINS_GROUP_BEGIN',
      endVariable: 'PERMITTED_ASINS_GROUP_END',
      keyLoopLengthVariable: 'PERMITTED_ASIN_ID',
    }));
    ({ content, subject: newSubject } = processTemplateLoop({
      content,
      subject: newSubject,
      beginVariable: 'UNPERMITTED_ASINS_GROUP_BEGIN',
      endVariable: 'UNPERMITTED_ASINS_GROUP_END',
      keyLoopLengthVariable: 'UNPERMITTED_ASIN_ID',
    }));
    for (let i = 0; i < templateVariables.length; i += 1) {
      const variable = templateVariables[i];
      if (Array.isArray(variable.replacement)) {
        content = content.replace(new RegExp(`{${variable.id}}`, 'g'), variable.replacement[0]);
        newSubject = newSubject.replace(
          new RegExp(`{${variable.id}}`, 'g'),
          variable.replacement[0],
        );
      } else {
        content = content.replace(new RegExp(`{${variable.id}}`, 'g'), variable.replacement);
        newSubject = newSubject.replace(new RegExp(`{${variable.id}}`, 'g'), variable.replacement);
      }
    }
    editorRef.current.setContent(content);
    setSubjectLine(newSubject);
  };

  const getFollowUpDate = () => {
    let followUpDate = null;
    if (followUp > 0) {
      const dateNow = dayjs();
      followUpDate = dateNow.add(followUp, 'day').startOf('day');
      return followUpDate.toISOString();
    }
    return null;
  };

  const saveTemplate = async (e) => {
    e.preventDefault();
    if (localSendMode) {
      setSaving(true);
      const mailingAddress = {
        name: toName,
        addressLine1: toAddress?.addressLine1,
        addressLine2: toAddress?.addressLine2,
        city: toAddress?.city,
        state: toAddress?.state,
        zip: toAddress?.zip,
        country: 'US',
        addressTypeId: 1,
      };
      const returnAddress = {
        name: fromAddress.name,
        addressLine1: fromAddress.addressLine1,
        addressLine2: fromAddress.addressLine2,
        city: fromAddress.city,
        state: fromAddress.state,
        zip: fromAddress.zip,
        country: 'US',
        addressTypeId: 3,
      };
      try {
        const markup = editorRef.current.getContent();
        if (markup.length > contentSizeLimit) {
          validateForm();
          setSaving(false);
          return;
        }
        // If no enforcementId is present, create one
        let enforcementIdToUse = enforcementId;
        if (!enforcementIdToUse) {
          const result = await actionService.addToEnforcementQueue({
            offers,
          });
          if (result && result.id !== null) {
            enforcementIdToUse = result.id;
          } else {
            setToast({
              type: 'error',
              message: `We're sorry, an error has been encountered while sending your letter. Please contact support. Code LS1`,
            });
            console.error('LetterEditor: no result back from creating a new action item', {
              offers: JSON.stringify(offers),
            });
          }
        }
        if (enforcementIdToUse) {
          // Kind of hacky, but needed to take away weird double-spacing, add the css to the content
          const content = `<html><style>table tbody tr td { font-size: 8px; border: 1px dotted; margin: 0; } </style>${markup}</html>`;
          const result = await letterService.sendLetter({
            description: 'Letter',
            address: mailingAddress,
            returnAddress,
            returnAddressId: fromAddress.externalId,
            letterContent: content,
            letterType: letterType.type,
            priceId: letterType.priceId,
            enforcementId: enforcementIdToUse,
            followUpDate: getFollowUpDate(),
            templateName: sendTemplate.name ?? 'None',
          });
          if (result === true) {
            // eslint-disable-next-line max-len, prefer-template
            const addressString = `${mailingAddress.name ?? ''}, ${
              mailingAddress.addressLine1 ?? ''
            }${mailingAddress.addressLine2 ? ` ${mailingAddress.addressLine2}` : ''}, ${
              mailingAddress.city ?? ''
            }, ${mailingAddress.state ?? ''} ${mailingAddress.zip ?? ''} ${
              mailingAddress.country ?? ''
            }`;
            trackEvent({
              tag: 'letter',
              description: `Sent ${letterType.type} letter to ${addressString}`,
            });
            setSuccess(true);
          } else {
            setToast({
              type: 'error',
              message: `We're sorry, an error has been encountered while sending your letter. Please contact support. Code LS2`,
            });
            console.error('LetterEditor: no result back from sending a letter', {
              description: 'Letter',
              address: mailingAddress,
              returnAddress,
              returnAddressId: fromAddress.externalId,
              letterContent: content,
              letterType: letterType.type,
              priceId: letterType.priceId,
              enforcementId: enforcementIdToUse,
            });
          }
        }
      } catch (err) {
        setToast({ type: 'error', message: err.message });
        console.error(err);
      } finally {
        setSaving(false);
      }
    } else {
      const errors = validateForm();
      if (errors.length > 0) {
        setFormErrors(errors);
      } else {
        setFormErrors([]);
        setSaving(true);
        const templateContent = editorRef.current.getContent();
        if (editMode) {
          const result = await letterService.updateLetterTemplate({
            id: editTemplate.id,
            name: templateName,
            template: templateContent,
            subject: subjectLine,
            followUpDays: followUp,
          });
          onClose(result);
        } else {
          const result = await letterService.addLetterTemplate({
            name: templateName,
            subject: subjectLine,
            template: templateContent,
            followUpDays: followUp,
          });
          if (sendMode !== localSendMode) {
            const templates = await letterService.getLetterTemplates();
            setSendTemplates(templates);
            for (let i = 0; i < templates.length; i += 1) {
              const template = templates[i];
              if (template.id === result.id) {
                setSendTemplate(template);
                replaceTemplateVariables(template);
                break;
              }
            }
            setLocalSendMode(true);
          } else {
            onClose(result);
          }
        }
        setSaving(false);
      }
    }
  };

  const validateCCEmail = (email) => {
    if (cc.includes(email)) {
      setCCError(`${email} has already been added.`);
      return false;
    }

    if (!isEmail(email)) {
      setCCError(`${email} is not a valid email address.`);
      return false;
    }

    setCCError(null);
    return true;
  };

  const validateBCCEmail = (email) => {
    if (bcc.includes(email)) {
      setBCCError(`${email} has already been added.`);
      return false;
    }

    if (!isEmail(email)) {
      setBCCError(`${email} is not a valid email address.`);
      return false;
    }

    setBCCError(null);
    return true;
  };

  const insertText = (text) => {
    if (text && text.length > 0) {
      if (editorHasFocus) {
        editorRef.current.execCommand('mceInsertContent', false, text);
        editorRef.current.execCommand('mceFocus', false, text);
      } else if (subjectHasFocus) {
        const currentValue = subjectLine || '';
        const newValue =
          currentValue.slice(0, subjectRef.current.selectionStart) +
          text +
          currentValue.slice(subjectRef.current.selectionEnd);
        setSubjectLine(newValue);
        const { selectionStart } = subjectRef.current;
        setTimeout(() => {
          subjectRef.current.selectionStart = selectionStart + text.length;
          subjectRef.current.selectionEnd = subjectRef.current.selectionStart;
        }, 1250);
      }
    }
  };

  const focusEditor = () => {
    editorRef?.current?.focus();
  };

  const subjectFocus = () => {
    subjectRef?.current?.focus();
  };

  useEffect(async () => {
    if (sendMode || emailMode) {
      setLocalSendMode(true);
      setLoading(true);
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (addressToEdit) {
      setToName(addressToEdit.name);
      const newToAddress = {
        addressLine1: addressToEdit.addressLine1,
        addressLine2: addressToEdit.addressLine2,
        city: addressToEdit.city,
        state: addressToEdit.state,
        zip: addressToEdit.zip,
        country: 'US',
      };
      setToAddress(newToAddress);
    }
  }, [addressToEdit]);

  useEffect(() => {
    if (toAddress) {
      setTemplateVariables(replaceSellerAddress(toAddress, templateVariables));
    }
  }, [toAddress]);

  const handleTemplateChange = (event) => {
    if (event.target.value !== 0) {
      replaceTemplateVariables(event.target.value);
    }
  };

  const handleEditAddress = () => {
    const address = {
      name: toName,
      addressLine1: toAddress?.addressLine1,
      addressLine2: toAddress?.addressLine2,
      city: toAddress?.city,
      state: toAddress?.state,
      zip: toAddress?.zip,
      country: 'US',
    };
    setAddressToEdit(address);
    setAddressEditorOpen(true);
  };

  const handleEditReturnAddress = () => {
    const address = {
      name: fromAddress?.name,
      addressLine1: fromAddress?.addressLine1,
      addressLine2: fromAddress?.addressLine2,
      city: fromAddress?.city,
      state: fromAddress?.state,
      zip: fromAddress?.zip,
      country: 'US',
      id: fromAddress?.id,
    };
    setReturnAddressToEdit(address);
    setReturnAddressEditorOpen(true);
  };

  const sendEmail = () => {
    const errors = validateEmailForm();
    if (!errors) {
      const emailMarkup = editorRef.current.getContent();
      const emailContent = `<html><style>table tbody tr td { font-size: 8px; border: 1px dotted; margin: 0; } </style>${emailMarkup}</html>`;
      const formattedCC = cc.map((item) => `"${item}"`).join(',');
      const formattedBCC = bcc.map((item) => `"${item}"`).join(',');
      // If no enforcementId is present, create one
      const enforcementIdToUse = enforcementId;

      if (!enforcementIdToUse) {
        actionService
          .addToEnforcementQueue({
            offers,
          })
          .then((result) => {
            if (result.id !== null) {
              letterService
                .sendEmail({
                  enforcementId: result.id,
                  emailContent,
                  CC: `[${formattedCC}]`,
                  BCC: `[${formattedBCC}]`,
                  toEmail,
                  fromEmail,
                  subjectLine,
                  followUpDate: getFollowUpDate(),
                  templateName: sendTemplate.name ?? 'None',
                  permittedDomainMode,
                  fromDisplayName: getDisplayNameForEmail(fromEmail),
                })
                .then(() => {
                  trackEvent({
                    tag: 'email',
                    description: `Sent email to ${toEmail} ${formattedCC}`,
                  });
                  setSuccess(true);
                })
                .catch((err) => {
                  setToast({
                    type: 'error',
                    message: `We're sorry, an error has been encountered while sending your email. Please contact support. Code: SE1`,
                  });
                  console.error(
                    'LetterEditor: error sending the email',
                    err,
                    {
                      offers: JSON.stringify(offers),
                    },
                    {
                      enforcementId: result.id,
                      emailContent,
                      CC: `[${formattedCC}]`,
                      BCC: `[${formattedBCC}]`,
                      toEmail,
                      fromEmail,
                      subjectLine,
                    },
                  );
                });
            } else {
              setToast({
                type: 'error',
                message: `We're sorry, an error has been encountered while sending your email. Please contact support. Code: SE2`,
              });
              console.error(
                'LetterEditor: no result back from creating an action queue item for the email',
                {
                  offers: JSON.stringify(offers),
                },
              );
            }
          })
          .catch((err) => {
            setToast({
              type: 'error',
              message: `We're sorry, an error has been encountered while sending your email. Please contact support. Code SE3`,
            });
            console.error('LetterEditor: error queueing the action item', err, {
              offers: JSON.stringify(offers),
            });
          });
      } else {
        letterService
          .sendEmail({
            enforcementId: enforcementIdToUse,
            emailContent,
            CC: `[${formattedCC}]`,
            BCC: `[${formattedBCC}]`,
            toEmail,
            fromEmail,
            subjectLine,
            followUpDate: getFollowUpDate(),
            templateName: sendTemplate.name ?? 'None',
            permittedDomainMode,
            fromDisplayName: getDisplayNameForEmail(fromEmail),
          })
          .then(() => {
            trackEvent({ tag: 'email', description: `Sent email to ${toEmail} ${formattedCC}` });
            setSuccess(true);
          })
          .catch((err) => {
            setToast({
              type: 'error',
              message: `We're sorry, an error has been encountered while sending your email. Please contact support. Code SE4`,
            });
            console.error('LetterEditor: error sending the email', err, {
              enforcementId: enforcementIdToUse,
              emailContent,
              CC: `[${formattedCC}]`,
              BCC: `[${formattedBCC}]`,
              toEmail,
              fromEmail,
              subjectLine,
            });
          });
      }
    }
  };

  const followUpArray = (() => {
    const arr = [];
    for (let i = 0; i <= 45; i += 1) {
      arr.push(i);
    }
    return arr;
  })();

  return (
    <CustomDialog
      title={
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
          data-cy="letter_template_header"
        >
          {editMode && <>Edit Letter/Email Template</>}
          {!editMode && !localSendMode && !emailMode && <>Add Letter/Email Template</>}
          {emailMode && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3" data-cy="new_email_header">
                    New Email
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle} variant="body1">
                    To: {businessName?.length > 0 ? `${businessName} (${sellerName})` : sellerName}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {!editMode && localSendMode && !emailMode && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3" data-cy="letter_editor_header">
                    New Letter
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle} variant="body1">
                    To: {businessName?.length > 0 ? `${businessName} (${sellerName})` : sellerName}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      }
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          flexGrow: 1,
          height: '100%',
          minHeight: `calc(100vh - 150px)`,
          maxHeight: `calc(100vh - 150px)`,
          minWidth: `calc(100vw - 150px)`,
          maxWidth: `calc(100vw - 150px)`,
        },
      }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      maxWidth="md"
      disableBackdropClick
      content={
        <CustomDialogContent
          bottom
          sx={{ height: '100%', minHeight: '100%' }}
          data-cy="letter_editor"
        >
          <form
            id="template-form"
            onSubmit={saveTemplate}
            style={{ height: '100%', minHeight: '100%' }}
          >
            <div className={classes.formDiv}>
              <div>
                {loading && <Loading />}
                {!localSendMode && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <TextField
                        variant="standard"
                        required
                        fullWidth
                        placeholder="Template name"
                        data-cy="letter_template_name"
                        value={templateName || ''}
                        onChange={(e) => {
                          setTemplateName(e.target.value);
                        }}
                      />
                      <FormControl sx={{ minWidth: '240px', height: '38px' }}>
                        <Select
                          value={followUp}
                          renderValue={(selected) => (
                            <Box>
                              {selected > 0 && (
                                <Box>
                                  Default follow-up:{' '}
                                  <b>
                                    {selected} day{selected > 1 ? 's' : ''}
                                  </b>
                                </Box>
                              )}
                              {selected === 0 && (
                                <Box>
                                  Default follow-up: <b>None</b>
                                </Box>
                              )}
                            </Box>
                          )}
                          MenuProps={{
                            style: {
                              maxHeight: 386,
                            },
                          }}
                        >
                          {followUpArray.map((item) => (
                            <MenuItem
                              value={item}
                              key={item}
                              onClick={() => {
                                setFollowUp(item);
                              }}
                              sx={{ fontWeight: item === followUp ? 700 : 500 }}
                            >
                              {item > 0 && (
                                <Box>
                                  {item} day{item > 1 ? 's' : ''}
                                </Box>
                              )}
                              {item === 0 && <Box>None</Box>}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <TextField
                      inputRef={subjectRef}
                      required
                      fullWidth
                      placeholder="Subject (for emails)"
                      data-cy="letter_template_subject"
                      value={subjectLine || ''}
                      onFocus={() => {
                        setSubjectHasFocus(true);
                        setEditorHasFocus(false);
                      }}
                      error={subjectLineError}
                      helperText={subjectLineError || ''}
                      onChange={(e) => setSubjectLine(e.target.value)}
                    />
                  </Box>
                )}
                {localSendMode && !loading && sendMode && (
                  <div style={{ display: 'flex', columnGap: '14px' }}>
                    <Card onClick={handleEditReturnAddress} className={classes.card}>
                      <div
                        style={{
                          display: 'flex',
                          columnGap: '14px',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: '700',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          Return:
                        </div>
                        <div>
                          <div>{fromAddress?.name}</div>
                          <div>
                            {fromAddress?.addressLine1}
                            <br />
                            {fromAddress?.addressLine2 ? `${fromAddress?.addressLine2}` : null}
                            {fromAddress?.addressLine2 && <br />}
                            {fromAddress?.city}
                            {fromAddress?.city && ','} {fromAddress?.state} {fromAddress?.zip}
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card onClick={handleEditAddress} className={classes.card}>
                      <div
                        style={{
                          display: 'flex',
                          columnGap: '14px',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: '700',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          To:
                        </div>
                        <div>
                          <div>{toName}</div>
                          <div>
                            {toAddress?.addressLine1}
                            <br />
                            {toAddress?.addressLine2 ? `${toAddress?.addressLine2}` : ''}
                            {toAddress?.addressLine2 && <br />}
                            {toAddress?.city}
                            {toAddress?.city && ','} {toAddress?.state} {toAddress?.zip}
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: 2,
                        minWidth: '350px',
                        maxWidth: '350px',
                      }}
                    >
                      <Select
                        classes={{
                          root:
                            sendTemplate === ''
                              ? classes.templateDropdownEmpty
                              : classes.templateDropdownSelected,
                          select:
                            sendTemplate === ''
                              ? classes.templateDropdownEmpty
                              : classes.templateDropdownSelected,
                        }}
                        className={
                          sendTemplate === ''
                            ? classes.templateDropdownEmpty
                            : classes.templateDropdownSelected
                        }
                        inputProps={{
                          classes: {
                            icon:
                              sendTemplate === ''
                                ? classes.templateDropdownEmpty
                                : classes.templateDropdownSelected,
                          },
                        }}
                        input={<CustomInputBase />}
                        fullWidth
                        displayEmpty
                        labelId="templateLabel"
                        value={sendTemplate || ''}
                        onChange={handleTemplateChange}
                        MenuProps={{ variant: 'menu' }}
                        renderValue={(value) => (
                          <div style={{ fontSize: '14px', fontWeight: '600' }}>
                            {value === '' && <div>Choose template</div>}
                            {value !== '' && <div>{value.name}</div>}
                          </div>
                        )}
                      >
                        {sendTemplates.map((template) => (
                          <MenuItem
                            key={template.id}
                            value={template}
                            classes={{ selected: classes.menuSelected }}
                            disableTouchRipple
                            onClick={() => {
                              setSendTemplate(template);
                              setFormErrors([]);
                            }}
                          >
                            {template.name}
                          </MenuItem>
                        ))}
                        {sendTemplates.length > 0 && <div className={classes.hr} />}
                        <MenuItem
                          value={0}
                          disableTouchRipple
                          onClick={() => {
                            setTemplateName(null);
                            editorRef?.current?.setContent('');
                            setLocalSendMode(false);
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AddIcon className={classes.newTemplateIcon} />
                            Create new template
                          </div>
                        </MenuItem>
                      </Select>
                      <FormControl sx={{ width: '100%', height: '38px' }}>
                        <Select
                          value={followUp}
                          renderValue={(selected) => (
                            <Box>
                              {selected > 0 && (
                                <Box>
                                  Follow-up:{' '}
                                  <b>
                                    {selected} day{selected > 1 ? 's' : ''}
                                  </b>
                                </Box>
                              )}
                              {selected === 0 && (
                                <Box>
                                  Follow-up: <b>None</b>
                                </Box>
                              )}
                            </Box>
                          )}
                          MenuProps={{
                            style: {
                              maxHeight: 386,
                            },
                          }}
                        >
                          {followUpArray.map((item) => (
                            <MenuItem
                              value={item}
                              key={item}
                              onClick={() => {
                                setFollowUp(item);
                              }}
                              sx={{ fontWeight: item === followUp ? 700 : 500 }}
                            >
                              {item > 0 && (
                                <Box>
                                  {item} day{item > 1 ? 's' : ''}
                                </Box>
                              )}
                              {item === 0 && <Box>None</Box>}
                            </MenuItem>
                          ))}
                          {/* </Menu> */}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
                {/* Email Letter Editor */}
                {localSendMode && !loading && emailMode && (
                  <Box sx={{ width: '100%', display: 'flex', columnGap: '14px', rowGap: '10px' }}>
                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {toEmailAddresses.length === 0 && (
                        <TextField
                          required
                          fullWidth
                          value={toEmail}
                          placeholder="To"
                          error={toEmailError}
                          helperText={toEmailError || ''}
                          onChange={(e) => setToEmail(e.target.value)}
                        />
                      )}
                      {toEmailAddresses.length > 0 && (
                        <>
                          <FormControl sx={{ width: '100%', height: '38px' }}>
                            <Select
                              error={toEmailError}
                              value={toEmail ?? DROPDOWN_NONE_VALUE}
                              renderValue={(selected) => (
                                <Box>
                                  {selected !== DROPDOWN_NONE_VALUE && (
                                    <Box>
                                      {selected}
                                    </Box>
                                  )}
                                  {selected === DROPDOWN_NONE_VALUE && (
                                    <Box sx={{ color: 'greys.grey' }}>
                                      To
                                    </Box>
                                  )}
                                </Box>
                              )}
                              MenuProps={{
                                style: {
                                  maxHeight: 386,
                                },
                              }}
                            >
                              <MenuItem key="NULL" value={DROPDOWN_NONE_VALUE} disabled>
                                None
                              </MenuItem>
                              {toEmailAddresses.map((item) => (
                                <MenuItem
                                  value={item}
                                  key={item}
                                  onClick={() => {
                                    setToEmail(item);
                                  }}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {toEmailError && (
                            <Box sx={{ fontSize: '12px', color: 'error.main', mt: -1 }}>{toEmailError}</Box>
                          )}
                        </>
                      )}
                      {!permittedDomainMode && (
                        <TextField
                          required
                          fullWidth
                          placeholder="From"
                          value={fromEmail}
                          error={fromEmailError}
                          helperText={fromEmailError || ''}
                          onChange={(e) => setFromEmail(e.target.value)}
                        />
                      )}
                      {permittedDomainMode && (
                        <>
                          <FormControl sx={{ width: '100%', height: '38px' }}>
                            <Select
                              error={fromEmailError}
                              value={fromEmail}
                              renderValue={(selected) => (
                                <Box>
                                  {selected !== DROPDOWN_NONE_VALUE && (
                                    <Box>
                                      {selected} {getDisplayNameForEmail(selected) && ` (${getDisplayNameForEmail(selected)})`}
                                    </Box>
                                  )}
                                  {selected === DROPDOWN_NONE_VALUE && (
                                    <Box sx={{ color: 'greys.grey' }}>
                                      From
                                    </Box>
                                  )}
                                </Box>
                              )}
                              MenuProps={{
                                style: {
                                  maxHeight: 386,
                                },
                              }}
                            >
                              <MenuItem key="NULL" value={DROPDOWN_NONE_VALUE} disabled>
                                None
                              </MenuItem>
                              {fromEmailAddresses.map((item) => (
                                <MenuItem
                                  value={item.address}
                                  key={item.id}
                                  onClick={() => {
                                    setFromEmail(item.address);
                                  }}
                                > 
                                  {item.name && `${item.address} (${item.name})`}
                                  {!item.name && `${item.address}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {fromEmailError && (
                            <Box sx={{ fontSize: '12px', color: 'error.main', mt: -1 }}>{fromEmailError}</Box>
                          )}
                        </>
                      )}
                      <Box>
                        <TextField
                          inputRef={subjectRef}
                          required
                          fullWidth
                          placeholder="Subject Line"
                          onFocus={() => {
                            setSubjectHasFocus(true);
                            setEditorHasFocus(false);
                          }}
                          value={subjectLine || ''}
                          error={subjectLineError}
                          helperText={subjectLineError || ''}
                          onChange={(e) => setSubjectLine(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '10px' }}
                    >
                      <Select
                        classes={{
                          root:
                            sendTemplate === ''
                              ? classes.templateDropdownEmpty
                              : classes.templateDropdownSelected,
                          select:
                            sendTemplate === ''
                              ? classes.templateDropdownEmpty
                              : classes.templateDropdownSelected,
                        }}
                        className={
                          sendTemplate === ''
                            ? classes.templateDropdownEmpty
                            : classes.templateDropdownSelected
                        }
                        inputProps={{
                          classes: {
                            icon:
                              sendTemplate === ''
                                ? classes.templateDropdownEmpty
                                : classes.templateDropdownSelected,
                          },
                        }}
                        input={<CustomInputBase />}
                        fullWidth
                        displayEmpty
                        labelId="templateLabel"
                        value={sendTemplate || ''}
                        onChange={handleTemplateChange}
                        MenuProps={{ variant: 'menu' }}
                        renderValue={(value) => (
                          <div style={{ fontSize: '14px', fontWeight: '600' }}>
                            {value === '' && <div>Choose template</div>}
                            {value !== '' && <div>{value.name}</div>}
                          </div>
                        )}
                      >
                        {sendTemplates.map((template) => (
                          <MenuItem
                            key={template.id}
                            value={template}
                            classes={{ selected: classes.menuSelected }}
                            disableTouchRipple
                            onClick={() => {
                              setSendTemplate(template);
                            }}
                          >
                            {template.name}
                          </MenuItem>
                        ))}
                        {sendTemplates.length > 0 && <div className={classes.hr} />}
                        <MenuItem
                          value={0}
                          disableTouchRipple
                          onClick={() => {
                            setTemplateName(null);
                            editorRef?.current?.setContent('');
                            setLocalSendMode(false);
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AddIcon className={classes.newTemplateIcon} />
                            Create new template
                          </div>
                        </MenuItem>
                      </Select>
                      <FormControl sx={{ width: '100%', height: '38px' }}>
                        <Select
                          value={followUp}
                          renderValue={(selected) => (
                            <Box>
                              {selected > 0 && (
                                <Box>
                                  Follow-up:{' '}
                                  <b>
                                    {selected} day{selected > 1 ? 's' : ''}
                                  </b>
                                </Box>
                              )}
                              {selected === 0 && (
                                <Box>
                                  Follow-up: <b>None</b>
                                </Box>
                              )}
                            </Box>
                          )}
                          MenuProps={{
                            style: {
                              maxHeight: 386,
                            },
                          }}
                        >
                          {followUpArray.map((item) => (
                            <MenuItem
                              value={item}
                              key={item}
                              onClick={() => {
                                setFollowUp(item);
                              }}
                              sx={{ fontWeight: item === followUp ? 700 : 500 }}
                            >
                              {item > 0 && (
                                <Box>
                                  {item} day{item > 1 ? 's' : ''}
                                </Box>
                              )}
                              {item === 0 && <Box>None</Box>}
                            </MenuItem>
                          ))}
                          {/* </Menu> */}
                        </Select>
                      </FormControl>
                      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-evenly' }}>
                        {!showCC && (
                          <IconButton onClick={() => setShowCC(true)}>
                            <Typography>CC</Typography>
                          </IconButton>
                        )}
                        {showCC && (
                          <TextField
                            error={ccError}
                            helperText={ccError || ''}
                            fullWidth
                            placeholder="CC - Type or paste email addresses and press `Enter`..."
                            value={ccValue}
                            onChange={(e) => setCCValue(e.target.value)}
                            onKeyDown={(e) => {
                              const value = e.target.value.trim();
                              if (keyPressedArray.includes(e.key)) {
                                e.preventDefault();
                                if (value && validateCCEmail(value)) {
                                  setCC([...cc, value]);
                                  setCCValue('');
                                }
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();

                              const paste = e.clipboardData.getData('text');
                              const emails = paste.match(/[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/g);

                              if (emails) {
                                const toBeAdded = emails.filter((email) => !cc.includes(email));
                                setCC([...cc, ...toBeAdded]);
                              }
                            }}
                          />
                        )}
                        {!showBCC && (
                          <IconButton onClick={() => setShowBCC(true)}>
                            <Typography>BCC</Typography>
                          </IconButton>
                        )}
                        {showBCC && (
                          <TextField
                            error={bccError}
                            helperText={bccError || ''}
                            fullWidth
                            placeholder="BCC - Type or paste email addresses and press `Enter`..."
                            value={bccValue}
                            onChange={(e) => setBCCValue(e.target.value)}
                            onKeyDown={(e) => {
                              const value = e.target.value.trim();
                              if (keyPressedArray.includes(e.key)) {
                                e.preventDefault();
                                if (value && validateBCCEmail(value)) {
                                  setBCC([...bcc, value]);
                                  setBCCValue('');
                                }
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();

                              const paste = e.clipboardData.getData('text');
                              const emails = paste.match(/[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/g);

                              if (emails) {
                                const toBeAdded = emails.filter((email) => !bcc.includes(email));
                                setBCC([...bcc, ...toBeAdded]);
                              }
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    {cc.length > 0 && (
                      <div style={{ width: '100%' }}>
                        CC:&nbsp;
                        {cc.map((email) => (
                          <div className={classes.tagItem} key={email}>
                            {email}
                            <IconButton
                              className={classes.tagItemButton}
                              onClick={() => {
                                setCC(cc.filter((e) => e !== email));
                              }}
                            >
                              &times;
                            </IconButton>
                          </div>
                        ))}
                      </div>
                    )}
                    {bcc.length > 0 && (
                      <div style={{ width: '100%' }}>
                        BCC:&nbsp;
                        {bcc.map((email) => (
                          <div className={classes.tagItem} key={email}>
                            {email}
                            <IconButton
                              className={classes.tagItemButton}
                              onClick={() => {
                                setBCC(bcc.filter((e) => e !== email));
                              }}
                            >
                              &times;
                            </IconButton>
                          </div>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </div>
              {formErrors.length > 0 && (
                <div style={{ display: 'flex' }}>
                  {formErrors.map((error) => (
                    <Typography key={error} color="error">
                      {error}
                    </Typography>
                  ))}
                </div>
              )}
              {toAddressError && (
                <div style={{ display: 'flex' }}>
                  <Box sx={{ color: 'error.main', fontSize: '14px' }}>{toAddressError}</Box>
                </div>
              )}
              {emailMode && contentError && (
                <Typography color="error" style={{ marginTop: '12px' }}>
                  {contentError}
                </Typography>
              )}
              <>
                {(!localSendMode || (localSendMode && !loading)) && (
                  <>
                    <Editor
                      onFocus={() => {
                        setSubjectHasFocus(false);
                        setEditorHasFocus(true);
                      }}
                      onInit={(evt, editor) => {
                        editorRef.current = editor;
                      }}
                      initialValue={editMode && editTemplate?.template ? editTemplate.template : ''}
                      init={{
                        content_css: '/tinymce.css',
                        height: '100%',
                        menubar: false,
                        branding: false,
                        statusbar: false,
                        resize: false,
                        paste_data_images: true,
                        quickbars_insert_toolbar: false,
                        placeholder: editorPlaceholder,
                        plugins: [
                          'advlist lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code quickbars',
                        ],
                        toolbar:
                          'undo redo | cut copy paste | formatselect | ' +
                          'bold italic backcolor underline | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat pastetext | quickimage quicktable',
                        toolbar_location: 'bottom',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      }}
                    />
                  </>
                )}
              </>
            </div>
          </form>
        </CustomDialogContent>
      }
      actions={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {!(templateVariables && (editMode || addMode)) && <Box />}
          {templateVariables && (editMode || addMode) && (
            <div>
              {templateVariables && (
                <Dropdown
                  trigger={
                    <Button
                      startIcon={<AddIcon className={classes.addIcon} />}
                      endIcon={<CaretDown className={classes.caretDownIcon} />}
                      disabled={loading || saving}
                      variant="outlined"
                      // onClick={handleClick}
                      className={classes.greyButton}
                    >
                      Insert
                    </Button>
                  }
                  menu={templateVariables.map((item) => {
                    if (item.type === 'group') {
                      return (
                        <DropdownNestedMenuItem
                          label={item.description}
                          rightIcon={<ArrowRight />}
                          menu={item.items.map((innerItem) => (
                            <DropdownMenuItem
                              key={innerItem.id}
                              onClick={() => {
                                insertText(
                                  localSendMode ? innerItem.replacement : `{${innerItem.id}}`,
                                );
                                if (editorHasFocus) {
                                  focusEditor();
                                } else {
                                  subjectFocus();
                                }
                              }}
                            >
                              <Box sx={{ ml: innerItem.type === 'indentedItem' ? 4 : 0 }}>
                                {innerItem.description}
                              </Box>
                            </DropdownMenuItem>
                          ))}
                        />
                      );
                    }
                    return (
                      <DropdownMenuItem
                        key={item.id}
                        onClick={() => {
                          insertText(localSendMode ? item.replacement : `{${item.id}}`);
                          if (editorHasFocus) {
                            focusEditor();
                          } else {
                            subjectFocus();
                          }
                        }}
                      >
                        {item.description}
                      </DropdownMenuItem>
                    );
                  })}
                />
              )}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              columnGap: '14px',
            }}
          >
            <LoadingButton
              disabled={loading || saving}
              variant="outlined"
              data-cy="letter_cancel"
              onClick={() => {
                if (sendMode !== localSendMode && !emailMode) {
                  setSendTemplate('');
                  setLocalSendMode(true);
                  loadSendData();
                } else {
                  onClose();
                }
              }}
              className={classes.greyButton}
            >
              Cancel
            </LoadingButton>
            {!localSendMode && (
              <LoadingButton
                endIcon={localSendMode ? <KeyboardArrowRightRounded /> : ''}
                form="template-form"
                data-cy="letter_save"
                loading={saving}
                disabled={loading || saving || returnAddressError}
                type="submit"
              >
                Save
              </LoadingButton>
            )}
            {localSendMode && sendMode && (
              <LoadingButton
                endIcon={localSendMode ? <KeyboardArrowRightRounded /> : ''}
                loading={saving}
                disabled={loading || saving || returnAddressError || fatalToAddressError}
                onClick={() => {
                  const errors = validateForm();
                  if (errors.length === 0) {
                    setFormErrors([]);
                    setOpenPayDialog(true);
                  } else {
                    setFormErrors(errors);
                  }
                }}
              >
                <Typography>Continue</Typography>
              </LoadingButton>
            )}
            {localSendMode && emailMode && (
              <LoadingButton
                endIcon={<KeyboardArrowRightRounded />}
                loading={saving}
                disabled={loading || saving}
                onClick={() => sendEmail()}
              >
                <Typography>Send</Typography>
              </LoadingButton>
            )}
          </div>
          <LetterDialog
            open={openPayDialog}
            sendLetter={saveTemplate}
            handleClose={handlePayDialogClose}
            letterType={letterType}
            setLetterType={setLetterType}
            loading={saving}
          />
          <LetterSuccessDialog email={emailMode} open={success} onClose={handleSuccessClose} />
          {addressEditorOpen && (
            <AddressEditor
              isOpen
              mailingMode
              setIsOpen={setAddressEditorOpen}
              address={addressToEdit}
              setAddress={setAddressToEdit}
              onClose={(result) => {
                if (result) {
                  setAddressToEdit(result);
                  setSaving(true);
                  setFormErrors([]);
                  verifyAddress(result);
                }
                setAddressEditorOpen(false);
              }}
            />
          )}
          {returnAddressEditorOpen && (
            <AddressEditor
              isOpen
              mailingMode
              returnMode
              setIsOpen={setAddressEditorOpen}
              address={returnAddressToEdit}
              setAddress={setReturnAddressToEdit}
              onClose={(result) => {
                if (result) {
                  setReturnAddressToEdit(result);
                  setFromAddress(result);
                  verifyReturnAddress(result);
                }
                setReturnAddressEditorOpen(false);
              }}
            />
          )}
        </div>
      }
    />
  );
};

LetterEditor.defaultProps = {
  classes: null,
  editMode: false,
  sendMode: false,
  emailMode: false,
  editTemplate: null,
  sellerId: null,
  sellerName: null,
  enforcementId: null,
  offers: null,
  addMode: false,
};

LetterEditor.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addMode: PropTypes.bool,
  editMode: PropTypes.bool,
  sendMode: PropTypes.bool,
  emailMode: PropTypes.bool,
  editTemplate: PropTypes.object,
  sellerId: PropTypes.string,
  sellerName: PropTypes.string,
  enforcementId: PropTypes.string,
  offers: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(LetterEditor);
