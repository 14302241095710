import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Drawer, Typography, Box, useTheme } from '@mui/material';
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import SellerContent from './SellerContent';
import OfferContent from './OfferContent';

const drawerWidth = '50vw';
const wideDrawerWidth = 'calc(100vw - 194px)';

const SellerDrawer = ({ open, id, onClose, large, transitionDuration }) => {
  const wideViewSellerDrawer = 'wide-view-seller-drawer-local-storage';
  const [wide, setwide] = useState(
    window.localStorage.getItem(wideViewSellerDrawer) !== 'false',
  );
  const [offerData, setOfferData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const theme = useTheme();

  const onBack = () => {
    setOfferData(null);
  };

  if (open !== previousOpen) {
    if (!large && large !== null) {
      setwide(false);
    }
    setOfferData(null);
    setIsOpen(open);
    setPreviousOpen(open);
  }

  const headerContent = offerData ? (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        fontSize: '14px',
        fontWeight: '400',
        color: 'greys.silver',
      }}
    >
      <Box>
        <NavLink
          to={`/sellers/${id}`}
          style={{ textDecoration: 'underline', color: theme.palette.greys.silver }}
          onClick={(e) => {
            e.preventDefault();
            setOfferData(null);
          }}
        >
          Seller {id}
        </NavLink>
      </Box>
      <Box>&gt;</Box>
      <Box>{offerData?.asin}</Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        fontSize: '14px',
        fontWeight: '400',
        color: 'greys.silver',
      }}
    >
      <Typography>Seller {id}</Typography>
    </Box>
  );

  return (
    <Drawer
      transitionDuration={transitionDuration !== null ? transitionDuration : 200}
      sx={[
        {
          flexShrink: 0,
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
          '& .MuiDrawer-paper': {
            backgroundColor: 'greys.white',
            overflow: 'hidden',
            borderTopLeftRadius: theme.borderRadius,
            borderBottomLeftRadius: theme.borderRadius,
          },
        },
        wide && {
          width: wideDrawerWidth,
          '& .MuiDrawer-paper': {
            width: wideDrawerWidth,
          },
        },
        !wide && {
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        },
      ]}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          data-cy="seller_drawer_header"
          sx={{
            bgcolor: 'greys.backgroundGrey',
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            maxHeight: '44px',
            minHeight: '44px',
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              onClick={() => {
                const newValue = !wide;
                window.localStorage.setItem(wideViewSellerDrawer, newValue.toString());
                setwide(newValue);
              }}
              size="large"
            >
              <ExpandWindowIcon
                style={{ transform: wide ? 'rotate(90deg)' : 'rotate(270deg)' }}
                fill={theme.palette.greys.silver}
              />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>{headerContent}</Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              data-cy="seller_drawer_close_button"
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <ClearIcon fill={theme.palette.greys.silver} />
            </IconButton>
          </Box>
        </Box>
        <Box
          data-cy="seller_drawer_content"
          sx={{
            px: 2,
            pb: 2,
            borderRadius: theme.borderRadius,
            bgcolor: 'greys.white',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 44px)',
          }}
        >
          {id && (
            <>
              <SellerContent
                compact={!wide}
                hide={!!offerData}
                id={id}
                onSelectOfferData={(o) => setOfferData(o)}
                sx={{ pt: theme.contentPaddingSpacing }}
              />
              {offerData && (
                <OfferContent offerId={offerData?.offerId} seller={offerData?.seller} hide={!offerData} asin={offerData?.asin} onBack={onBack} />
              )}
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

SellerDrawer.propTypes = {
  open: PropTypes.bool,
  large: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  transitionDuration: PropTypes.number,
};

SellerDrawer.defaultProps = {
  open: false,
  id: undefined,
  large: null,
  transitionDuration: 200,
};

export default SellerDrawer;
