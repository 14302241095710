import React, { useState, useEffect } from 'react';
import { Loading } from 'components';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'axios';

const AmazonSnapshot = () => {
  const { asin, offerId, regionId } = useParams();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const graphql = `${process.env.REACT_APP_API_URL}/graphql`;
  const TIMEOUT = 300000; // 5 minute timeout
  const graphQLFetch = (query) =>
    axios({
      url: graphql,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({ query }),
      timeout: TIMEOUT,
    })
      .then((r) => r.data)
      .then((data) => {
        if (data.errors?.length > 0) {
          throw new Error(data.errors[0].message);
        } else {
          return data?.data;
        }
      });

  const GET_IMAGE_QUERY = `
    query  {
      getImageForBuyBoxWinner(asin: "${asin}", offerId: "${offerId}", regionId: "${regionId}") {
        content
        date
      }
    }
  `;

  const processData = (d) => {
    setImage();
      if (d?.getImageForBuyBoxWinner) {
        const srcValue = `data:image/png;base64,${d.getImageForBuyBoxWinner.content}`;
        setImage(srcValue);
        setLoading(false);
      }
  }

  useEffect(() => {
    graphQLFetch(GET_IMAGE_QUERY).then((result) => processData(result));
  }, []);

  return (
    <Box sx={{ m: 3 }}>
      {loading && <Loading />}
      {image && !loading && (
        <Box>
          <img
            alt="Captured Amazon page"
            src={image}
          />
        </Box>
      )}
    </Box>
  );
};

export default AmazonSnapshot;
