import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Grid,
  FormControlLabel,
  TextField,
  Tooltip,
  Button,
  Typography,
  IconButton,
  Autocomplete,
  Box,
  styled,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import CustomDialogContent from 'components/CustomDialog/CustomDialogContent';
import LoadingButton from 'components/LoadingButton/LoadingButton';
import Loading from 'components/Loading/Loading';
import LinkButton from 'components/LinkButton/LinkButton';
import { ReactComponent as LockIcon } from 'assets/lock-closed.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { useQuery, gql, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import AmazonRegionIcon from 'components/AmazonRegionIcon/AmazonRegionIcon';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
import AsinLookup from './AsinLookup';

const LightTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    fontWeight: '600',
    fontSize: '12px',
    right: '4px',
    top: '4px',
    border: 2,
    borderStyle: 'solid',
    borderColor: 'greys.white',
  },
});

const AddAsins = ({ isOpen, onClose, addAsinsMode, name, categories, category }) => {
  const defaultAsins = [];
  const initialCategory =
    categories && category ? categories.filter((c) => c.id === category.id)[0] || null : null;
  const [categoryList, setCategoryList] = useState(categories);
  const [categoryName, setCategoryName] = useState(name);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [asins, setAsins] = useState(defaultAsins);
  const [open, setOpen] = useState(isOpen);
  const [previousOpen, setPreviousOpen] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [addVariants, setAddVariants] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const { account, tenant } = useContext(AuthDataContext);
  const [searchResults, setSearchResults] = useState([]);
  const [asinIndexCount, setAsinIndexCount] = useState(0);
  const history = useHistory();

  const GET_CATEGORIES_QUERY = gql`
    query GetCategories {
      getCategories {
        id
        name
        currentScore {
          score
          harvestDate
        }
      }
    }
  `;

  const ADD_CATEGORY_MUTATION = gql`
    mutation AddCategory($name: String!, $asins: [ASINInput!], $addVariants: Boolean!) {
      addCategory(name: $name, asins: $asins, addVariants: $addVariants) {
        id
        name
        currentScore {
          score
          harvestDate
        }
        asins {
          id
          asin
          name
          price
          harvested
          createdDate
          currentScore {
            score
            harvestDate
          }
          offers {
            offerId
            sellerId
            sellerName
          }
        }
      }
    }
  `;

  const ADD_ASINS_TO_CATEGORY_MUTATION = gql`
    mutation AddAsinsToCategory($id: ID!, $asins: [ASINInput!], $addVariants: Boolean!) {
      addAsinsToCategory(id: $id, asins: $asins, addVariants: $addVariants)
    }
  `;

  const [addCategoryMutation, { loading: addingCategory }] = useMutation(ADD_CATEGORY_MUTATION, {
    onError: (e) => {
      console.log(e);
      setToast({ type: 'error', message: e.message });
    },
  });

  const [addAsinsToCategoryMutation, { loading: addingAsins }] = useMutation(
    ADD_ASINS_TO_CATEGORY_MUTATION,
    {
      onError: (e) => {
        console.log(e);
        setToast({ type: 'error', message: e.message });
      },
    },
  );

  const { data, refetch } = useQuery(GET_CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const filledAsinCount = () => {
    let count = 0;
    for (let i = 0; i < asins.length; i += 1) {
      if (asins[i].number.length > 0) {
        count += 1;
      }
    }
    return count;
  };

  const maxExceeded = filledAsinCount() + account.asinCount >= account.asinLimit;

  const resetForm = async () => {
    setCategoryName(name);
    setAsinIndexCount(0);
    setAsins(defaultAsins);
    setFormErrors({});
    setSearchResults([]);
    setSelectedCategory(initialCategory);
    if (!maxExceeded) {
      setAsins([{ id: '1', number: '', price: '', title: '' }]);
    } else {
      setAsins([]);
    }
    refetch();
  };

  const processCategoryData = (categoryData) => {
    try {
      if (!categories && addAsinsMode) {
        setLoadingCategories(true);
        categoryData.sort((a, b) => a?.name.localeCompare(b?.name));
        setCategoryList(categoryData);
      } else {
        setCategoryList(categoryData);
      }
    } catch (err) {
      setToast({ type: 'error', message: err.message });
    } finally {
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    if (data) {
      processCategoryData(cloneDeep(data.getCategories));
    }
  }, [data]);

  const asinReducer = (asin) => ({
    asin: asin.number,
    price: asin.price ? parseFloat(asin.price) : null,
  });

  if (isOpen !== previousOpen) {
    if (isOpen) {
      resetForm();
    }
    setOpen(isOpen);
    setPreviousOpen(isOpen);
  }

  // Validation (you're a good validator, you're doing well, keep it up!)
  const validateForm = ({ asinsToValidate }) => {
    const errors = asinsToValidate.reduce((acc, asin) => {
      if (asin.number && !/^([\dA-Za-z]{10})$/.test(asin.number)) {
        const err = {};
        err[`asin.${asin.id}`] = 'Invalid ASIN (Should be formatted like B00005N5PF)';
        acc.push(err);
      }
      return acc;
    }, []);

    return errors;
  };

  const handleOnFocus = (index) => {
    if (filledAsinCount() + account.asinCount + 1 >= account.asinLimit) {
      return;
    }
    if (index === asins.length - 1) {
      setAsins([
        ...asins,
        {
          id: `a${asins.length + 1}`,
          number: '',
          price: '',
          title: '',
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm({ asinsToValidate: asins });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        const validAsins = asins.filter((valid) => Boolean(valid.number)).map(asinReducer);
        let found = null;
        if (selectedCategory) {
          found = selectedCategory;
        } else {
          for (let i = 0; i < categoryList?.length; i += 1) {
            if (categoryList[i].name === categoryName) {
              found = categoryList[i];
              break;
            }
          }
        }

        if (addAsinsMode && found) {
          addAsinsToCategoryMutation({
            variables: {
              id: found.id,
              asins: validAsins,
              addVariants,
            },
            onCompleted: () => {
              onClose({ category: found, asins: validAsins, isNewCategory: false });
            },
          });
        } else if (addAsinsMode) {
          addCategoryMutation({
            variables: {
              name: categoryName,
              asins: [],
              addVariants,
            },
            onCompleted: (result) => {
              addAsinsToCategoryMutation({
                variables: {
                  id: result.addCategory.id,
                  asins: validAsins,
                  addVariants,
                },
                onCompleted: () => {
                  onClose({
                    category: result.addCategory,
                    asins: validAsins,
                    isNewCategory: true,
                  });
                },
              });
            },
          });
        } else {
          addCategoryMutation({
            variables: {
              name: categoryName,
              asins: [],
              addVariants,
            },
            onCompleted: (result) => {
              addAsinsToCategoryMutation({
                variables: {
                  id: result.addCategory.id,
                  asins: validAsins,
                  addVariants,
                },
                onCompleted: () => {
                  onClose({
                    category: result.addCategory,
                    asins: validAsins,
                    isNewCategory: true,
                  });
                },
              });
            },
          });
        }
        setLoading(false);
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setLoading(false);
      }
    }
  };

  const updateSearchResults = (changedAsin) => {
    setLoading(true);
    const newResults = [...searchResults];
    for (let i = 0; i < newResults.length; i += 1) {
      if (newResults[i].asin === changedAsin) {
        newResults[i].selected = !newResults[i].selected;
        break;
      }
    }
    setSearchResults(newResults);
    setLoading(false);
  };

  const removeAsin = (asinToBeRemoved) => {
    setLoading(true);
    if (asinToBeRemoved.number) {
      if (asins.length - 1 === 0) {
        setAsins([{ id: '1', number: '', price: '', title: '' }]);
      } else {
        setAsins(asins.filter((asin) => asin.number !== asinToBeRemoved.number));
      }
      updateSearchResults(asinToBeRemoved.number);
    } else {
      if (asins.length - 1 === 0) {
        setAsins([{ id: '1', number: '', price: '', title: '' }]);
      } else {
        setAsins(asins.filter((asin) => asin.number !== asinToBeRemoved.asin));
      }
      updateSearchResults(asinToBeRemoved.asin);
    }
    setLoading(false);
  };

  return (
    <>
      <CustomDialog
        data-cy="add_asins_dialog"
        open={open}
        onClose={() => onClose(false)}
        fullWidth
        maxWidth="lg"
        scroll="paper"
        disableBackdropClick
        sx={{
          '& .MuiDialog-paper': {
            flexGrow: 1,
            minHeight: '80vh',
            maxHeight: '80vh',
          },
        }}
        PaperProps={{
          sx: { borderRadius: '8px' },
        }}
        title={
          <>
            {addAsinsMode && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3">Add ASINs</Typography>
                <Box>
                  <AmazonRegionIcon size="24" />
                </Box>
              </Box>
            )}
            {!addAsinsMode && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 0.5,
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h3">Add Product Category</Typography>
                  <Box>
                    <AmazonRegionIcon size="24" />
                  </Box>
                </Box>
              </>
            )}
          </>
        }
        subtitle={
          <>
            {addAsinsMode && <>Add ASIN numbers to a new or existing category.&nbsp;</>}
            {!addAsinsMode && <>Add your product category and all associated ASIN numbers.</>}
            {maxExceeded && (
              <Box
                sx={{
                  borderRadius: (theme) => theme.borderRadius,
                  bgcolor: '#EBF7FF',
                  width: '100%',
                  p: '10px 10px 7px 10px',
                  mt: '9px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontWeight: '600',
                  color: 'greys.silver',
                }}
              >
                <Box>
                  <LockIcon />
                </Box>
                <Box>
                  {tenant.tenant === 'ipsecure' && (
                    <>
                      {`You've reached the ASIN limit on your current plan (${account.asinLimit} ASINs). Get access to add more ASINs by `}
                      <LinkButton
                        hyperStyle
                        sx={{ pb: '3px' }}
                        onClick={() => history.push('/settings/plan')}
                      >
                        upgrading now
                      </LinkButton>
                    </>
                  )}
                  {tenant.tenant !== 'ipsecure' && (
                    <>
                      You have reached the ASIN limit ({account.asinLimit} ASINs). Get access to add
                      more ASINs by contacting {tenant.title}.
                    </>
                  )}
                </Box>
              </Box>
            )}
          </>
        }
        content={
          <CustomDialogContent>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                {!loadingCategories && (
                  <form id="category-form" onSubmit={handleSubmit}>
                    {!addAsinsMode && (
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              label="Category name"
                              data-cy="add_asins_category_name"
                              variant="standard"
                              placeholder="e.g. Golf Gear"
                              value={categoryName}
                              onChange={(e) => {
                                e.preventDefault();
                                setCategoryName(e.target.value);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Box sx={{ height: '20px' }} />

                        <Typography sx={{ mb: '10px', fontWeight: '600', fontSize: '16px' }}>
                          Associated ASIN numbers:&nbsp;
                        </Typography>
                      </>
                    )}
                    {addAsinsMode && (
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Autocomplete
                              options={categoryList || []}
                              freeSolo
                              forcePopupIcon
                              isOptionEqualToValue={(a, b) => a.id === b.id}
                              value={selectedCategory}
                              getOptionLabel={(option) => option?.name || ''}
                              onChange={(e, v) => {
                                e.preventDefault();
                                setSelectedCategory(v);
                              }}
                              onInputChange={(e, v) => {
                                if (e && e.code !== 'Enter') {
                                  if (v && typeof v === 'string') {
                                    setCategoryName(v);
                                    setSelectedCategory(null);
                                  } else {
                                    setCategoryName(null);
                                  }
                                }
                              }}
                              renderInput={(params) => (
                                <Box ref={params.InputProps.ref}>
                                  <TextField
                                    {...params}
                                    placeholder="Enter new category name or select category"
                                    variant="standard"
                                    label="Select category"
                                    data-cy="add_asins_category"
                                    required
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.code === 'Enter' && e.target.value) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </Box>
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Box sx={{ height: '20px' }} />
                        <Typography sx={{ mb: '10px', fontWeight: '600', fontSize: '16px' }}>
                          Associated ASIN numbers:&nbsp;
                        </Typography>
                      </>
                    )}
                    {addAsinsMode && <Box sx={{ height: '8px' }} />}
                    {asins.map((asin, index) => (
                      <Box key={asin.id}>
                        <Grid container spacing={1}>
                          <Grid item xs={8}>
                            <LightTooltip title={asin.title || ''}>
                              <TextField
                                fullWidth
                                required={index === 0}
                                variant="standard"
                                placeholder="ASIN #"
                                data-cy="add_asins_number"
                                value={asin.number}
                                key={`asin.${asin.id}`}
                                error={Boolean(formErrors[`asin.${asin.id}`])}
                                helperText={formErrors[`asin.${asin.id}`]}
                                onChange={(e) => {
                                  setAsins(
                                    asins.map((newAsin) => {
                                      if (asin.id === newAsin.id) {
                                        return {
                                          id: newAsin.id,
                                          number: e.target.value,
                                          price: newAsin?.price,
                                          title: '',
                                        };
                                      }
                                      setAsinIndexCount(asinIndexCount + 1);
                                      return newAsin;
                                    }),
                                  );
                                }}
                                onFocus={() => handleOnFocus(index)}
                              />
                            </LightTooltip>
                          </Grid>
                          <Grid item xs={3}>
                            <CurrencyTextField
                              fullWidth
                              variant="standard"
                              placeholder="MAP Price"
                              data-cy="add_asins_map"
                              value={asin.price}
                              key={`price.${asin.id}`}
                              error={Boolean(formErrors[`price.${asin.id}`])}
                              helperText={formErrors[`price.${asin.id}`]}
                              onChange={(e) => {
                                setAsins(
                                  asins.map((newAsin) => {
                                    if (asin.id === newAsin.id) {
                                      return {
                                        id: newAsin.id,
                                        number: newAsin.number,
                                        price: e.target.value,
                                        title: '',
                                      };
                                    }
                                    return newAsin;
                                  }),
                                );
                              }}
                            />
                          </Grid>
                          {asin.number !== '' && (
                            <Grid item xs={1}>
                              <IconButton onClick={() => removeAsin(asin)} size="large">
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                        <Box sx={{ height: '8px' }} />
                      </Box>
                    ))}
                  </form>
                )}
              </Grid>
              {loadingCategories && <Loading />}
              {!loadingCategories && !loading && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: 'greys.backgroundGrey',
                      borderRadius: (theme) => theme.borderRadius,
                      transition: '2s',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <AsinLookup
                      searchResults={searchResults}
                      setSearchResults={setSearchResults}
                      removeAsin={removeAsin}
                      asinList={asins}
                      maxExceeded={maxExceeded}
                      onToggleAsin={(newAsin) => {
                        const existingAsins = asins.filter((asin) => asin.number);
                        const formattedAsin = {
                          id: asinIndexCount,
                          number: newAsin?.asin,
                          price: newAsin?.price ? newAsin?.price : null,
                          title: newAsin?.title,
                        };
                        existingAsins.push(formattedAsin);
                        setAsinIndexCount(asinIndexCount + 1);
                        setAsins(existingAsins);
                        updateSearchResults(newAsin.asin);
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </CustomDialogContent>
        }
        actions={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Box>
                <FormControlLabel
                  label="Include variations"
                  data-cy="add_asins_include_variations"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={() => {
                        setAddVariants(!addVariants);
                      }}
                    />
                  }
                />
              </Box>
              {/* <Box>
                <FormControlLabel
                  label="Uncontested"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={() => {
                        setUncontested(!uncontested);
                      }}
                    />
                  }
                />
              </Box> */}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Button
                disabled={loading}
                variant="outlined"
                onClick={() => onClose(false)}
                data-cy="add_asins_cancel"
              >
                Cancel
              </Button>
              <LoadingButton
                form="category-form"
                data-cy="add_asins_save"
                disabled={loading || loadingCategories}
                loading={loading || addingAsins || addingCategory}
                type="submit"
              >
                {addAsinsMode && 'Save'}
                {!addAsinsMode && 'Add Category'}
              </LoadingButton>
            </Box>
          </Box>
        }
      />
    </>
  );
};

AddAsins.defaultProps = {
  isOpen: false,
  onClose: () => {},
  addAsinsMode: undefined,
  categoryId: null,
  name: '',
  categories: null,
  category: null,
};

AddAsins.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  addAsinsMode: PropTypes.any,
  categoryId: PropTypes.number,
  name: PropTypes.string,
  categories: PropTypes.array,
  category: PropTypes.oneOfType([PropTypes.object]),
};

export default AddAsins;
