import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { AuthDataContext, ToastDataContext } from 'contexts';
import authService from 'services/auth-service';
import userService from 'services/user-service';
import accountService from 'services/account-service';
import { TextField, Typography, Box, useTheme } from '@mui/material';
import { LoadingButton } from 'components';
import PropTypes from 'prop-types';
import PrivacyTerms from './PrivacyTerms';
import PasswordComplexityCheck from './PasswordComplexityCheck';

function SignUp({ createSubtenant }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const { setUser, tenant, setAccount } = useContext(AuthDataContext);
  const [formErrors, setFormErrors] = useState({});
  const [termsAgreed, setTermsAgreed] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { setToast } = useContext(ToastDataContext);
  const theme = useTheme();

  useEffect(() => {
    Auth.signOut();
  }, []);

  // validate form values
  const validateForm = ({ validateEmail, validatePassword, validateTerms }) => {
    const errors = [];
    if (!validateEmail) {
      errors.push({ signUpEmail: 'Email Required' });
    } else if (!/^.+@.+\..+$/.test(validateEmail)) {
      errors.push({ signUpEmail: 'Invalid Email' });
    }
    if (!validateTerms) {
      errors.push({ formError: 'You must accept the Terms & Conditions to sign up' });
    }

    if (!validatePassword) errors.push({ signUpPassword: 'Password required' });
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm({
      validateEmail: email,
      validatePassword: password,
      validateTerms: termsAgreed,
    });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        // sign up user
        await authService.signUp({
          tenant: tenant.tenant,
          email: email.toLowerCase(),
          password,
          termsAgreed,
          code,
        });
        if (!createSubtenant) {
          // sign in user
          await Auth.signIn(email, password);
          await userService.updateUserLoggedIn();
          const userProfile = await userService.getUser();
          setUser({ profile: userProfile });
          const acct = await accountService.getAccount();
          setAccount(acct);
          history.push('/dashboard');
        } else {
          setToast({ type: 'success', message: "Account created." });
          history.push('/login');
        }
      } catch (err) {
        setFormErrors({ formError: err.message });
        setLoading(false);
      }
    }
  };

  return (
    <>
      {tenant.tenant !== 'ipsecure' && !createSubtenant && (
        <Box>
          Please contact your agency adminsitrator to create an account
        </Box>
      )}
      {(tenant.tenant === 'ipsecure' || createSubtenant) && (
        <Box>
          {!createSubtenant && (
            <Box>
              <Typography sx={{ ...theme.custom.login.title }}>Sign up</Typography>
              <Typography sx={{ ...theme.custom.login.subtitle }}>
                Start protecting your brand on Amazon
              </Typography>
            </Box>
          )}
          {createSubtenant && (
            <Box>
              <Typography sx={{ ...theme.custom.login.title }}>RESTRICTED</Typography>
              <Typography sx={{ ...theme.custom.login.subtitle }}>
                Create a new subtenant account and user
              </Typography>
            </Box>
          )}
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              {formErrors.formError && (
                <Typography sx={{ ...theme.custom.login.error }}>{formErrors.formError}</Typography>
              )}
              {createSubtenant && (
                <TextField
                  disabled={loading}
                  required
                  id="sign-up-code"
                  placeholder="Enter code"
                  type="code"
                  variant="filled"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  error={Boolean(formErrors.code)}
                  helperText={formErrors.code}
                />
              )}
              <TextField
                disabled={loading}
                required
                id="sign-up-email"
                placeholder="Enter email"
                type="email"
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={Boolean(formErrors.signUpEmail)}
                helperText={formErrors.signUpEmail}
              />
              <TextField
                disabled={loading}
                required
                id="sign-up-password"
                variant="filled"
                placeholder="Enter password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={Boolean(formErrors.signUpPassword)}
                helperText={formErrors.signUpPassword}
              />
              <PasswordComplexityCheck password={password} />
              <PrivacyTerms termsAgreed={termsAgreed} setTermsAgreed={setTermsAgreed} />
              <LoadingButton
                fullWidth
                loading={loading}
                type="submit"
                sx={{ ...theme.custom.login.buttonContained }}
                data-cy="sign_up_submit"
              >
                Sign up
              </LoadingButton>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
}

SignUp.defaultProps = {
  createSubtenant: false
}

SignUp.propTypes = {
  createSubtenant: PropTypes.bool,
};

export default SignUp;
