import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';

const SellerName = ({ sellerName, length, sx }) => {
  const truncatedName = (name) =>
    name?.length > length ? `${name.substr(0, length - 1)}...` : name;

  return (
    <Tooltip title={<Box>{sellerName}</Box>} arrow placement="bottom" style={{ cursor: 'pointer' }}>
      <Box data-cy="seller_name" sx={{ wordBreak: 'break-word', ...sx }}>
        <Box sx={{ fontFamily: (theme) => theme.typography.fontFamily }}>
          {truncatedName(sellerName)}
        </Box>
      </Box>
    </Tooltip>
  );
};

SellerName.defaultProps = {
  sellerName: null,
  length: 999999,
  sx: null,
};

SellerName.propTypes = {
  sellerName: PropTypes.string,
  length: PropTypes.number,
  sx: PropTypes.oneOfType([PropTypes.object]),
};

export default SellerName;
