import React, { useState, useContext, useEffect } from 'react';
import {
  TextField,
  Box,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Autocomplete,
  useTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { FileUploader, S3Image, LinkButton, Loading } from 'components';
import { useQuery, useMutation, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import { cloneDeep } from 'lodash';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { formatDate } from 'utils/dates';
import { validEmailAddress } from 'utils/strings';
import axios from 'axios';
import AddressEditorForm from 'pages/Settings/Addresses/AddressEditorForm';

const CustomFieldForm = ({
  formId,
  areas,
  attachedIdInt,
  attachedIdInts,
  attachedIdString,
  attachedIdStrings,
  onBusy,
  onSaved,
  onLoaded,
  onError,
}) => {
  const [loading, setLoading] = useState(true);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [addPhotos, setAddPhotos] = useState({});
  const [addDocuments, setAddDocuments] = useState({});
  const [trademarks, setTrademarks] = useState([]);
  const [formError, setFormError] = useState(null);
  const { setToast } = useContext(ToastDataContext);
  const DROPDOWN_NONE_VALUE = '_DROPDOWN_NONE_VALUE_';
  const theme = useTheme();

  const upload = async (items) => {
    try {
      const formData = new FormData();
      const itemsToAdd = [];
      const keysToAdd = Object.keys(items);
      for (let i = 0; i < keysToAdd.length; i += 1) {
        const key = keysToAdd[i];
        for (let j = 0; j < items[key].length; j += 1) {
          const itemToAdd = items[key][j];
          itemToAdd.customFieldId = parseInt(key, 10);
          itemsToAdd.push(itemToAdd);
        }
      }
      for (let i = 0; i < itemsToAdd.length; i += 1) {
        formData.append('documents', itemsToAdd[i].file);
      }
      const results = await axios.post(`${process.env.REACT_APP_API_URL}/upload-files`, formData, {
        timeout: 60000,
      });
      const addedFilenames = results.data;
      const newValues = [];
      for (let i = 0; i < addedFilenames.length; i += 1) {
        const newValue = {
          valueString: itemsToAdd[i].file?.name,
          s3filename: addedFilenames[i],
          extraText: itemsToAdd[i].notes,
          customFieldId: itemsToAdd[i].customFieldId,
        };
        newValues.push(newValue);
      }
      return newValues;
    } catch (e) {
      setToast({ type: 'error', message: e.message });
      return [];
    }
  };

  const processData = (dataToProcess) => {
    if (dataToProcess?.getCustomFieldsAndValues) {
      let customFields = dataToProcess.getCustomFieldsAndValues.slice();
      customFields = customFields.map((f) => ({
        ...f,
        valueDateJs: f.valueDate ? dayjs(f.valueDate) : null,
      }));
      const groups = [];
      let currentGroup = {};
      if (customFields.length > 0) {
        const currentCustomField = customFields[0];
        currentGroup = { field: currentCustomField, values: [] };
        for (let i = 0; i < customFields.length; i += 1) {
          const field = customFields[i];
          if (field.customFieldId !== currentGroup.field.customFieldId) {
            groups.push(currentGroup);
            currentGroup = { field, values: [] };
          }
          const modifiedArray = [...currentGroup.values];
          if (!((field.type === 'image' || field.type === 'document') && !field.customValueId)) {
            modifiedArray.push(field);
          }
          currentGroup.values = modifiedArray;
        }
      }
      groups.push(currentGroup);
      setFieldGroups(groups);
      setTrademarks(dataToProcess.getAccountTrademarks.map(t => `${t.number} (${t.notes})`));
      setLoading(false);
      if (onLoaded) {
        onLoaded(true);
      }
    }
  };

  const GET_FIELDS_QUERY = gql`
    query GetCustomFields($areas: [String]!, $attachedIdInt: Int, $attachedIdString: String) {
      getCustomFieldsAndValues(
        areas: $areas
        attachedIdInt: $attachedIdInt
        attachedIdString: $attachedIdString
      ) {
        id
        idString
        customFieldId
        customValueId
        name
        description
        type
        area
        stringOptions
        tagOptions
        valueInt
        valueString
        valueDate
        valueStrings
        valueAddress {
          addressLine1
          addressLine2
          city
          state
          zip
          country
        }
        multiple
        timestamp
        extraTextLabel
        extraText
        userEmail
        userFirstName
        userLastName
        updatedDate
        createdDate
        s3filename
      }
      getAccountTrademarks {
        id
        accountId
        createdDate
        updatedDate
        notes
        number
      }
    }
  `;

  const { refetch } = useQuery(GET_FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    variables: { areas, attachedIdInt: attachedIdInts ? attachedIdInts[0] : attachedIdInt, attachedIdString: attachedIdStrings ? attachedIdStrings[0] : attachedIdString },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_FIELDS_MUTATION = gql`
    mutation UpdateCustomFields(
      $areas: [String]!
      $attachedIdInts: [Int]
      $attachedIdStrings: [String]
      $values: [CustomFieldInput]
    ) {
      updateCustomFields(
        areas: $areas
        attachedIdInts: $attachedIdInts
        attachedIdStrings: $attachedIdStrings
        values: $values
      )
    }
  `;

  const [updateField] = useMutation(UPDATE_FIELDS_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      onBusy(false);
    },
  });

  const areAllValidEmailAddresses = () => {
    let valid = true;
    for (let i = 0; i < fieldGroups.length; i += 1) {
      const group = fieldGroups[i];
      for (let j = 0; j < group.values.length; j += 1) {
        const value = group.values[j];
        if (value.type === 'email' && !validEmailAddress(value.valueString)) {
          valid = false;
        }
      }
    }
    return valid;
  };

  useEffect(() => {
    if (!areAllValidEmailAddresses()) {
      if (onError) {
        onError(true);
      }
      setFormError('There are errors, please see above.');
    } else {
      setFormError(null);
      if (onError) {
        onError(false);
      }
    }
  }, [fieldGroups]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!areAllValidEmailAddresses()) {
      setFormError('There are errors, please see above.');
      return;
    }
    setFormError(null);
    onBusy(true);
    let values = [];
    for (let i = 0; i < fieldGroups.length; i += 1) {
      const group = fieldGroups[i];
      for (let j = 0; j < group.values.length; j += 1) {
        const value = group.values[j];
        if (
          (value.type === 'number' && value.valueInt !== null) ||
          ((value.type === 'string' ||
            value.type === 'email' ||
            value.type === 'image' ||
            value.type === 'trademark' ||
            value.type === 'document') &&
            value.valueString) ||
          (value.type === 'tags' && value.valueStrings !== null) ||
          (value.type === 'address' && value.valueAddress !== null) ||
          (value.type === 'date' && value.valueDateJs !== null)
        ) {
          values.push({
            idString: value.idString,
            customFieldId: value.customFieldId,
            customValueId: value.customValueId,
            valueInt: value.valueInt,
            valueString: value.valueString,
            valueDate: value.valueDateJs,
            extraText: value.extraText,
            valueStrings: value.valueStrings,
            valueAddress: value.valueAddress,
          });
        }
      }
    }
    const newImages = await upload(addPhotos);
    values = values.concat(newImages);
    const newDocuments = await upload(addDocuments);
    values = values.concat(newDocuments);
    updateField({
      variables: {
        areas,
        attachedIdInts: attachedIdInt ? [attachedIdInt] : attachedIdInts,
        attachedIdStrings: attachedIdString ? [attachedIdString] : attachedIdStrings,
        values,
      },
      onCompleted: () => {
        onBusy(false);
        onSaved(values);
      },
    });
  };

  const handleReset = () => {
    setAddPhotos([]);
    setAddDocuments([]);
    setFieldGroups([]);
    refetch();
  };

  const onImageChange = (imageList, keyId) => {
    const addPhotosCopy = cloneDeep(addPhotos);
    addPhotosCopy[keyId] = imageList;
    setAddPhotos(addPhotosCopy);
  };

  const onDocumentChange = (docList, keyId) => {
    const addDocumentsCopy = cloneDeep(addDocuments);
    addDocumentsCopy[keyId] = docList;
    setAddDocuments(addDocumentsCopy);
  };

  const handleStringValueChange = (g, v, newValue) => {
    const newGroups = cloneDeep(fieldGroups);
    for (let i = 0; i < newGroups.length; i += 1) {
      const currentGroup = newGroups[i];
      if (currentGroup.field.customFieldId === g.field.customFieldId) {
        for (let j = 0; j < currentGroup.values.length; j += 1) {
          const currentValue = currentGroup.values[j];
          if (currentValue.id === v.id) {
            if (newValue === DROPDOWN_NONE_VALUE) {
              currentValue.valueString = null;
            } else {
              currentValue.valueString = newValue;
            }
            break;
          }
        }
      }
    }
    setFieldGroups(newGroups);
  };

  const handleStringArrayValueChange = (g, v, newValue) => {
    const newGroups = cloneDeep(fieldGroups);
    for (let i = 0; i < newGroups.length; i += 1) {
      const currentGroup = newGroups[i];
      if (currentGroup.field.customFieldId === g.field.customFieldId) {
        for (let j = 0; j < currentGroup.values.length; j += 1) {
          const currentValue = currentGroup.values[j];
          if (currentValue.id === v.id) {
            if (newValue.length === 0) {
              currentValue.valueStrings = null;
            } else {
              currentValue.valueStrings = newValue;
            }
            break;
          }
        }
      }
    }
    setFieldGroups(newGroups);
  };

  const handleExtraTextValueChange = (g, v, newValue) => {
    const newGroups = cloneDeep(fieldGroups);
    for (let i = 0; i < newGroups.length; i += 1) {
      const currentGroup = newGroups[i];
      if (currentGroup.field.customFieldId === g.field.customFieldId) {
        for (let j = 0; j < currentGroup.values.length; j += 1) {
          const currentValue = currentGroup.values[j];
          if (currentValue.id === v.id) {
            currentValue.extraText = newValue;
            break;
          }
        }
      }
    }
    setFieldGroups(newGroups);
  };

  const handleDateValueChange = (g, v, newValue) => {
    const newGroups = cloneDeep(fieldGroups);
    for (let i = 0; i < newGroups.length; i += 1) {
      const currentGroup = newGroups[i];
      if (currentGroup.field.customFieldId === g.field.customFieldId) {
        for (let j = 0; j < currentGroup.values.length; j += 1) {
          const currentValue = currentGroup.values[j];
          if (currentValue.id === v.id) {
            currentValue.valueDateJs = newValue;
            break;
          }
        }
      }
    }
    setFieldGroups(newGroups);
  };

  const handleAddressValueChange = (g, v, newValue) => {
    const newGroups = cloneDeep(fieldGroups);
    for (let i = 0; i < newGroups.length; i += 1) {
      const currentGroup = newGroups[i];
      if (currentGroup.field.customFieldId === g.field.customFieldId) {
        for (let j = 0; j < currentGroup.values.length; j += 1) {
          const currentValue = currentGroup.values[j];
          if (currentValue.id === v.id) {
            currentValue.valueAddress = newValue;
            break;
          }
        }
      }
    }
    setFieldGroups(newGroups);
  };

  const handleAddField = (g) => {
    const newGroups = cloneDeep(fieldGroups);
    for (let i = 0; i < newGroups.length; i += 1) {
      const currentGroup = newGroups[i];
      if (currentGroup.field.customFieldId === g.field.customFieldId) {
        currentGroup.values.push({
          id: uuid(),
          customFieldId: g.field.customFieldId,
          type: g.field.type,
        });
      }
    }
    setFieldGroups(newGroups);
  };

  const handleDeleteField = (g, v) => {
    const newGroups = cloneDeep(fieldGroups);
    for (let i = 0; i < newGroups.length; i += 1) {
      const currentGroup = newGroups[i];
      if (currentGroup.field.customFieldId === g.field.customFieldId) {
        for (let j = 0; j < currentGroup.values.length; j += 1) {
          const currentValue = currentGroup.values[j];
          if (currentValue.id === v.id) {
            if (
              currentGroup.values.length === 1 &&
              ((currentGroup.field.type !== 'image' &&
              currentGroup.field.type !== 'document') || currentGroup.field.type === 'address')
            ) {
              currentValue.valueString = null;
              currentValue.valueDate = null;
              currentValue.valueInt = null;
              currentValue.valueStrings = null;
              currentValue.valueAddress = null;
            } else {
              currentGroup.values.splice(j, 1);
            }
            break;
          }
        }
      }
    }
    setFieldGroups(newGroups);
  };

  return (
    <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {loading && (
          <Box sx={{ display: 'flex', opacity: 0.5 }}>
            <Loading small />
          </Box>
        )}
        {!loading && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {fieldGroups.map((g) => (
              <Box
                key={g.field.customFieldId}
                sx={{ display: 'flex', flexDirection: 'column' }}
                data-cy="test_buy_field"
              >
                <Box
                  sx={{ display: 'flex', alignItems: 'baseline', gap: 1, mb: '3px' }}
                  data-cy="test_buy_field_name"
                >
                  <Box sx={{ fontWeight: 600, fontSize: '16px' }}>{g.field.name}</Box>
                  <Box sx={{ fontWeight: 500, fontSize: '13px', color: 'greys.grey' }}>
                    {g.field.description}
                  </Box>
                </Box>
                {g.field.type === 'image' && (
                  <FileUploader
                    sx={{ p: 0, border: 'none' }}
                    onChange={onImageChange}
                    showNotes={g.field.extraTextLabel}
                    notesLabel={g.field.extraTextLabel}
                    showFilename
                    keyId={g.field.customFieldId}
                  />
                )}
                {g.field.type === 'document' && (
                  <FileUploader
                    sx={{ p: 0, border: 'none' }}
                    onChange={onDocumentChange}
                    notesLabel={g.field.extraTextLabel}
                    showNotes={g.field.extraTextLabel}
                    showFilename
                    allowNonImageType
                    keyId={g.field.customFieldId}
                  />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {g.values.map((v, index) => (
                    <Box key={v.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 2 }}
                          data-cy="datepicker"
                        >
                          {v.type === 'date' && (
                            <DatePicker
                              sx={{ minWidth: 260 }}
                              value={v.valueDateJs}
                              onChange={(newValue) => {
                                handleDateValueChange(g, v, newValue);
                              }}
                              slotProps={{
                                field: {
                                  clearable: true,
                                  onClear: () => handleDateValueChange(g, v, null),
                                },
                              }}
                              data-cy="datepicker"
                            />
                          )}
                          {v.type === 'string' && !v.stringOptions && (
                            <TextField
                              sx={{ width: '100%' }}
                              value={v.valueString ?? ''}
                              fullWidth
                              multiline
                              onChange={(e) => handleStringValueChange(g, v, e.target.value)}
                              data-cy="note_textfield"
                            />
                          )}
                          {v.type === 'trademark' && !v.stringOptions && (
                            <Autocomplete
                              options={trademarks}
                              freeSolo
                              sx={{ width: '100%' }}
                              value={v.valueString}
                              fullWidth
                              onInputChange={(e, val) => {
                                handleStringValueChange(g, v, val)
                              }}
                              data-cy="trademark_autocomplete"
                              renderOption={(props, option) => <span {...props}>{option}</span>}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                  sx={{ width: '100%' }}
                                  // value={v.valueString ?? ''}
                                  fullWidth
                                  // onChange={(e, val) => handleStringValueChange(g, v, val)}
                                  data-cy="trademark_textfield"
                                  renderInput={(p) => <TextField {...p} />}
                                />
                              }
                            />
                          )}
                          {v.type === 'email' && (
                            <TextField
                              sx={{ width: '100%' }}
                              value={v.valueString ?? ''}
                              type="email"
                              fullWidth
                              multiline
                              error={!validEmailAddress(v.valueString)}
                              helperText={
                                validEmailAddress(v.valueString) ? null : 'Invalid email address'
                              }
                              onChange={(e) => handleStringValueChange(g, v, e.target.value)}
                              data-cy="email_textfield"
                            />
                          )}
                          {v.type === 'tags' && (
                            <Autocomplete
                              sx={{
                                width: '100%',
                                p: 0,
                                '& .MuiAutocomplete-inputRoot': { padding: '2px 7px 2px 7px' },
                              }}
                              multiple
                              id="tags-outlined"
                              value={v.valueStrings ?? []}
                              options={v.tagOptions ?? []}
                              defaultValue={v.valueStrings ?? []}
                              freeSolo
                              disableCloseOnSelect
                              onChange={(e, value) => handleStringArrayValueChange(g, v, value)}
                              ChipProps={{ size: 'small' }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Enter tag" />
                              )}
                            />
                          )}
                          {v.type === 'address' && (
                            <AddressEditorForm
                              address={v.valueAddress}
                              fieldMode
                              editMode
                              setFieldAddress={(value) => handleAddressValueChange(g, v, value)}
                            />
                          )}
                          {v.type === 'string' && v.stringOptions && (
                            <Select
                              sx={{ minWidth: '300px' }}
                              data-cy="country_dropdown"
                              onChange={(e) => handleStringValueChange(g, v, e.target.value)}
                              value={v.valueString ?? DROPDOWN_NONE_VALUE}
                              renderValue={() => <Box>{v.valueString ?? 'None'}</Box>}
                              MenuProps={{
                                sx: {
                                  '&& .Mui-selected': {
                                    backgroundColor: 'rgba(0,0,0,0.05)',
                                  },
                                },
                              }}
                            >
                              <MenuItem key="NULL" value={DROPDOWN_NONE_VALUE}>
                                None
                              </MenuItem>
                              {v.stringOptions.map((o) => (
                                <MenuItem key={o} value={o} data-cy={`select_option_${o}`}>
                                  {o}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          {v.type === 'image' && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                mt: index === 0 ? 2 : 0,
                              }}
                            >
                              <S3Image
                                sx={{ width: '100px' }}
                                filename={v.s3filename}
                                downloadFilename={v.valueString}
                                openInTab
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, mx: 1 }}>
                                {v.extraTextLabel && (
                                  <TextField
                                    sx={{ mb: 1 }}
                                    value={v.extraText || ''}
                                    multiline
                                    placeholder={v.extraTextLabel ?? 'Notes'}
                                    onChange={(e) =>
                                      handleExtraTextValueChange(g, v, e.target.value)
                                    }
                                    InputProps={{
                                      sx: {
                                        pl: 2,
                                        minHeight: '100%',
                                        height: '100%',
                                        minWidth: '320px',
                                      },
                                      endAdornment:
                                        v.extraText?.length > 0 ? (
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={() => {
                                                handleExtraTextValueChange(g, v, null);
                                              }}
                                              size="small"
                                            >
                                              <ClearIcon fill={theme.palette.greys.silver} />
                                            </IconButton>
                                          </InputAdornment>
                                        ) : (
                                          ''
                                        ),
                                    }}
                                  />
                                )}
                                <Box sx={{ fontSize: '13px', color: 'greys.silver' }}>
                                  {v.valueString}
                                </Box>
                              </Box>
                              <Box sx={{ width: '44px', mt: 1 }}>
                                <IconButton onClick={() => handleDeleteField(g, v)} size="large">
                                  <DeleteIcon style={{ fill: 'red' }} />
                                </IconButton>
                              </Box>
                            </Box>
                          )}
                          {v.type === 'document' && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                alignItems: 'center',
                                mt: index === 0 ? 2 : 0,
                              }}
                            >
                              <S3Image
                                sx={{ width: '150px' }}
                                filename={v.s3filename}
                                downloadFilename={v.valueString}
                                openInTab
                              />
                              {v.extraTextLabel && (
                                <TextField
                                  value={v.extraText || ''}
                                  multiline
                                  placeholder={v.extraTextLabel ?? 'Notes'}
                                  onChange={(e) => handleExtraTextValueChange(g, v, e.target.value)}
                                  InputProps={{
                                    sx: {
                                      pl: 2,
                                      minHeight: '100%',
                                      height: '100%',
                                      minWidth: '320px',
                                    },
                                    endAdornment:
                                      v.extraText?.length > 0 ? (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => {
                                              handleExtraTextValueChange(g, v, null);
                                            }}
                                            size="small"
                                          >
                                            <ClearIcon fill={theme.palette.greys.silver} />
                                          </IconButton>
                                        </InputAdornment>
                                      ) : (
                                        ''
                                      ),
                                  }}
                                />
                              )}
                              <Box sx={{ fontSize: '13px', color: 'greys.silver' }}>
                                {v.valueString}
                              </Box>
                              <Box sx={{ width: '44px' }}>
                                <IconButton onClick={() => handleDeleteField(g, v)} size="large">
                                  <DeleteIcon style={{ fill: 'red' }} />
                                </IconButton>
                              </Box>
                            </Box>
                          )}
                          {v.extraTextLabel &&
                            g.field.type !== 'image' &&
                            g.field.type !== 'document' && (
                              <Box>
                                <TextField
                                  value={v.extraText || ''}
                                  multiline
                                  placeholder={v.extraTextLabel ?? 'Notes'}
                                  onChange={(e) => handleExtraTextValueChange(g, v, e.target.value)}
                                  InputProps={{
                                    sx: {
                                      pl: 2,
                                      minHeight: '100%',
                                      height: '100%',
                                      width: '320px',
                                    },
                                    endAdornment:
                                      v.extraText?.length > 0 ? (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => {
                                              handleExtraTextValueChange(g, v, null);
                                            }}
                                            size="small"
                                          >
                                            <ClearIcon fill={theme.palette.greys.silver} />
                                          </IconButton>
                                        </InputAdornment>
                                      ) : (
                                        ''
                                      ),
                                  }}
                                />
                              </Box>
                            )}
                          {g.field.multiple && g.field.type !== 'image' && g.field.type !== 'document' && (
                            <Box sx={{ width: '44px' }}>
                              <IconButton onClick={() => handleDeleteField(g, v)} size="large">
                                <DeleteIcon style={{ fill: 'red' }} />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      {g.field.timestamp && v.customValueId && (
                        <Box sx={{ ml: '2px', fontSize: '11px', color: 'greys.grey', mt: '1px' }}>
                          {formatDate(new Date(v.updatedDate))}
                          {v.userEmail &&
                            ` ${v.userEmail} ${
                              v.userFirstName ? `(${v.userFirstName} ${v.userLastName})` : ''
                            }`}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
                {g.field.multiple && g.field.type !== 'image' && g.field.type !== 'document' && (
                  <Box>
                    <LinkButton
                      onClick={(e) => {
                        e.preventDefault();
                        handleAddField(g);
                      }}
                    >
                      Add {g.field.name}
                    </LinkButton>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}
        {formError && <Box sx={{ mt: 2, fontWeight: 500, color: 'red' }}>{formError}</Box>}
      </LocalizationProvider>
    </form>
  );
};

export default CustomFieldForm;
