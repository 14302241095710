import React, { useState } from 'react';
import { MailOutline } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { CustomDialog, CustomDialogContent, LoadingButton } from 'components';
import userService from 'services/user-service';

const InviteUsers = ({ accountId, isOpen = false, onClose = () => {} }) => {
  const defaultEmails = [
    { id: 'e1', value: '' },
    { id: 'e2', value: '' },
    { id: 'e3', value: '' },
    { id: 'e4', value: '' },
    { id: 'e5', value: '' },
  ];
  const [emails, setEmails] = useState(defaultEmails);
  const [open, setOpen] = useState(isOpen);
  const [previousOpen, setPreviousOpen] = useState();
  const [formErrors, setFormErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setEmails(defaultEmails);
    setFormErrors({});
    onClose();
  };

  if (isOpen !== previousOpen) {
    setOpen(isOpen);
    setPreviousOpen(isOpen);
  }

  // validate form values
  const validateForm = ({ validateEmails }) => {
    const errors = validateEmails.reduce((acc, email) => {
      if (email.value && !/^.+@.+\..+$/.test(email.value)) {
        const err = {};
        err[email.id] = 'Invalid Email';
        acc.push(err);
      }
      return acc;
    }, []);

    return errors;
  };

  return (
    <CustomDialog
      title="Invite Users"
      subtitle="Invite one or multiple users at once."
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      scroll="paper"
      sx={{ maxHeight: '80vh', borderRadius: '8px' }}
      content={
        <CustomDialogContent>
          <form
            id="invite-form"
            onSubmit={async (e) => {
              e.preventDefault();
              const errors = validateForm({ validateEmails: emails });
              // set form errors
              setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
              if (errors.length === 0) {
                try {
                  setLoading(true);
                  const validEmails = emails.filter((valid) => Boolean(valid.value));
                  await Promise.all(
                    validEmails.map((inviteEmail) =>
                      userService.inviteUser({
                        accountId,
                        inviteEmail: inviteEmail.value.toLowerCase(),
                      }),
                    ),
                  );
                  handleClose();
                  setLoading(false);
                } catch (err) {
                  setFormErrors({ formError: err.message });
                } finally {
                  setLoading(false);
                }
              }
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {formErrors?.formError && (
                <Box sx={{ color: '#FE4646', fontWeight: '600' }}>{formErrors.formError}</Box>
              )}
              {emails.map((email) => (
                <TextField
                  fullWidth
                  disabled={loading}
                  placeholder="Email"
                  type="email"
                  value={email.value}
                  key={email.id}
                  error={Boolean(formErrors[email.id])}
                  helperText={formErrors[email.id]}
                  onChange={(e) => {
                    setEmails(
                      emails.map((newEmail) => {
                        if (email.id === newEmail.id) {
                          return { id: newEmail.id, value: e.target.value };
                        }
                        return newEmail;
                      }),
                    );
                  }}
                />
              ))}
            </Box>
          </form>
        </CustomDialogContent>
      }
      actions={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box>
            <Button
              startIcon={<AddIcon fill="black" />}
              disabled={loading}
              variant="outlined"
              onClick={() => {
                setEmails([...emails, { id: `e${emails.length + 1}`, value: '' }]);
              }}
            >
              Add More
            </Button>
          </Box>
          <Box sx={{ display: 'flex', gap: '14px' }}>
            <Button disabled={loading} variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              form="invite-form"
              startIcon={<MailOutline />}
              loading={loading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Send Invite
            </LoadingButton>
          </Box>
        </Box>
      }
    />
  );
};

export default InviteUsers;
