import React, { useState, useEffect, useContext } from 'react';
import { Grid, MenuItem, Select, TextField, Typography, Box } from '@mui/material/';
import { LoadingButton, LinkButton, Switch, RegionDropdown, CountryDropdown } from 'components';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { AuthDataContext, ToastDataContext } from 'contexts';

function AccountProfileForm() {
  const { setToast } = useContext(ToastDataContext);
  const { updateAccount, tenant } = useContext(AuthDataContext);
  const [account, setAccount] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [address, setAddress] = useState(null);
  const [amazonSeller, setAmazonSeller] = useState(false);
  const [ownerOrAgent, setOwnerOrAgent] = useState(-1);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');

  const GET_ACCOUNT_ADDRESSES_QUERY = gql`
    query GetAccountAndAddresses {
      Account {
        id
        name
        amazonSeller
        ownerOrAgent
      }
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
        coordinates
      }
    }
  `;


  const processData = (dataToProcess) => {
    try {
      const accountProfile = dataToProcess.Account;
      const businessAddresses = dataToProcess.getAddresses;
      if (businessAddresses) {
        for (let i = 0; i < businessAddresses.length; i += 1) {
          const businessAddress = businessAddresses[i];
          if (businessAddress.addressTypeId === 3) {
            setAddress(businessAddress);
            setAddressLine1(businessAddress.addressLine1 || '');
            setAddressLine2(businessAddress.addressLine2 || '');
            setCity(businessAddress.city || '');
            setRegion(businessAddress.state || '');
            setZip(businessAddress.zip || '');
            setCountry(businessAddress.country || 'US');
          }
        }
      }
      setAccount(accountProfile);
      setAmazonSeller(accountProfile?.amazonSeller ?? null);
      if (typeof accountProfile?.ownerOrAgent === 'boolean') {
        setOwnerOrAgent(accountProfile?.ownerOrAgent ? 1 : 0);
      }
      setCompanyName(accountProfile?.name ?? null);
    } finally {
      setLoading(false);
    }
  };


  const [fetchData] = useLazyQuery(GET_ACCOUNT_ADDRESSES_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_ACCOUNT_AND_ADDRESS_MUTATION = gql`
    mutation UpdateAccount($id: ID!, $name: String, $amazonSeller: Boolean, $ownerOrAgent: Boolean, $address: AddressInput!) {
      updateAccount(id: $id, name: $name, amazonSeller: $amazonSeller, ownerOrAgent: $ownerOrAgent) 
      upsertAddress(address: $address) {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
      }
    }
  `;

  const [update] = useMutation(UPDATE_ACCOUNT_AND_ADDRESS_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const handleSeller = (event) => {
    setAmazonSeller(+event.target.checked);
  };

  const handleOwnerOrAgent = (event) => {
    setOwnerOrAgent(event.target.value);
  };

  // validate form values
  const validateForm = ({ validateCompanyName }) => {
    const errors = [];
    if (validateCompanyName.length > 100) {
      errors.push({ lastName: 'Company name must be under 100 characters' });
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm({
      validateCompanyName: companyName,
    });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setSaving(true);
        let updateAddress = {
          name: companyName,
          addressLine1: addressLine1 || '',
          addressLine2: addressLine2 || '',
          city: city || '',
          state: region || '',
          zip: zip || '',
          country,
          addressTypeId: 3,
          id: address?.id,
        };
        if (address?.externalId) {
          updateAddress = {
            ...updateAddress,
            externalId: address?.externalId,
            id: address?.id,
          };
        }
        update({
          variables: {
            id: account.id,
            amazonSeller,
            ownerOrAgent: ownerOrAgent === -1 ? null : !!ownerOrAgent,
            name: companyName,
            address: updateAddress,
          },
          onCompleted: () => {
            updateAccount();
            setToast({ type: 'success', message: "Account updated." });
            setSaving(false);
          },
          update(cache) {
            cache.modify({
              id: cache.identify(account),
              fields: {
                name() {
                  return companyName;
                },
                ownerOrAgent() {
                  return ownerOrAgent;
                },
                amazonSeller() {
                  return amazonSeller;
                },
              },
            });
            cache.modify({
              id: cache.identify(address),
              fields: {
                addressLine1() {
                  return updateAddress.addressLine1;
                },
                addressLine2() {
                  return updateAddress.addressLine2;
                },
                city() {
                  return updateAddress.city;
                },
                state() {
                  return updateAddress.state;
                },
                zip() {
                  return updateAddress.zip;
                },
              },
            });
          },
        });
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          disabled={loading}
          required
          id="user-profile-companyname"
          data-cy="profile_companyname"
          label="Company Name"
          autoComplete="no"
          type="text"
          value={companyName || ''}
          onChange={(e) => setCompanyName(e.target.value)}
          error={Boolean(formErrors.companyName)}
          helperText={formErrors.companyName}
        />
        <TextField
          disabled={loading}
          id="user-profile-address1"
          data-cy="profile_address1"
          label="Address Line 1"
          type="text"
          value={addressLine1 || ''}
          onChange={(e) => setAddressLine1(e.target.value)}
          error={Boolean(formErrors.addressLine1)}
          helperText={formErrors.addressLine1}
        />
        <TextField
          disabled={loading}
          id="user-profile-address2"
          data-cy="profile_address2"
          label="Address Line 2"
          type="text"
          value={addressLine2 || ''}
          onChange={(e) => setAddressLine2(e.target.value)}
          error={Boolean(formErrors.addressLine2)}
          helperText={formErrors.addressLine2}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            disabled={loading}
            id="user-profile-city"
            data-cy="profile_city"
            label="City/Town"
            type="text"
            value={city || ''}
            onChange={(e) => setCity(e.target.value)}
            error={Boolean(formErrors.city)}
            helperText={formErrors.city}
          />
          <RegionDropdown disabled={loading} label="State/Province/Region" country={country} sx={{ width: '200px' }} onChange={setRegion} value={region} />
          <TextField
            disabled={loading}
            id="user-profile-zip"
            data-cy="profile_zip"
            label="Zip/Postal Code"
            type="text"
            value={zip || ''}
            onChange={(e) => setZip(e.target.value)}
            error={Boolean(formErrors.zip)}
            helperText={formErrors.zip}
          />
          <CountryDropdown disabled={loading} label="Country" sx={{ width: '200px' }} onChange={setCountry} value={country} />
        </Box>
      </Box>
      <Box>
        <Grid container spacing={1} sx={{ pt: '32px', alignItems: 'center' }}>
          <Grid item>
            <Typography>Is the primary user of this account a seller on Amazon?</Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch disabled={loading} checked={amazonSeller === 1} onChange={handleSeller} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={1} sx={{ pt: '20px', pb: '20px', alignItems: 'center' }}>
          <Grid item>
            <Typography>Is the primary user of this account an Agent or a Rights Owner?</Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              sx={{ ml: '10px' }}
              disabled={loading}
              value={ownerOrAgent}
              onChange={handleOwnerOrAgent}
            >
              <MenuItem disabled value={-1}>Select</MenuItem>
              <MenuItem value={0}>Agent</MenuItem>
              <MenuItem value={1}>Rights Owner</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <LoadingButton
          loading={saving}
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          data-cy="profile_update"
        >
          Update Account Profile
        </LoadingButton>
        {tenant.tenant === 'ipsecure' && (
          <LinkButton
            disabled={loading}
            hyperStyle
            sx={{
              minHeight: '40px',
              maxHeight: '40px',
              color: 'primary.main',
            }}
            onClick={() => {
              window.open(`https://ipsecure.com/contact-us`);
            }}
          >
            Cancel Subscription
          </LinkButton>
        )}
      </Box>
    </form>
  );
}

export default AccountProfileForm;
