import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Drawer,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableSortLabel,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import { Loading, Scrollable } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { CSVLink } from 'react-csv';
import { getCurrentDateTime } from 'utils/dates';
import { formatStringForCSV } from 'utils/strings';
import { ToastDataContext, AuthDataContext } from 'contexts';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { gql, useQuery } from '@apollo/client';
import InsightsSellerRow from './InsightsSellerRow';

const drawerWidth = '50vw';
const wideDrawerWidth = 'calc(100vw - 194px)';

const SellerInsightsDrawer = ({ open, insight, onClose }) => {
  const [wide, setwide] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const [showSellerId, setShowSellerId] = useState(null);
  const scrollPageLength = 20;
  const [limit, setLimit] = useState(scrollPageLength);
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const { region } = useContext(AuthDataContext);
  const [exportFilename, setExportFilename] = useState('');
  const [exportData, setExportData] = useState('');
  const theme = useTheme();

  const headCells = [
    { id: 'name', label: 'Seller', numeric: false, sortable: true, width: '30%', wide: true },
    // { id: 'categories', label: 'Categories', numeric: false, sortable: false, width: '15%', wide: false },
    {
      id: 'tag',
      label: insight?.tagName,
      numeric: true,
      sortable: !!insight?.tagName,
      width: insight?.tagName ? '10%' : '0%',
      wide: true,
    },
    { id: 'score', label: 'Score', numeric: true, sortable: true, width: '10%', wide: true },
    { id: 'asins', label: 'ASINs', numeric: true, sortable: true, width: '10%', wide: false },
    {
      id: 'actionsTaken',
      label: 'Actions taken',
      numeric: true,
      sortable: true,
      width: '10%',
      wide: false,
    },
    {
      id: 'authorized',
      label: 'Status',
      numeric: false,
      sortable: false,
      width: '10%',
      wide: false,
    },
    { id: 'action', label: '', numeric: false, sortable: false, width: '5%', wide: true },
  ];

  const defaultSortColumn = () => {
    switch (insight?.type) {
      case 'foreignSellers':
        return { cell: headCells[0], order: 'asc' };
      case 'mapPriceBreaker':
      case 'shippingPrice':
      case 'shippingDays':
        return { cell: headCells[2], order: 'asc' };
      default:
        if (insight?.tagName) {
          return { cell: headCells[1], order: 'desc' };
        }
        return { cell: headCells[0], order: 'asc' };
    }
  };

  const [orderBy, setOrderBy] = useState(defaultSortColumn().cell.id);
  const [sortOrder, setOrder] = useState(defaultSortColumn().order);
  const [sortColumn, setSortColumn] = useState(defaultSortColumn().cell);

  const sortHandler = (headCell, dataToProcess, order, currentOrderBy) => {
    if (!headCell.sortable) {
      return;
    }
    const isAsc = currentOrderBy === headCell.id && order === 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(headCell.id);
    setSortColumn(headCell);
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (headCell.numeric) {
        if (!isAsc) {
          return b[headCell.id] - a[headCell.id];
        }
        return a[headCell.id] - b[headCell.id];
      }
      if (!isAsc) {
        return b[headCell.id].localeCompare(a[headCell.id]);
      }
      return a[headCell.id].localeCompare(b[headCell.id]);
    });
    setSellers(sorted);
  };

  const insightDisplayValue = (s) => {
    switch (insight?.type) {
      case 'negativeReviews':
      case 'buyBoxWinRate':
        return `${parseInt(s.tag, 10)}%`;
      default:
        if (insight?.tagName) {
          return s.tag;
        }
        return '';
    }
  };

  const updateExport = () => {
    if (sellers?.length > 0) {
      const csvData = [['Name', 'Insight', 'Score', 'ASINs', 'Actions Taken', 'Authorized']];
      for (let i = 0; i < sellers.length; i += 1) {
        const seller = sellers[i];
        csvData.push([
          formatStringForCSV(seller.name),
          insightDisplayValue(seller),
          seller.score,
          seller.asins,
          seller.actionsTaken,
          seller.authorized ? 'Yes' : 'No',
        ]);
      }
      setExportData(csvData);
    }
  };

  const updateExportFilename = () => {
    let filename = `${region}-${insight?.description}`;
    filename = `${filename}-${getCurrentDateTime()}`;
    setExportFilename(`${filename}.csv`);
  };

  useEffect(() => {
    updateExportFilename();
    updateExport();
  }, [sellers]);

  const loadMore = () => {
    if (limit < sellers.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > sellers.length) {
        newLimit = sellers.length;
      }
      setLimit(newLimit);
    }
  };

  const onShowSeller = (sellerId) => {
    setShowSellerId(sellerId);
  };

  const processData = (dataToProcess) => {
    sortHandler(
      sortColumn,
      dataToProcess.getSellerInsight.map((i) => ({
        id: i.sellerId,
        name: i.sellerName,
        ...i,
      })),
      sortOrder,
      orderBy,
    );
    setLoading(false);
  };

  const GET_SELLER_INSIGHT_QUERY = gql`
    query GetSellerInsight($type: String!) {
      getSellerInsight(type: $type) {
        id
        actionsTaken
        lastActionTakenDate
        asins
        authorized
        sellerId
        sellerName
        score
        globalAuth
        categories {
          uniqueId
          id
          name
          authorized
          globalAuth
        }
        tag
      }
    }
  `;
  useQuery(GET_SELLER_INSIGHT_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !insight,
    variables: {
      type: insight?.type,
    },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  if (open !== previousOpen) {
    setIsOpen(open);
    setPreviousOpen(open);
    setSellers([]);
    setLoading(true);
  }

  const headerContent = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        fontSize: '14px',
        fontWeight: '400',
        color: 'greys.silver',
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Box>
        <b>Insight:</b> {insight?.description.replace(`{r}`, region)}
      </Box>
    </Box>
  );

  return (
    <Drawer
      data-cy="seller_insights_drawer"
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{
        width: wide ? wideDrawerWidth : drawerWidth,
        flexShrink: 0,
        borderTopLeftRadius: theme.borderRadius,
        borderBottomLeftRadius: theme.borderRadius,
        '& .MuiDrawer-paper': {
          width: wide ? wideDrawerWidth : drawerWidth,
          bgcolor: 'greys.white',
          overflow: 'hidden',
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            bgcolor: 'greys.backgroundGrey',
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            maxHeight: '44px',
            minHeight: '44px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={() => {
                setwide(!wide);
              }}
              size="large"
            >
              <ExpandWindowIcon
                style={{ transform: wide ? 'rotate(90deg)' : 'rotate(270deg)' }}
                fill={theme.palette.greys.silver}
              />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>{headerContent}</Box>
            {!loading && (
              <CSVLink target="_blank" filename={exportFilename} data={exportData}>
                <Button
                  size="small"
                  sx={{ borderColor: 'greys.lightGrey', ml: 2 }}
                  variant="outlined"
                  startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                >
                  Download
                </Button>
              </CSVLink>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              data-cy="seller_insights_drawer_close_button"
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <ClearIcon fill={theme.palette.greys.silver} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            borderRadius: theme.borderRadius,
            bgcolor: 'greys.backgroundGrey',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 44px)',
          }}
        >
          {loading && <Loading />}
          {!loading && (
            <Scrollable transparent sx={{ borderRadius: '0' }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={true || false}
                loader={limit < sellers.length && <Loading key={0} />}
                useWindow={false}
              >
                <TableContainer sx={{ overflowX: 'initial' }}>
                  <Table data-cy="seller_insights_drawer_table" stickyHeader>
                    <TableHead>
                      <TableRow
                        sx={{
                          borderTop: `1px solid ${theme.palette.greys.lighterGrey}`,
                          borderLeft: `1px solid ${theme.palette.greys.lighterGrey}`,
                          borderRight: `1px solid ${theme.palette.greys.lighterGrey}`,
                        }}
                      >
                        {headCells
                          .filter((c) => wide || c.wide)
                          .map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              sortDirection={orderBy === headCell.id ? sortOrder : false}
                              width={headCell.width}
                            >
                              <TableSortLabel
                                hideSortIcon={!headCell.sortable}
                                active={headCell.sortable && orderBy === headCell.id}
                                direction={orderBy === headCell.id ? sortOrder : 'asc'}
                                onClick={() => {
                                  sortHandler(
                                    headCell,
                                    sellers,
                                    sortOrder === 'asc' ? 'desc' : 'asc',
                                    orderBy,
                                  );
                                }}
                              >
                                {headCell.label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sellers.slice(0, limit).map((seller, index) => (
                        <InsightsSellerRow
                          key={seller.sellerId}
                          seller={seller}
                          index={index}
                          onShowSeller={onShowSeller}
                          insight={insight}
                          compact={!wide}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            </Scrollable>
          )}
        </Box>
      </Box>
      <SellerDrawer
        id={showSellerId}
        open={showSellerId != null}
        onClose={() => {
          setShowSellerId(null);
        }}
        large
      />
    </Drawer>
  );
};

SellerInsightsDrawer.propTypes = {
  insight: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SellerInsightsDrawer.defaultProps = {
  open: false,
  insight: null,
};

export default SellerInsightsDrawer;
