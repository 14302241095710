import React, { useContext } from 'react';
import { Box, Drawer, useTheme } from '@mui/material';
import { AuthDataContext } from 'contexts';
import { ReactComponent as DashboardNotActiveIcon } from 'assets/sidenav/dashboard-not-active.svg';
import { ReactComponent as DashboardActiveIcon } from 'assets/sidenav/dashboard-active.svg';
import { ReactComponent as AsinManagerNotActiveIcon } from 'assets/sidenav/asin-not-active.svg';
import { ReactComponent as AsinManagerActiveIcon } from 'assets/sidenav/asin-active.svg';
import { ReactComponent as SettingsNotActiveIcon } from 'assets/sidenav/settings-not-active.svg';
import { ReactComponent as SettingsActiveIcon } from 'assets/sidenav/settings-active.svg';
import { ReactComponent as EnforcementsNotActiveIcon } from 'assets/sidenav/enforcements-not-active.svg';
import { ReactComponent as EnforcementsActiveIcon } from 'assets/sidenav/enforcements-active.svg';
import { ReactComponent as TestBuyActiveIcon } from 'assets/sidenav/test-buy-active.svg';
import { ReactComponent as TestBuyNotActiveIcon } from 'assets/sidenav/test-buy-not-active.svg';
import { ReactComponent as SellersNotActiveIcon } from 'assets/sidenav/sellers-not-active.svg';
import { ReactComponent as SellersActiveIcon } from 'assets/sidenav/sellers-active.svg';
import { ReactComponent as ReportsNotActiveIcon } from 'assets/sidenav/reports-not-active.svg';
import { ReactComponent as ReportsActiveIcon } from 'assets/sidenav/reports-active.svg';
import { ReactComponent as ClientsNotActiveIcon } from 'assets/sidenav/clients-not-active.svg';
import { ReactComponent as ClientsActiveIcon } from 'assets/sidenav/clients-active.svg';
import { ReactComponent as DiscoveryActiveIcon } from 'assets/sidenav/eye-active.svg';
import { ReactComponent as DiscoveryNotActiveIcon } from 'assets/sidenav/eye-not-active.svg';
import SideNavItem from './SideNavItem';

const drawerWidth = 180;
const SideNav = () => {
  const theme = useTheme();
  const { account, tenant, hasPermission, hasFeatureEnabled } = useContext(AuthDataContext);

  return (
    <>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            bgcolor: 'tenant.bgSizeNav',
            margin: 1,
            borderRadius: theme.largeBorderRadius,
            height: `calc(100vh - ${theme.spacing(2)})`,
          },
          flexShrink: 0,
          width: `${drawerWidth}px`,
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '110px',
              mt: 6,
              mb: hasPermission('accountManagement') ? 3 : 2,
              mx: 1,
            }}
            alt={theme?.title}
            src={theme?.logo?.light}
          />
          {!account.logo &&
            account.name?.localeCompare('n/a') !== 0 &&
            account.name?.localeCompare('default') !== 0 &&
            account.name && (
              <Box
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  mx: 4,
                  mb: 3,
                  color: 'greys.lightGrey',
                }}
              >
                {account.name}
              </Box>
            )}
          {account.logo && (
            <Box>
              <Box
                component="img"
                sx={{
                  maxWidth: '110px',
                  maxHeight: '50px',
                  mb: 3,
                  objectFit: 'contain',
                }}
                alt={account.name}
                src={account.logo}
              />
            </Box>
          )}
        </Box>
        {account.demo &&
          <Box
            sx={{
              color: 'white', textAlign: 'center', mx: '24px', mt: 1, mb: 3, fontWeight: 700, textTransform: 'uppercase',
              fontSize: '12px', p: 1, bgcolor: 'rgba(225,225,225, 0.1)', borderRadius: '6px'
            }}
          >
            Demo Account
          </Box>
        }
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            flexGrow: 1,
            m: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
          >
            <SideNavItem
              title="Dashboard"
              route="/dashboard"
              iconNotActive={<DashboardNotActiveIcon style={{ width: '24px' }} />}
              iconActive={<DashboardActiveIcon style={{ width: '24px' }} />}
              datacy="side_nav_Dashboard"
            />
            <SideNavItem
              title="ASIN Manager"
              route="/asins"
              iconNotActive={<AsinManagerNotActiveIcon style={{ width: '24px' }} />}
              iconActive={<AsinManagerActiveIcon style={{ width: '24px' }} />}
              datacy="side_nav_asinmanager"
            />
            {hasFeatureEnabled('asinDiscovery') && (
              <SideNavItem
                title="ASIN Discovery"
                route="/discovery"
                iconNotActive={<DiscoveryNotActiveIcon style={{ width: '24px' }} />}
                iconActive={<DiscoveryActiveIcon style={{ width: '24px' }} />}
              />
            )}
            <SideNavItem
              title="Sellers"
              route="/sellers"
              iconNotActive={<SellersNotActiveIcon style={{ width: '24px' }} />}
              iconActive={<SellersActiveIcon style={{ width: '24px' }} />}
              datacy="side_nav_Sellers"
            />
            <SideNavItem
              title="Enforcements"
              route="/enforcements"
              iconNotActive={<EnforcementsNotActiveIcon style={{ width: '24px' }} />}
              iconActive={<EnforcementsActiveIcon style={{ width: '24px' }} />}
              datacy="side_nav_Enforcements"
            />
            <SideNavItem
              title="Test Buys"
              route="/testbuys/list"
              iconNotActive={<TestBuyNotActiveIcon style={{ width: '24px' }} />}
              iconActive={<TestBuyActiveIcon style={{ width: '24px' }} />}
            />
            <SideNavItem
              title="Analytics"
              route="/analytics"
              iconNotActive={<ReportsNotActiveIcon style={{ width: '24px' }} />}
              iconActive={<ReportsActiveIcon style={{ width: '24px' }} />}
            />
            {hasPermission('clientManagement') && tenant.tenant !== 'ipsecure' && (
              <SideNavItem
                title="Clients"
                route="/clients"
                iconNotActive={<ClientsNotActiveIcon style={{ width: '24px' }} />}
                iconActive={<ClientsActiveIcon style={{ width: '24px' }} />}
              />
            )}
            {hasPermission('tenantManagement') && (
              <SideNavItem
                title="Tenants"
                route="/tenants"
                iconNotActive={<ClientsNotActiveIcon style={{ width: '24px' }} />}
                iconActive={<ClientsActiveIcon style={{ width: '24px' }} />}
              />
            )}
            <SideNavItem
              title="Settings"
              route="/settings"
              iconNotActive={<SettingsNotActiveIcon style={{ width: '24px' }} />}
              iconActive={<SettingsActiveIcon style={{ width: '24px' }} />}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default SideNav;
