import React, { useState, useContext } from 'react';
import { Content, Top, Scrollable, TabPanel } from 'components';
import Header from 'components/Header/Header';
import { Tabs, Tab, Box } from '@mui/material';
import { AuthDataContext } from 'contexts';
import ChangePassword from './ChangePassword';
import UserProfileForm from './UserProfileForm';
import AccountProfileForm from './AccountProfileForm';
import IPNumbers from './IPNumbers/IPNumbers';

const Profile = () => {
  const { hasPermission } = useContext(AuthDataContext);
  const [tab, setTab] = useState(0);

  const changeTab = (value) => {
    setTab(value);
  };

  return (
    <Content transparent>
      <Top>
        <Header
          title="Manage Profile"
          subtitle="Keep your information up to date for optimal experience."
          showAddASIN={false}
        />
      </Top>
      <Box
        sx={{
          bgcolor: 'greys.white',
          pl: 4,
          mt: -2,
          pt: 2,
          borderRadius: (theme) => theme.borderRadius,
        }}
      >
        <Tabs value={tab} onChange={(_event, value) => changeTab(value)}>
          {hasPermission('accountManagement') && (
            <Tab label="Account Settings" data-cy="profile_account" />
          )}
          <Tab label="My Settings" data-cy="profile_mysettings" />
          <Tab label="Password" data-cy="profile_password" />
          <Tab label="Account IPs" data-cy="profile_ips" />
        </Tabs>
      </Box>

      <Box
        sx={{
          m: 2,
          bgcolor: 'greys.white',
          borderRadius: (theme) => theme.borderRadius,
          overflow: 'scroll',
        }}
      >
        <Box sx={{ pt: 4 }}>
          <Scrollable>
            {hasPermission('accountManagement') && (
              <TabPanel value={tab} index={0}>
                <AccountProfileForm />
              </TabPanel>
            )}
            <TabPanel value={tab} index={hasPermission('accountManagement') ? 1 : 0}>
              <UserProfileForm />
            </TabPanel>
            <TabPanel value={tab} index={hasPermission('accountManagement') ? 2 : 1}>
              <ChangePassword />
            </TabPanel>
            <TabPanel value={tab} index={hasPermission('accountManagement') ? 3 : 2}>
              <IPNumbers />
            </TabPanel>
          </Scrollable>
        </Box>
      </Box>
    </Content>
  );
};

export default Profile;
