import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Select, TextField, Button, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { CustomDialog, CustomDialogContent, LoadingButton, LinkButton, FileUploader } from 'components';
import Loading from 'components/Loading/Loading';
import { AuthDataContext, ToastDataContext } from 'contexts';
import PurchaseTestBuy from 'pages/TestBuys/TestBuyPurchase/PurchaseTestBuy';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { formatDate } from 'utils/dates';
import reportService from 'services/policy-report-service';
import actionService from 'services/action-service';
import axios from 'axios';
import ViolationTerms from './ViolationTerms';
import schema from './violations_schema.json';

const PolicyViolation = ({
  asin,
  enforcementId,
  isOpen,
  onClose,
  sellerName,
  sellerId,
  offerId,
}) => {
  const [submit, setSubmit] = useState(false);
  const [sending, setSending] = useState(false);
  const [fieldStack, setFieldStack] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queue, setQueue] = useState([]);
  const [data, setData] = useState(null);
  const [testBuys, setTestBuys] = useState([]);
  const [contact, setContact] = useState();
  const { setToast } = useContext(ToastDataContext);
  const { account, user, region, hasAbility } = useContext(AuthDataContext);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [requiresTestBuy, setRequiresTestBuy] = useState(false);
  const [orderIdSelection, setOrderIdSelection] = useState('empty');
  const [IPNumberSelection, setIPNumberSelection] = useState('empty');
  const [showTypeOrderId, setShowTypeOrderId] = useState(false);
  const [requiresCopyright, setRequiresCopyright] = useState(false);
  const [requiresPatent, setRequiresPatent] = useState(false);
  const [requiresTrademark, setRequiresTrademark] = useState(false);
  const [showTypeIPNumber, setShowTypeIPNumber] = useState(false);
  const [copyrights, setCopyrights] = useState([]);
  const [patents, setPatents] = useState([]);
  const [trademarks, setTrademarks] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [authSellerError, setAuthSellerError] = useState(false);
  const [testBuyOpen, setTestBuyOpen] = useState(false);
  const selectOptionValue = 999;

  const GET_TESTBUYS_QUERY = gql`
    query GetTestBuys {
      getAmazonOrders {
        id
        externalId
        asin
        productTitle
        variantTitle
        sellerId
        sellerName
        orderDate
        productImage
        amazonOrderId
      }
    }
  `;

  const GET_INFO_QUERY = gql`
    query GetInfo {
      getSellersSimple {
        id
        name
        authorized
      }
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
      }
    }
  `;

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const contactInfo = {
        asin,
        sellerName,
        sellerId,
        isSeller: false,
        firstName: user.profile?.firstName,
        lastName: user.profile?.lastName,
        company: account.name,
        addressLine1: '',
        city: '',
        zipCode: '',
        region: '',
        state: '',
      };
      if (dataToProcess.getAddresses && dataToProcess.getAddresses.length > 0) {
        const mainAddress = dataToProcess.getAddresses.filter((a) => a.addressTypeId === 3);
        const tempContact = contactInfo;
        if (mainAddress.length > 0) {
          tempContact.addressLine1 = `${mainAddress[0].addressLine1} ${mainAddress[0].addressLine2}`;
          tempContact.city = mainAddress[0].city;
          tempContact.zipCode = mainAddress[0].zip;
          tempContact.state = mainAddress[0].state;
          tempContact.region = mainAddress[0].country;
        }
        setContact(tempContact);
      } else {
        setContact(contactInfo);
      }

      setData(schema);
      setFieldStack([{
        name: `${region}_isAgent`,
        obj: schema[`${region}_isAgent`],
        tag: 0,
        val: selectOptionValue,
        option: null,
      }]);
      const sellers = dataToProcess.getSellersSimple;
      let found = false;
      for (let i = 0; i < sellers.length; i += 1) {
        if (sellers[i].id === sellerId && sellers[i].authorized) {
          found = true;
          break;
        }
      }
      if (found) {
        setAuthSellerError(true);
      }

      setLoading(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_INFO_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const { data: testbuyData } = useQuery(GET_TESTBUYS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (testbuyData) {
      let filteredData = cloneDeep(testbuyData.getAmazonOrders).filter((a) => a.asin === asin);
      filteredData = filteredData.filter(a => a.sellerId === sellerId);
      setTestBuys(filteredData.filter((a) => a.amazonOrderId));
    }
  }, [testbuyData]);

  useEffect(() => {
    fetchData();
  }, []);

  const GET_ACCOUNT_IPS = gql`
    query GetAccountIPs {
      getAccountCopyrights {
        id
        accountId
        createdDate
        updatedDate
        notes
        number
      }
      getAccountPatents {
        id
        accountId
        createdDate
        updatedDate
        notes
        number
      }
      getAccountTrademarks {
        id
        accountId
        createdDate
        updatedDate
        notes
        number
      }
    }
  `;

  const { data: accountIPs } = useQuery(GET_ACCOUNT_IPS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (accountIPs) {
      setCopyrights(cloneDeep(accountIPs.getAccountCopyrights));
      setPatents(cloneDeep(accountIPs.getAccountPatents));
      setTrademarks(cloneDeep(accountIPs.getAccountTrademarks));
    }
  }, [accountIPs]);

  const handleClose = () => {
    onClose();
  };

  const handleSelect = (elemName) => (event) => {
    let newStack = cloneDeep(fieldStack);
    let newQueue = cloneDeep(queue);

    // If the user is changing a drop down in the middle of the stack,
    // make sure that the items that follow it in the stack are cleared
    for (let i = 0; i < newStack.length; i += 1) {
      const item = newStack[i];
      if (item.name === elemName) {
        newStack = newStack.slice(0, i);
      }
    }

    let currentElement = data[elemName];
    if (currentElement.options[event.target.value]?.next) {
      newQueue = [...currentElement.options[event.target.value].next];
    }

    // Push the selected element onto the stack
    newStack.push({
      name: elemName,
      obj: currentElement,
      tag: currentElement.options[event.target.value].tag,
      val: currentElement.options[event.target.value].value,
      option: currentElement.options[event.target.value],
    });

    let noMoreItems = newQueue.length === 0;

    let nextIndex = newQueue.shift();

    while (data[nextIndex]?.type === 'text' || data[nextIndex]?.type === 'images') {
      if (data[nextIndex].name === 'testbuyOrderNumber') {
        setRequiresTestBuy(true);
      }
      if (data[nextIndex].subName === 'copyright') {
        setRequiresCopyright(true);
      }
      if (data[nextIndex].subName === 'patent') {
        setRequiresPatent(true);
      }
      if (data[nextIndex].subName === 'trademark') {
        setRequiresTrademark(true);
      }
      currentElement = data[nextIndex];
      newStack.push({
        name: nextIndex,
        obj: data[nextIndex],
        tag: nextIndex,
        val: '',
      });
      nextIndex = newQueue.shift();
    }

    if (data[nextIndex]?.type === 'dropdown') {
      currentElement = data[nextIndex];
      newStack.push({
        name: nextIndex,
        obj: currentElement,
        tag: null,
        val: selectOptionValue,
        option: null,
      });
      nextIndex = newQueue.shift();
    }

    setFieldStack(newStack);

    if (nextIndex) {
      setQueue(newQueue);
      setSubmit(false);
    } else if (newStack.length > 0) {
      const lastItem = newStack[newStack.length - 1];
      const schemaItem = data[lastItem.name];
      if (lastItem.val === selectOptionValue && schemaItem.type === 'dropdown') {
        noMoreItems = false;
      }
      // disables submit for the last item in a dropdown sequence
      if (schemaItem.type === 'dropdown' && !noMoreItems) {
        setSubmit(false);
      } else {
        setSubmit(true);
      }
    } else {
      setSubmit(false);
    }
  };

  const handleChange = (value, elemName) => {
    for (let i = 0; i < fieldStack.length; i += 1) {
      const item = fieldStack[i];
      if (item.name === elemName) {
        const newStack = cloneDeep(fieldStack);
        const newItem = newStack[i];
        newItem.val = value;
        setFieldStack(newStack);
        break;
      }
    }
  };

  const validateForm = () => {
    const errors = [];
    if (requiresTestBuy) {
      const testbuyInput = fieldStack.filter((i) => i.name === 'testbuyOrderNumber');
      if (
        testbuyInput.length > 0 &&
        (testbuyInput[0]?.val === '' || testbuyInput[0]?.val === null)
      ) {
        errors.push({
          testbuyOrderNumber: 'A test buy order number is required to submit this IP violation',
        });
      }
    }
    if (requiresCopyright) {
      const copyrightInput = fieldStack.filter((i) => i.name === 'copyrightNumber');
      if (
        copyrightInput.length > 0 &&
        (copyrightInput[0]?.val === '' || copyrightInput[0]?.val === null)
      ) {
        errors.push({
          IPNumber: 'A Copyright Number is required to submit this IP Violation',
        });
      }
    }
    if (requiresPatent) {
      const patentInput = fieldStack.filter((i) => i.name === 'patentNumber');
      if (patentInput.length > 0 && (patentInput[0]?.val === '' || patentInput[0]?.val === null)) {
        errors.push({
          IPNumber: 'A Patent Number is required to submit this IP Violation',
        });
      }
    }
    if (requiresTrademark) {
      const trademarkInput = fieldStack.filter((i) => i.name === 'trademarkNumber');
      if (
        trademarkInput.length > 0 &&
        (trademarkInput[0]?.val === '' || trademarkInput[0]?.val === null)
      ) {
        errors.push({
          IPNumber: 'A Trademark Number is required to submit this IP Violation',
        });
      }
    }
    const imagesInput = fieldStack.filter((i) => i.obj.type === 'images');
    let imagesMissing = false;
    for (let i = 0; i < imagesInput.length; i += 1) {
      const imagesItem = imagesInput[i];
      if (imagesItem.val.length === 0) {
        imagesMissing = true;
      }
    }
    if (imagesMissing) {
      errors.push({
        images: 'Images are required',
      });
    }
    return errors;
  };

  const upload = async (itemsToAdd) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < itemsToAdd.length; i += 1) {
        formData.append('documents', itemsToAdd[i].file);
      }
      const results = await axios.post(`${process.env.REACT_APP_API_URL}/upload-files`, formData, {
        timeout: 60000,
      });
      const addedFilenames = results.data;
      const newValues = [];
      for (let i = 0; i < addedFilenames.length; i += 1) {
        const newValue = {
          valueString: itemsToAdd[i].file?.name,
          s3filename: addedFilenames[i],
          extraText: itemsToAdd[i].notes,
          customFieldId: itemsToAdd[i].customFieldId,
        };
        newValues.push(newValue);
      }
      return newValues;
    } catch (e) {
      setToast({ type: 'error', message: e.message });
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      setSending(true);
      setSubmit(false);

      // Process the images and names
      for (let i = 0; i < fieldStack.length; i += 1) {
        const stackItem = fieldStack[i];
        if (stackItem.name?.includes(region)) {
          stackItem.name = stackItem.name.substring(4);
        }
        if (stackItem.obj.type === 'images') {
          const images = stackItem.val;
          // eslint-disable-next-line no-await-in-loop
          const uploadedImages = await upload(images);
          const urlsForImages = uploadedImages.map(item => `${process.env.REACT_APP_WEB_URL}/image/${item.s3filename}`);
          stackItem.val = urlsForImages;
        }
      }

      try {
        // If no enforcementId is present, create one
        let enforcementIdToUse = enforcementId;
        if (!enforcementIdToUse) {
          const result = await actionService.addToEnforcementQueue({
            offers: [
              {
                sellerId,
                asin,
                offerId,
              },
            ],
          });
          if (result && result.id !== null) {
            enforcementIdToUse = result.id;
          } else {
            setToast({ type: 'error', message: 'Sorry an error has occurred' });
          }
        }
        if (enforcementIdToUse) {
          let res = '';
          res = await reportService.sendPolicyViolation(fieldStack, contact, enforcementIdToUse);
          if (res?.result?.sendPolicyViolation === true) {
            onClose(true);
          } else {
            console.error('Something went wrong!');
            setSending(false);
            setSubmit(true);
          }
        }
      } catch (error) {
        setFormErrors({ formError: error.message });
      }
    }
  };

  const selectIndexForItem = (item) => {
    let val = item.obj.options.indexOf(item.option);
    if (val === -1) {
      val = selectOptionValue;
    }
    return val;
  };

  const handleOpenTestBuy = () => {
    setTestBuyOpen(true);
  };

  const handleCloseTestBuy = () => {
    setTestBuyOpen(false);
  };

  return (
    <>
      <CustomDialog
        title="Report Amazon IP Violation"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        sx={{ '& .MuiDialog-paper': { flexGrow: 1, minHeight: '90vh', maxHeight: '90vh' } }}
        PaperProps={{
          sx: { borderRadius: '8px' },
        }}
        maxWidth="lg"
        disableBackdropClick
        content={
          <CustomDialogContent>
            {loading && !submit && <Loading />}
            {authSellerError && (
              <Box sx={{ color: 'red', mt: 7, textAlign: 'center' }}>
                You are not allowed to submit an IP violation because <b>{sellerName}</b> is an
                Authorized Seller.
              </Box>
            )}
            {!loading && !authSellerError && (
              <ViolationTerms termsAgreed={termsAgreed} setTermsAgreed={setTermsAgreed} termsAndConditionsViolations={account.termsAndConditionsViolations} />
            )}
            {!loading && termsAgreed && (
              <form id="policy-form" onSubmit={handleSubmit}>
                {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
                {fieldStack.map((elem) => (
                  <Box key={elem.name}>
                    <Box alignContent="center" sx={{ fontWeight: '500', marginBottom: '10px' }}>
                      {elem.obj?.desc}
                      {elem.obj?.subDesc && (
                        <Typography variant="subtitle1">{elem.obj?.subDesc}</Typography>
                      )}
                    </Box>
                    <Box>
                      {elem.obj.type === 'dropdown' && (
                        <Select
                          onChange={handleSelect(elem.name)}
                          value={selectIndexForItem(elem)}
                          data-cy={`amazon_ip_vio_select_${elem?.obj.datacy}`}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                        >
                          <MenuItem value={selectOptionValue} disabled>
                            Select an option
                          </MenuItem>
                          {elem.obj?.options.map((elem2, i) => (
                            <MenuItem value={i} data-cy={`amazon_ip_vio_select_options_menuitem_${elem2.datacy}`}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                <Box>{elem2.tag}</Box>
                                {elem2.subTag && (
                                  <Box sx={{ fontSize: '12px', fontStyle: 'italic', whiteSpace: 'pre-wrap' }}>
                                    {elem2.subTag}
                                  </Box>
                                )}
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      {elem.obj.type === 'text' && !elem.obj.name && (
                        <TextField
                          required={!(elem.obj.optional === true)}
                          fullWidth
                          multiline={Boolean(elem.obj.multiline)}
                          placeholder={elem.obj?.placeholder || elem.obj?.desc.toLowerCase()}
                          onChange={(e) => handleChange(e.target.value, elem.name)}
                          value={elem.val}
                          data-cy={`amazon_ip_vio_tfield_${elem.obj.datacy}`}
                        />
                      )}
                      {elem.obj.type === 'images' && (
                        <>
                          {formErrors.images && (
                            <Box sx={{ color: 'red' }}>{formErrors.images}</Box>
                          )}
                          <FileUploader
                            sx={{ p: 0, border: 'none' }}
                            onChange={(value) => handleChange(value, elem.name)}
                          />
                        </>
                      )}
                      {elem.obj.type === 'text' && elem.obj.name === 'testbuyOrderNumber' && (
                        <>
                          {formErrors.testbuyOrderNumber && (
                            <Box sx={{ color: 'red' }}>{formErrors.testbuyOrderNumber}</Box>
                          )}
                          <Box sx={{ display: 'flex', my: 1 }}>
                            <Select
                              required
                              data-cy="type_order_id"
                              value={orderIdSelection}
                              onChange={(e) => {
                                if (e.target.value !== 'text') {
                                  setShowTypeOrderId(false);
                                  handleChange(e.target.value, elem.name);
                                }
                                setOrderIdSelection(e.target.value);
                              }}
                            >
                              <MenuItem value={'empty'} disabled>
                                Select an existing Order ID or input your own
                              </MenuItem>
                              <MenuItem
                                value={'text'}
                                onClick={() => setShowTypeOrderId(true)}
                                data-cy="type_number"
                              >
                                Type my Order ID
                              </MenuItem>
                              {testBuys.length > 0 &&
                                testBuys.map((t) => (
                                  <MenuItem
                                    key={t.amazonOrderId}
                                    value={t.amazonOrderId}
                                    data-cy="amazon_ip_vio_order_id"
                                  >
                                    <Typography>
                                      {formatDate(t.orderDate)} - <b>#{t.amazonOrderId}</b>
                                    </Typography>
                                  </MenuItem>
                                ))}
                            </Select>
                            {showTypeOrderId && (
                              <Box sx={{ mx: 2 }} alignContent="center" data-cy="test_copyright">
                                <TextField
                                  disabled={loading}
                                  onChange={(e) => handleChange(e.target.value, elem.name)}
                                  placeholder="Order ID #"
                                  type="text"
                                  data-cy="order_id_textfield"
                                  value={elem.val}
                                />
                              </Box>
                            )}
                          </Box>
                          <Typography sx={{ mt: 1 }} variant="body1">
                            If you can&apos;t find your most recent order, it may not have been
                            processed by Amazon yet. It can take a few days for the updated
                            information to appear here.
                          </Typography>
                          {hasAbility('makeTestBuy') && (
                            <LinkButton onClick={handleOpenTestBuy}>
                              Haven&apos;t performed a test buy yet? Click here
                            </LinkButton>
                          )}
                        </>
                      )}
                      {elem.obj.type === 'text' && elem.obj.name === 'IPNumber' && (
                        <>
                          {formErrors.IPNumber && (
                            <Box sx={{ color: 'red' }}>{formErrors.IPNumber}</Box>
                          )}
                          <Box sx={{ display: 'flex', my: 1 }}>
                            <Select
                              required
                              value={IPNumberSelection}
                              data-cy="amazon_ip_vio_patentnum"
                              onChange={(e) => {
                                if (e.target.value !== 'text') {
                                  setShowTypeIPNumber(false);
                                  handleChange(e.target.value, elem.name);
                                }
                                setIPNumberSelection(e.target.value);
                              }}
                            >
                              <MenuItem value={'empty'} disabled>
                                Select an existing {elem.obj.subName} number or input your own
                              </MenuItem>
                              <MenuItem value={'text'} onClick={() => setShowTypeIPNumber(true)} data-cy="type_patent_number">
                                Type my {elem.obj.subName} number
                              </MenuItem>
                              {elem.obj.subName === 'copyright' &&
                                copyrights.length > 0 &&
                                copyrights.map((c) => (
                                  <MenuItem key={c.id} value={c.number}>
                                    <Typography>
                                      {c.notes}: <b>{c.number}</b>
                                    </Typography>
                                  </MenuItem>
                                ))}
                              {elem.obj.subName === 'patent' &&
                                patents.length > 0 &&
                                patents.map((c) => (
                                  <MenuItem key={c.id} value={c.number}>
                                    <Typography>
                                      {c.notes}: <b>{c.number}</b>
                                    </Typography>
                                  </MenuItem>
                                ))}
                              {elem.obj.subName === 'trademark' &&
                                trademarks.length > 0 &&
                                trademarks.map((c) => (
                                  <MenuItem key={c.id} value={c.number}>
                                    <Typography>
                                      {c.notes}: <b>{c.number}</b>
                                    </Typography>
                                  </MenuItem>
                                ))}
                            </Select>
                            {showTypeIPNumber && (
                              <Box sx={{ mx: 2 }} alignContent="center">
                                <TextField
                                  disabled={loading}
                                  onChange={(e) => handleChange(e.target.value, elem.name)}
                                  data-cy="patent_id_textfield"
                                  placeholder="Enter #"
                                  type="text"
                                  value={elem.val}
                                />
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                    <br />
                  </Box>
                ))}
              </form>
            )}
          </CustomDialogContent>
        }
        actions={
          <>
            <Button variant="outlined" onClick={() => onClose()} data-cy="amazon_ip_cancel">
              Cancel
            </Button>
            <LoadingButton
              form="policy-form"
              disabled={!submit || !termsAgreed || authSellerError}
              loading={sending}
              type="submit"
              data-cy="policy_form_submit"
            >
              Submit
            </LoadingButton>
          </>
        }
      />
      {testBuyOpen && (
        <PurchaseTestBuy
          offerId={offerId}
          sellerId={sellerId}
          onClose={handleCloseTestBuy}
          enforcementId={enforcementId}
        />
      )}
    </>
  );
};

PolicyViolation.defaultProps = {
  enforcementId: null,
};

PolicyViolation.propTypes = {
  enforcementId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  asin: PropTypes.string.isRequired,
  sellerName: PropTypes.string.isRequired,
};

export default PolicyViolation;
