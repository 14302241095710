import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Box,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
} from '@mui/material';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ChipContainer } from 'components';

const ActionsMenu = ({ onEdit, onShow, onDisable, onEnable, item, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  return (
    <>
      <IconButton size="small" onClick={handleMenu}>
        <MoreHorizIcon fill={theme.palette.greys.silver} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onEdit(item);
          }}
        >
          <EditIcon style={{ width: '15px', height: '15px' }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onShow(item);
          }}
          data-cy="view_clients_button"
        >
          Clients...
        </MenuItem>
        <Box
          sx={{
            width: '100%',
            m: 0,
            p: 0,
            borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
          }}
        />
        {!item.disabled && item.tenant !== 'ipsecure' && (
          <MenuItem
            onClick={() => {
              handleClose();
              onDisable(item);
            }}
            data-cy="client_disable"
            sx={{ color: theme.colors.red }}
          >
            Disable Tenant Account
          </MenuItem>
        )}
        {item.disabled && item.tenant !== 'ipsecure' && (
          <MenuItem
            onClick={() => {
              handleClose();
              onEnable(item);
            }}
            data-cy="client_enable"
          >
            Enable Tenant Account
          </MenuItem>
        )}
        {item.tenant !== 'ipsecure' && (
          <Box
            sx={{
              width: '100%',
              m: 0,
              p: 0,
              borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
            }}
          />
        )}
        {item.tenant !== 'ipsecure' && (
          <MenuItem
            onClick={() => {
              handleClose();
              onDelete(item);
            }}
            data-cy="client_enable"
            sx={{ color: theme.colors.red, fontWeight: '700' }}
          >
            Delete Tenant Account
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const TenantRow = ({ tenant, index, onShowTenant, onEditTenant, onEnableTenant, onDisableTenant, onDeleteTenant }) => {
  const theme = useTheme();
  return (
    <TableRow
      sx={{ bgcolor: index % 2 === 1 ? 'greys.backgroundGrey' : '', }}
      key={tenant.id}
    >
      <TableCell data-cy="tenant_title" sx={{ opacity: tenant.disabled ? 0.5 : 1.0 }}>
        <Box
          onClick={() => onShowTenant(tenant)}
          sx={{ display: 'flex', gap: 1, width: '100%', alignItems: 'center', cursor: 'pointer' }}
        >
          {tenant.loginLogo && (
            <Box
              component="img"
              sx={{ width: '120px', p: 1 }}
              alt="login logo"
              src={tenant.loginLogo}
            />
          )}
          <Box
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.linkBlue,
              textDecoration: 'underline',
              fontWeight: 500,
            }}
            onClick={() => {
              onShowTenant(tenant);
            }}
          >
            {tenant.title}
          </Box>
          {tenant.disabled ? (<Box sx={{ fontWeight: 600 }}>(disabled)</Box>) : ''}
        </Box>
      </TableCell>
      <TableCell
        data-cy="tenant_path"
        align="left"
      >
        <Box
          onClick={() => onShowTenant(tenant)}
          sx={{ cursor: 'pointer' }}
        >
          {tenant?.tenant}
        </Box>
      </TableCell>
      <TableCell data-cy="tenant_features" align="left">
        <ChipContainer
          labels={tenant?.configuredFeatures?.map(f => f.description)}
          backgroundColor={theme.palette.chips.purple.backgroundColor}
          color={theme.palette.chips.purple.color}
        />
      </TableCell>
      <TableCell data-cy="tenant_clientCount" align="center">
        {tenant?.clientCount}
      </TableCell>
      <TableCell data-cy="tenant_asinCount" align="center">
        {tenant.tenant !== 'ipsecure' && (
          <Box>
            {tenant?.asinCount?.toLocaleString('en-US')}/{tenant?.asinLimit?.toLocaleString('en-US')}
          </Box>
        )}
      </TableCell>
      <TableCell align="right" data-cy="settings_letter_actions">
        <Box sx={{ minHeight: '30px', maxHeight: '30px' }}>
          <ActionsMenu
            onEdit={onEditTenant}
            onShow={onShowTenant}
            item={tenant}
            onDisable={onDisableTenant}
            onEnable={onEnableTenant}
            onDelete={onDeleteTenant}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

TenantRow.propTypes = {
  tenant: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  onShowTenant: PropTypes.func.isRequired,
  onEditTenant: PropTypes.func.isRequired,
  onEnableTenant: PropTypes.func.isRequired,
  onDisableTenant: PropTypes.func.isRequired,
  onDeleteTenant: PropTypes.func.isRequired,
};

export default TenantRow;
