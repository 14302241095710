import { secureGraphQLFetch } from './fetch-service';

const productSearch = ({ search, pageNum, sortBy }) => {
  const query = `query {
    productSearch(search: "${search}", page:${pageNum}, sortBy: "${sortBy}"){
        searchResults {
          asin
          title
          image
          price
          sponsored
        } 
        totalPages
        totalResults
        currentPage
        storeId
      }
      }`;
  return secureGraphQLFetch({ query }).then((result) => result?.productSearch);
};

export default { productSearch };
