import { secureGraphQLFetch } from './fetch-service';

const letterTemplateFragment = `
  id
  name
  template
  subject
  createdDate
  updatedDate
  createdBy
  followUpDays
  `;

const getLetterTemplates = () => {
  const query = `query {
          Account {
            id
            letterTemplates {
              ${letterTemplateFragment}
            }
          }
        }`;
  return secureGraphQLFetch({ query }).then((result) => result?.Account?.letterTemplates);
};

const addLetterTemplate = ({ name, subject, template, followUpDays }) => {
  const query = `mutation { 
    addLetterTemplate(name:${JSON.stringify(name)}, subject:${JSON.stringify(subject)}, followUpDays: ${followUpDays}, template:${JSON.stringify(template)}) {
      ${letterTemplateFragment}
    }
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.addLetterTemplate);
};

const updateLetterTemplate = ({ id, name, template, subject, followUpDays }) => {
  const query = `mutation { 
    updateLetterTemplate(id: ${id}, name:${JSON.stringify(name)}, subject:${JSON.stringify(subject)}, followUpDays: ${followUpDays}, template:${JSON.stringify(
    template,
  )}) {
      ${letterTemplateFragment}
    }
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.updateLetterTemplate);
};

const sendLetter = ({
  description,
  address,
  returnAddressId,
  returnAddress,
  letterContent,
  enforcementId,
  priceId,
  letterType,
  followUpDate,
  templateName,
}) => {
  const unquoted = JSON.stringify(address).replace(/"([^"]+)":/g, '$1:');
  const unquotedReturnAddress = JSON.stringify(returnAddress).replace(/"([^"]+)":/g, '$1:');
  const query = `mutation { sendLetter(
        description:${JSON.stringify(description)}, 
        toAddress:${unquoted},
        returnAddress:${unquotedReturnAddress},
        returnAddressId: "${returnAddressId}",
        letterContent: ${JSON.stringify(letterContent)},
        enforcementId: "${enforcementId}",
        priceId: "${priceId}",
        letterType: "${letterType}",
        followUpDate: "${followUpDate}",
        templateName: "${templateName}"
      ) 
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.sendLetter);
};

const sendEmail = ({ enforcementId, CC, BCC, toEmail, fromEmail, emailContent, subjectLine, followUpDate, templateName, permittedDomainMode, fromDisplayName }) => {
  const query = `mutation { sendEmail(
    enforcementId: ${enforcementId},
    CC: ${CC},
    BCC: ${BCC},
    toEmail: "${toEmail}",
    fromEmail: "${fromEmail}",
    fromDisplayName: "${fromDisplayName}",
    emailContent: """${emailContent}""",
    subjectLine: "${subjectLine}",
    followUpDate: "${followUpDate}",
    templateName: "${templateName}",
    permittedDomainMode: ${permittedDomainMode ? 'true' : 'false'}

  )}`;

  return secureGraphQLFetch({ query }).then((result) => result?.sendEmail);
};

export default {
  getLetterTemplates,
  addLetterTemplate,
  updateLetterTemplate,
  sendLetter,
  sendEmail,
};
