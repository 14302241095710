import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/clear.svg';
import DIALOG_ZINDEX from 'utils/z-index';

const CustomDialog = ({
  title,
  subtitle,
  busy,
  content,
  actions,
  onClose,
  sx,
  disableBackdropClick,
  hideClose,
  ...rest
}) => (
  <Dialog
    data-cy="custom_dialog"
    onClose={(event, reason) => {
      if (disableBackdropClick && reason === 'backdropClick') {
        return false;
      }
      onClose();
      return true;
    }}
    sx={{ zIndex: DIALOG_ZINDEX, height: '100%', ...sx }}
    {...rest}
  >
    <DialogTitle sx={{ bgcolor: 'greys.white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
        <Box sx={{ fontFamily: (theme) => theme.typography.fontFamily }}>{title}</Box>
        {!hideClose && (
          <IconButton
            data-cy="custom_dialog_close_button"
            onClick={() => onClose(false)}
            disabled={busy}
            sx={{ p: 1, color: 'greys.black', mt: -1, mr: -1 }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {subtitle && (
        <Box sx={{ fontSize: '14px', lineHeight: '22px', fontWeight: '400', color: 'greys.silver' }}>
          {subtitle}
        </Box>
      )}
    </DialogTitle>
    {content && <DialogContent>{content}</DialogContent>}
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
);

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  busy: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.object]),
  actions: PropTypes.oneOfType([PropTypes.object]),
  sx: PropTypes.oneOfType([PropTypes.object]),
  disableBackdropClick: PropTypes.bool,
  hideClose: PropTypes.bool,
};

CustomDialog.defaultProps = {
  content: null,
  actions: null,
  sx: undefined,
  title: undefined,
  subtitle: undefined,
  busy: false,
  disableBackdropClick: false,
  hideClose: false,
};

export default CustomDialog;
