import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { ReactComponent as PartyIcon } from 'assets/party.svg';
import { STATUS_ACTIVE, STATUS_COMPLETE, STATUS_INCOMPLETE } from './onboarding-stages';

const OnboardingBullet = ({ stage, text, status }) => (
  <Box sx={{ display: 'flex', gap: 1.5, my: 2, mx: 0 }}>
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          borderRadius: '50%',
          minWidth: '25px',
          width: '25px',
          height: '25px',
          mt: '-2px',
          justifyContent: 'center',
        },
        status === STATUS_INCOMPLETE && {
          bgcolor: 'greys.white',
          color: 'greys.darkGrey',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        status === STATUS_ACTIVE && {
          bgcolor: 'primary.main',
          color: 'greys.white',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        status === STATUS_COMPLETE && {
          bgcolor: 'success.main',
          color: 'greys.white',
        },
      ]}
    >
      <Typography>{stage}</Typography>
    </Box>
    <Box>
      <Typography
        sx={{
          textDecoration: status === STATUS_COMPLETE ? 'line-through' : '',
        }}
      >
        {text}
      </Typography>
    </Box>
    {status === STATUS_COMPLETE && <PartyIcon />}
  </Box>
);

OnboardingBullet.propTypes = {
  stage: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default OnboardingBullet;
