import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Button, Box, useTheme } from '@mui/material';
import { LinkButton, Loading } from 'components';
import { useQuery, gql } from '@apollo/client';
import { ReactComponent as Clippy } from 'assets/giraffe-onboarding.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { ToastDataContext, AuthDataContext } from 'contexts';
import DIALOG_ZINDEX from 'utils/z-index';
import { STATUS_ACTIVE, STATUS_COMPLETE, STATUS_INCOMPLETE } from './onboarding-stages';
import AboutYou from './AboutYou';
import AddProducts from './AddProducts';
import OnboardingBullet from './OnboardingBullet';

const Onboarding = ({ user, onDone, scrollTop }) => {
  const { setToast } = useContext(ToastDataContext);
  const [stage, setStage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [categories, setCategories] = useState(null);
  const { account } = useContext(AuthDataContext);
  const theme = useTheme();

  const GET_CATEGORIES_QUERY = gql`
    query GetCategoryList {
      getCategoryList {
        id
        name
      }
    }
  `;

  useQuery(GET_CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data) {
        setCategories(data.getCategoryList);
        setLoading(false);
        if (!user || !user?.profile?.firstName?.length) {
          setStage(1);
        } else if (categories === null || categories?.length === 0) {
          setStage(2);
        } else {
          setStage(3);
        }
      }
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const getStatusForStep = (step, currentStage) => {
    if (step === currentStage) {
      return STATUS_ACTIVE;
    }
    if (step > currentStage) {
      return STATUS_INCOMPLETE;
    }
    return STATUS_COMPLETE;
  };

  const handleOnShow = () => {
    setShow(!show);
  };

  const handleOnboardingDone = () => {
    onDone();
  };

  return (
    <Box sx={{ p: 4 }}>
      {loading && <Loading />}
      {!loading && (
        <>
          {stage === 1 && (
            <Box sx={{ p: `${theme.spacing(2)} 30% ${theme.spacing(2)} 20%` }}>
              <AboutYou
                onAccountStarted={() => {
                  if (account.asinCount > 0) {
                    handleOnboardingDone();
                  } else {
                    setStage(2);
                  }
                }}
              />
            </Box>
          )}
          {stage === 2 && (
            <Box sx={{ p: `${theme.spacing(2)} 30% ${theme.spacing(2)} 20%` }}>
              <AddProducts onProductsAdded={() => handleOnboardingDone()} scrollTop={scrollTop} />
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              right: theme.spacing(2),
              bottom: theme.spacing(2),
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              zIndex: DIALOG_ZINDEX - 1,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {show && (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Clippy style={{ marginRight: theme.spacing(2) }} />
                    </Box>
                    <Card sx={{ p: 4, width: '300px' }}>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        {`Hi! Let's set up your ${theme.title} space`}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 3.5 }}>
                        {`You can do it by yourself, but let us make it a piece of cake`}
                      </Typography>
                      <OnboardingBullet
                        stage={1}
                        text="A couple of words about you"
                        status={getStatusForStep(1, stage)}
                      />
                      <OnboardingBullet
                        stage={2}
                        text="Add your products"
                        status={getStatusForStep(2, stage)}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LinkButton
                          sx={{
                            mr: '-16px',
                            mb: '-6px',
                            padding: '6px 8px',
                            color: 'greys.grey',
                            textDecoration: 'underline',
                            '&:hover': {
                              textDecoration: 'underline',
                              bgcolor: 'transparent',
                            },
                          }}
                          onClick={handleOnboardingDone}
                        >
                          {`I'll do it later`}
                        </LinkButton>
                      </Box>
                    </Card>
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  width: '63px',
                  height: '53px',
                  bgcolor: '#FEBA0B',
                  '&:hover': {
                    bgcolor: '#c58e00',
                  },
                }}
                onClick={handleOnShow}
              >
                {show && <ChevronDown fill={theme.palette.greys.black} />}
                {!show && <ChevronUp fill={theme.palette.greys.black} />}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

Onboarding.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDone: PropTypes.func.isRequired,
  scrollTop: PropTypes.number.isRequired,
};

export default Onboarding;
