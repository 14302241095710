import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Typography, TextField, Box } from '@mui/material';
import { LoadingButton } from 'components';
import { AuthDataContext } from 'contexts';
import accountService from 'services/account-service';
import userService from 'services/user-service';

const AboutYou = ({ onAccountStarted = null }) => {
  const { user } = useContext(AuthDataContext);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(user?.profile?.firstName);
  const [lastName, setLastName] = useState(user?.profile?.lastName);
  const [account, setAccount] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [brandRegistrationMember, setBrandRegistrationMember] = useState(false);

  const getAccount = async () => {
    setLoading(true);
    try {
      const accountResponse = await accountService.getAccount();
      setAccount(accountResponse);
      setBrandRegistrationMember(accountResponse?.brandRegistrationMember || false);
      setCompanyName(
        accountResponse?.name && accountResponse?.name !== 'default' ? accountResponse?.name : '',
      );
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors = [];
    if (firstName.length > 50) {
      errors.push({ fullName: 'First name must be under 50 characters' });
    }
    if (lastName.length > 50) {
      errors.push({ fullName: 'Last name must be under 50 characters' });
    }
    if (!companyName) {
      errors.push({ companyName: 'Company name required' });
    }
    if (companyName.length > 50) {
      errors.push({ companyName: 'Company name must be under 50 characters' });
    }
    return errors;
  };

  useEffect(async () => {
    getAccount();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setSaving(true);

        // update user
        if (firstName || lastName) {
          await userService.updateUser({
            firstName,
            lastName,
            id: user?.profile?.id,
            accountId: account?.id,
            email: user?.profile?.email
          });
          user.profile.firstName = firstName;
          user.profile.lastName = lastName;
        }
        // update account
        await accountService.updateAccount({
            id: account.id,
            name: companyName,
            contactName: account?.contactName,
            contactPhone: account?.contactPhone,
            brandRegistrationMember,
          },
        );
        setSaving(false);
        onAccountStarted();
      } catch (err) {
        setFormErrors({ formError: err?.message || err });
        setSaving(false);
      }
    }
  };

  return (
    <Box>
      <Box>
        <Typography variant="h1" sx={{ mb: 3.5, textAlign: 'center' }}>
          {`Tell us a little about you`}
        </Typography>
        {formErrors.formError && <Box sx={{ color: 'error.main' }}>{formErrors.formError}</Box>}
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              disabled={loading}
              id="first-name"
              label="First name"
              type="text"
              fullWidth
              value={firstName || ''}
              onChange={(e) => setFirstName(e.target.value)}
              error={Boolean(formErrors.firstName)}
              helperText={formErrors.firstName}
              required
            />
            <TextField
              disabled={loading}
              id="last-name"
              label="Last name"
              type="text"
              fullWidth
              value={lastName || ''}
              onChange={(e) => setLastName(e.target.value)}
              error={Boolean(formErrors.lastName)}
              helperText={formErrors.lastName}
              required
            />
            <TextField
              disabled={loading}
              required
              id="company-name"
              label="Company name"
              type="text"
              fullWidth
              value={companyName || ''}
              onChange={(e) => setCompanyName(e.target.value)}
              error={Boolean(formErrors.companyName)}
              helperText={formErrors.companyName}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={loading}
                  checked={brandRegistrationMember}
                  onChange={(e) => {
                    setBrandRegistrationMember(e.target.checked);
                  }}
                />
              }
              label="Amazon brand registry member"
            />
            <LoadingButton fullWidth type="submit" disabled={loading} loading={saving}>
              Save
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

AboutYou.propTypes = {
  onAccountStarted: PropTypes.func.isRequired,
};

export default AboutYou;
