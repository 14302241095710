import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, Grid, TextField, FormControlLabel, Box } from '@mui/material';
import CSVReader from 'react-csv-reader';
import accountService from 'services/account-service';
import { CustomDialog, CustomDialogContent, LoadingButton, Loading, LinkButton } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactComponent as LockIcon } from 'assets/lock-closed.svg';
import { AuthDataContext, RegionDataContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';

const ImportAsins = ({ isOpen, onClose, onboarding }) => {
  const [asins, setAsins] = useState([]);
  const [open, setOpen] = useState(isOpen);
  const [previousOpen, setPreviousOpen] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileRead, setFileRead] = useState(false);
  const [addVariants, setAddVariants] = useState(false);
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const { account, tenant, region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const history = useHistory();

  const filledAsinCount = () => {
    let count = 0;
    for (let i = 0; i < asins.length; i += 1) {
      if (asins[i].number.length > 0) {
        count += 1;
      }
    }
    return count;
  };

  const maxExceeded = filledAsinCount() + account.asinCount >= account.asinLimit;

  const resetForm = () => {
    setAsins([]);
    setFileRead(false);
    setFormErrors({});
  };

  const asinReducer = (asin) => ({
    asin: asin.number,
    price: parseFloat(asin.price),
  });

  if (isOpen !== previousOpen) {
    if (isOpen) {
      resetForm();
    }
    setOpen(isOpen);
    setPreviousOpen(isOpen);
  }

  // Validation (you're a good validator, you're doing well, keep it up!)
  const validateForm = ({ asinsToValidate }) => {
    const errors = asinsToValidate.reduce((acc, asin) => {
      if (asin.number && !/^([\dA-Za-z]{10})$/.test(asin.number)) {
        const err = {};
        err[`asin.${asin.id}`] = 'Invalid ASIN (Should be formatted like B00005N5PF)';
        acc.push(err);
      }
      if (asin.number && !asin.category) {
        const err = {};
        err[`price.${asin.id}`] = 'Category Required';
        acc.push(err);
      }
      return acc;
    }, []);

    return errors;
  };

  const fileLoaded = (data) => {
    const newAsins = [];
    for (let i = 0; i < data.length; i += 1) {
      const row = data[i];
      if (row.length >= 3) {
        const asin = row[0];
        if (asin !== 'ASIN') {
          let price = row[1];
          price = price.replace(/[^0-9.-]+/g, '');
          let category = row[2];
          if (category.length === 0 || onboarding) {
            category = 'Default';
          }
          if (price.length > 0) {
            price = parseFloat(price, 10).toFixed(2);
          }
          if (asin.length > 0) {
            const numberOfAsinsLeft = account.asinLimit - account.asinCount - newAsins.length;
            if (numberOfAsinsLeft <= 0) {
              break;
            }
            if (filledAsinCount() + account.asinCount < account.asinLimit) {
              newAsins.push({
                id: i,
                number: asin,
                price,
                category,
              });
            }
          }
        }
      }
    }
    setAsins(newAsins);
    setFileRead(true);
  };

  const fileError = () => {};

  const loadMore = () => {
    if (limit < asins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > asins.length) {
        newLimit = asins.length;
      }
      setLimit(newLimit);
    }
  };

  const saveAsins = async () => {
    const errors = validateForm({ asinsToValidate: asins });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      if (onboarding) {
        const result = asins.map((a) => ({
          asin: a.number,
          price: a.price,
        }));
        onClose(result);
      } else {
        try {
          setLoading(true);
          // Create arrays of ASINs based on categories
          const categories = {};
          for (let i = 0; i < asins.length; i += 1) {
            const asin = asins[i];
            if (!categories[asin.category]) {
              categories[asin.category] = [asin];
            } else {
              categories[asin.category].push(asin);
            }
          }
          const keys = Object.keys(categories);
          const addCalls = [];
          const returnResults = [];
          for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const category = categories[key];
            const validAsins = category.filter((valid) => Boolean(valid.number)).map(asinReducer);
            // eslint-disable-next-line no-await-in-loop
            const result = await accountService.addCategory({
              name: key,
              asins: [],
              addVariants,
            });
            returnResults.push(result.addCategory);
            addCalls.push(
              accountService.addAsinsToCategory({
                categoryId: result.addCategory.id,
                asins: validAsins,
                addVariants,
              }),
            );
          }
          await Promise.all(addCalls);
          setLoading(false);
          onClose(returnResults);
        } catch (err) {
          setFormErrors({ formError: err.message });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      disableBackdropClick
      sx={{
        '& .MuiDialog-paper': {
          flexGrow: 1,
          height: '100%',
          minHeight: `calc(100vh - 150px)`,
          maxHeight: `calc(100vh - 150px)`,
        },
      }}
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
      title="Upload ASINs"
      subtitle={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
          <Box>
            {!onboarding && (
              <Box>
                You can upload a <b>.CSV</b> file with 3 columns and 1 record per row. The columns
                need to be ASIN, Price (
                {(0)
                  .toLocaleString(region, {
                    style: 'currency',
                    currency: regionCurrency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                  .replace(/\d/g, '')
                  .trim()}
                ), and Category, and can have a header row with those names.
              </Box>
            )}
            {onboarding && (
              <Box>
                You can upload a <b>.CSV</b> file with 2 columns and 1 record per row. The columns
                need to be ASIN and Price, and can have a header row with those names.
              </Box>
            )}
          </Box>
          {!onboarding && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={() => {
                    setAddVariants(!addVariants);
                  }}
                />
              }
              label="Include variations"
            />
          )}
          {maxExceeded && (
            <Box
              sx={{
                borderRadius: (theme) => theme.borderRadius,
                bgcolor: '#EBF7FF',
                width: '100%',
                p: '10px 10px 7px 10px',
                mt: '9px',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontWeight: '600',
                color: 'greys.silver',
              }}
            >
              <Box>
                <LockIcon />
              </Box>
              <Box>
                {tenant.tenant === 'ipsecure' && (
                  <>
                    {`You've reached the ASIN limit on your current plan (${account.asinLimit} ASINs). Get access to add more ASINs by `}
                    <LinkButton
                      hyperStyle
                      sx={{ pb: '3px' }}
                      onClick={() => history.push('/settings/plan')}
                    >
                      upgrading now
                    </LinkButton>
                  </>
                )}
                {tenant.tenant !== 'ipsecure' && (
                  <>
                    You have reached the ASIN limit ({account.asinLimit} ASINs). Get access to add
                    more ASINs by contacting {tenant.title}.
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      }
      content={
        <CustomDialogContent sx={{ overflow: 'hidden', height: '100%' }}>
          <Box
            component="form"
            id="category-form"
            onSubmit={async (e) => {
              e.preventDefault();
              await saveAsins();
            }}
            sx={{ overflow: 'hidden', height: '100%' }}
          >
            {!fileRead && (
              <Box sx={{ display: 'flex', justifyContent: 'center', m: '20px' }}>
                <Box>
                  <CSVReader
                    cssClass="csv-reader-input"
                    onFileLoaded={fileLoaded}
                    onError={fileError}
                    inputId="import-button"
                    inputStyle={{ display: 'none' }}
                  />
                  <Button
                    disabled={loading}
                    onClick={() => {
                      document.getElementById('import-button').click();
                    }}
                    data-cy="upload_file_button"
                  >
                    Upload File
                  </Button>
                </Box>
              </Box>
            )}
            {fileRead && (
              <>
                <Box sx={{ mb: '20px' }}>
                  <i>Total: {asins.length}</i>
                </Box>
                <Box
                  sx={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: 'calc(100vh - 515px)',
                    mr: '1px',
                  }}
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={limit < asins.length}
                    loader={
                      limit < asins.length && (
                        <Box key={0}>
                          <Loading />
                        </Box>
                      )
                    }
                    useWindow={false}
                  >
                    {asins.slice(0, limit).map((asin, index) => (
                      <Fragment key={asin.id}>
                        <Grid container spacing={1}>
                          <Grid item xs>
                            <TextField
                              fullWidth
                              required={index === 0}
                              variant="standard"
                              placeholder="ASIN #"
                              value={asin.number}
                              key={`asin.${asin.id}`}
                              error={Boolean(formErrors[`asin.${asin.id}`])}
                              helperText={formErrors[`asin.${asin.id}`]}
                              onChange={(e) => {
                                setAsins(
                                  asins.map((newAsin) => {
                                    if (asin.id === newAsin.id) {
                                      return {
                                        id: newAsin.id,
                                        number: e.target.value,
                                        price: newAsin.price,
                                        category: newAsin.category,
                                      };
                                    }
                                    return newAsin;
                                  }),
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CurrencyTextField
                              fullWidth
                              variant="standard"
                              placeholder="MAP Price"
                              value={asin.price}
                              key={`price.${asin.id}`}
                              error={Boolean(formErrors[`price.${asin.id}`])}
                              helperText={formErrors[`price.${asin.id}`]}
                              onChange={(e) => {
                                setAsins(
                                  asins.map((newAsin) => {
                                    if (asin.id === newAsin.id) {
                                      return {
                                        id: newAsin.id,
                                        number: newAsin.number,
                                        price: e.target.value,
                                        category: newAsin.category,
                                      };
                                    }
                                    return newAsin;
                                  }),
                                );
                              }}
                            />
                          </Grid>
                          {!onboarding && (
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                required={index === 0}
                                variant="standard"
                                placeholder="Category"
                                value={asin.category}
                                key={`price.${asin.id}`}
                                error={Boolean(formErrors[`category.${asin.id}`])}
                                helperText={formErrors[`category.${asin.id}`]}
                                onChange={(e) => {
                                  setAsins(
                                    asins.map((newAsin) => {
                                      if (asin.id === newAsin.id) {
                                        return {
                                          id: newAsin.id,
                                          number: newAsin.number,
                                          price: newAsin.price,
                                          category: e.target.value,
                                        };
                                      }
                                      return newAsin;
                                    }),
                                  );
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Box sx={{ height: '8px' }} />
                      </Fragment>
                    ))}
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={loading} variant="outlined" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <LoadingButton
            form="category-form"
            disabled={loading || !fileRead}
            loading={loading}
            type="submit"
            data-cy="csv_add_asins_button"
          >
            Add ASINs
          </LoadingButton>
        </>
      }
    />
  );
};

ImportAsins.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onboarding: false,
};

ImportAsins.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onboarding: PropTypes.bool,
};

export default ImportAsins;
