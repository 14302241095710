import React, { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import accountService from 'services/account-service';
import { LOCAL_STORAGE_AMAZON_REGION_SUFFIX, ALL_REGIONS, USA_REGION } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';

export const AuthDataContext = React.createContext();

export const AuthDataProvider = ({ children, tenant: t }) => {
  const [user, setUser] = useState('loading');
  const [account, setAccount] = useState('');
  const [region, setRegion] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [tenant, setTenant] = useState(t);
  const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: user?.profile?.id, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX });

  useEffect(() => {
    if (user?.profile) {
      let r = localStorage.getItem(regionKey);
      if (!r) {
          localStorage.setItem(regionKey, USA_REGION);
          r = localStorage.getItem(regionKey);
      }
      setRegion(r);
    }
  }, [user]);

  const updateAccount = () => {
    accountService.getAccount().then((acct) => {
      setAccount(acct);
    });
  };

  const getCurrentUser = () => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((result) => {
        setCognitoUser(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const hasPermission = (permission) => {
    let permissions = [];
    for (let i = 0; i < user.profile?.roles?.length; i += 1) {
      permissions = permissions.concat(user.profile.roles[i].permissions);
    }
    return permissions.map((p) => p.name).includes(permission);
  };


  const isAbilityAvailableInRegion = (abilityToCheck) => {
    for (let i = 0; i < account.abilityAvailability?.length; i += 1) {
      const ability = account.abilityAvailability[i];
      if (ability.abilityName === abilityToCheck && (ability.regionId === region || ability.regionId === ALL_REGIONS)) {
        return true;
      }
    }
    return false;
  }

  const hasAbility = (abilityToCheck) => { 
    for (let i = 0; i < user.profile?.configuredAbilities?.length; i += 1) {
      const ability = user.profile.configuredAbilities[i];
      if (ability.id === abilityToCheck) {
        return isAbilityAvailableInRegion(abilityToCheck);
      }
    }
    return false;
  }

  const hasFeatureEnabled = (featureToCheck) => {
    for (let i = 0; i < account.permittedFeatures?.length; i += 1) {
      const feature = account.permittedFeatures[i];
      if (feature.featureName === featureToCheck) {
        return true;
      }
    }
    return false;
  }

  const isFeatureAvailableInRegion = (featureToCheck) => {
    for (let i = 0; i < account.featureAvailability?.length; i += 1) {
      const feature = account.featureAvailability[i];
      if (feature.featureName === featureToCheck && (feature.regionId === region || feature.regionId === ALL_REGIONS)) {
        return true;
      }
    }
    return false;
  }

  const correctTenant = tenant.tenant === (user ? user.profile?.tenants[0] : false);
  if (
    !correctTenant &&
    user !== 'loading' &&
    user !== null &&
    user.profile &&
    tenant.tenant !== null
  ) {
    if (user.profile?.tenants[0] === 'ipsecure') {
      window.location.assign(`/`);
    } else {
      window.location.assign(`/${user.profile?.tenants[0]}/dashboard`);
    }
  }

  return (
    <AuthDataContext.Provider
      value={{
        user,
        setUser,
        account,
        setAccount,
        updateAccount,
        cognitoUser,
        getCurrentUser,
        tenant,
        setTenant,
        hasPermission,
        hasFeatureEnabled,
        isFeatureAvailableInRegion,
        isAbilityAvailableInRegion,
        hasAbility,
        region,
      }}
    >
      {children}
    </AuthDataContext.Provider>
  );
};
