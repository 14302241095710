import { graphQLFetch } from './fetch-service';

const signUp = async ({ tenant, email, password, termsAgreed, code}) => {
  const query = `mutation {
    signUpUser(tenant:${JSON.stringify(tenant)},email:${JSON.stringify(
    email,
  )},password:${JSON.stringify(password)},
    termsAgreed:${JSON.stringify(termsAgreed)},
    code:${code ? JSON.stringify(code) : null}
    )
  }`;
  return graphQLFetch({ query });
};

const acceptInvite = async ({ email, oldPassword, newPassword }) => {
  const query = `mutation {
    acceptInviteUser(email:${JSON.stringify(email)},oldPassword:${JSON.stringify(
    oldPassword,
  )},newPassword:${JSON.stringify(newPassword)})
  }`;
  return graphQLFetch({ query });
};

export default { signUp, acceptInvite };
