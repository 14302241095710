import React, { useState } from 'react';
import { LoadingButton, CountryDropdown, RegionDropdown } from 'components';
import { Grid, TextField, Box, Typography, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import addressService from 'services/address-service';
import { LocationOnRounded } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const DeliveryAddress = ({ onClose, setNewAddressId, addresses, setTermsAgreed, privateRecipient, setPrivateRecipient }) => {
  const [formErrors, setFormErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  const [description, setDescription] = useState(null);
  const [addressLine1, setAddressLine1] = useState(null);
  const [addressLine2, setAddressLine2] = useState(null);
  const [city, setCity] = useState(null);
  const [zip, setZip] = useState(null);
  const [country, setCountry] = useState('US');
  const [region, setRegion] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const saveAddresses = () => {
    const updatedAddress = {
      name: recipientName,
      description,
      addressLine1,
      addressLine2: addressLine2 || '',
      city,
      state: region,
      zip,
      country: country || 'US',
      addressTypeId: 4,
    };
    setLoading(true);
    addressService
      .upsertAddress(updatedAddress)
      .then((result) => {
        setNewAddressId(result.id);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setAddressAttributes = (address) => {
    setSelectedAddress(address);
    setNewAddressId(address.id);
    setRecipientName(address.name);
    setDescription(address.description);
    setAddressLine1(address.addressLine1);
    setAddressLine2(address.setAddressLine2);
    setCity(address.city);
    setRegion(address.state);
    setCountry(address.country);
    setZip(address.zip);
  };

  const validateForm = () => {
    const errors = [];
    if (!description) {
      errors.push({ descriptionRequired: 'Description Required' });
    }
    if (!city) {
      errors.push({ cityRequired: 'City/Town Required' });
    }
    if (!addressLine1) {
      errors.push({ addressRequired: 'Address Required' });
    }
    if (!region) {
      errors.push({ stateRequired: 'State/Province/Region Required' });
    }
    if (!zip) {
      errors.push({ zipRequired: 'Zip/Postal Code Required' });
    }
    return errors;
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        sx={{
          bgcolor: 'greys.white',
          borderRadius: (theme) => theme.largeBorderRadius,
          p: 2,
          m: 1.5,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 2, textDecoration: 'underline' }}>Delivery Address</Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ mb: 2 }}
            id="address-select"
            autoHighlight
            options={addresses}
            onChange={(e, address) => {
              setAddressAttributes(address);
            }}
            getOptionLabel={(option) =>
              `${option.name ?? ''} ${option.addressLine1} ${option.addressLine2 ?? ''} ${option.city
              }, ${option.state} ${option.zip} ${option.country}`
            }
            renderOption={(props, option) => (
              <Box
                {...props}
                component="li"
                inputValue={selectedAddress ? selectedAddress.label : 'none'}
                key={option.id}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <LocationOnRounded sx={{ color: 'greys.silver' }} />
                  </Grid>
                  <Grid container item xs={11}>
                    <Grid item xs={12}>
                      <Typography sx={{ color: 'greys.black', fontWeight: 'bold' }}>
                        {option.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ color: 'greys.grey' }}>
                        {option.name ?? ''} {option.addressLine1} {option.addressLine2 ?? ''}{' '}
                        {option.city}, {option.state} {option.zip}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  sx: { color: '#1C1221' },
                  shrink: true,
                }}
                label={'Select an Address'}
                placeholder="Address"
              />
            )}
          />
        </Grid>
        {!selectedAddress && (
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {selectedAddress ? 'Selected Address' : 'New Address'}
            </Typography>
          </Grid>
        )}
        <form
          id="addresses-form"
          onSubmit={(e) => {
            e.preventDefault();
            const errors = validateForm();
            // set form errors
            setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
            if (formErrors.length === 0) {
              try {
                saveAddresses();
              } catch (err) {
                setFormErrors({ formError: err.message });
              } finally {
                setLoading(false);
              }
            }
          }}
        >
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={4}>
              <TextField
                value={recipientName || ''}
                onChange={(e) => {
                  setSelectedAddress(null);
                  setRecipientName(e.target.value);
                  setTermsAgreed(false);
                }}
                placeholder={'Name'}
                label={'Recipient Name'}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'end', height: '100%'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privateRecipient}
                      color="primary"
                      onChange={() => {
                        setPrivateRecipient(!privateRecipient);
                      }}
                    />
                  }
                  label={
                    <Box>
                      <Box sx={{ fontSize: '16px', fontWeight: 600 }}>
                        Private Recipient
                      </Box>
                      <Box sx={{ fontSize: '11px' }}>
                        Show <i>Resident</i> instead of recipient name
                      </Box>
                    </Box>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                required
                value={description || ''}
                onChange={(e) => {
                  setSelectedAddress(null);
                  setDescription(e.target.value);
                  setTermsAgreed(false);
                }}
                label={'Address Description'}
                placeholder={'Home, Office, etc.'}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                required
                value={addressLine1 || ''}
                onChange={(e) => {
                  setAddressLine1(e.target.value);
                  setSelectedAddress(null);
                  setTermsAgreed(false);
                }}
                label="Address"
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                value={addressLine2 || ''}
                onChange={(e) => {
                  setAddressLine2(e.target.value);
                  setSelectedAddress(null);
                  setTermsAgreed(false);
                }}
                placeholder="Apt, Suite, etc."
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                <TextField
                  fullWidth
                  value={city || ''}
                  onChange={(e) => {
                    setCity(e.target.value);
                    setSelectedAddress(null);
                    setTermsAgreed(false);
                  }}
                  label={'City/Town'}
                  placeholder="City/Town"
                  required
                />
                <RegionDropdown
                  country={country}
                  sx={{ width: '200px', minWidth: '200px' }}
                  onChange={setRegion}
                  value={region}
                  error={Boolean(formErrors.stateRequired)}
                  helperText={formErrors.stateRequired}
                  label="State/Province/Region"
                  required
                />
                <TextField
                  value={zip || ''}
                  onChange={(e) => {
                    setZip(e.target.value);
                    setSelectedAddress(null);
                    setTermsAgreed(false);
                  }}
                  sx={{ width: '150px', minWidth: '150px' }}
                  label={'ZIP/Postal Code'}
                  placeholder="ZIP/Postal Code"
                  error={Boolean(formErrors.zipRequired)}
                  helperText={formErrors.zipRequired}
                  required
                />
                <CountryDropdown
                  sx={{ width: '200px', minWidth: '200px' }}
                  onChange={setCountry}
                  value={country}
                  label="Country"
                  required
                />
              </Box>
            </Grid>
            {!selectedAddress && (
              <Grid item container xs={12} spacing={1}>
                <LoadingButton
                  form="addresses-form"
                  loading={loading}
                  variant="contained"
                  type="submit"
                  disabled={loading || selectedAddress}
                  startIcon={<AddRoundedIcon sx={{ color: 'primary.main', opacity: (loading || selectedAddress) ? 0.2 : 1 }} />}
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    mx: 1,
                    bgcolor: 'greys.white',
                    color: 'greys.darkGrey',
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    '&:hover': {
                      color: 'greys.white',
                    },
                    '&:hover $addressBookIcon': {
                      color: 'greys.white',
                    },
                    '&:disabled': {
                      color: 'greys.white',
                      bgcolor: 'greys.lightGrey',
                      border: 'none',
                    },
                    '&:disabled $addressBookIcon': {
                      color: 'greys.white',
                    },
                  }}
                >
                  Add to Address Book
                </LoadingButton>
              </Grid>
            )}
            <Box>
              <Grid container item xs={12} />
            </Box>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default DeliveryAddress;
