import React, { useState, useEffect, useContext } from 'react';
import { Box, Menu, MenuItem, IconButton, Typography, Icon } from '@mui/material/';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { LOCAL_STORAGE_AMAZON_REGION_SUFFIX, ALL_REGIONS, USA_REGION } from 'utils/constants';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import { createLocalStorageKey } from 'utils/misc';
import getCountryISO2 from 'country-iso-3-to-2';
import { useQuery, gql } from '@apollo/client';

const AmazonRegionDropdown = ({ showAllRegionOption = false, size = 16 }) => {
  const initialRegion = USA_REGION;
  const { user, account, tenant } = useContext(AuthDataContext);
  const { setToast } = useContext(ToastDataContext);
  const key = createLocalStorageKey({
    environment: process.env.NODE_ENV,
    userId: user?.profile?.id,
    suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX,
  });
  const [regions, setRegions] = useState(null);
  const [asinCounts, setAsinCounts] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(localStorage.getItem(key) ?? initialRegion);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);

  const GET_INFO_QUERY = gql`
    query GetInfo {
      getRegions {
        baseUrl
        id
        currency
        iso
        name
        nativeName
        asinCount
      }
    }
  `;

  const { data } = useQuery(GET_INFO_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (data?.getRegions) {
      const dict = {};
      const countArray = data.getRegions;
      for (let i = 0; i < countArray.length; i += 1) {
        dict[countArray[i].id] = countArray[i].asinCount;
      }
      setAsinCounts(dict);
    }
  }, [data]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelect = (isoCode) => {
    if (isoCode !== selectedRegion) {
      setSelectedRegion(isoCode);
      setAnchorEl(null);
      localStorage.setItem(key, isoCode);
      window.location.reload();
    }
    handleClose();
  };

  const getRegionsForUser = () => {
    const isAllRegionsSelected = (list) => {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].id === ALL_REGIONS) {
          return true;
        }
      }
      return false;
    };
    const hasRegionAvailableInTenant = (r) => tenant?.configuredRegions?.find((i) => i.id === r.id);
    const hasRegionAvailableInAccount = (r) =>
      account?.configuredRegions?.find((i) => i.id === r.id);
    const hasRegionConfiguredInProfile = (r) =>
      user?.profile?.configuredRegions?.find((i) => i.id === r.id);

    const regionsToDisplay = [];
    for (let i = 0; i < tenant.allRegions.length; i += 1) {
      const region = tenant.allRegions[i];
      if (!(!showAllRegionOption && region.id === ALL_REGIONS)) {
        const found =
          (hasRegionConfiguredInProfile(region) ||
            isAllRegionsSelected(user?.profile?.configuredRegions)) &&
          (hasRegionAvailableInAccount(region) ||
            (isAllRegionsSelected(account?.configuredRegions) &&
              (hasRegionAvailableInTenant(region) ||
                isAllRegionsSelected(tenant?.configuredRegions))));
        regionsToDisplay.push({ region, enabled: found !== false });
      }
    }
    regionsToDisplay.sort((a, b) => {
      if (a.enabled === b.enabled) {
        return a.region.name.localeCompare(b.region.name);
      }
      return (b.enabled ? 1 : 0) - (a.enabled ? 1 : 0);
    });
    return regionsToDisplay;
  };

  const setUpRegions = () => {
    const regionsToDisplay = getRegionsForUser();
    setRegions(regionsToDisplay);

    const enabledRegions = regionsToDisplay.filter((r) => r.enabled);
    const storedRegion = localStorage.getItem(key);
    let found = false;
    for (let i = 0; i < enabledRegions.length; i += 1) {
      if (enabledRegions[i].region.id === storedRegion) {
        found = true;
      }
    }
    if (!found && enabledRegions.length > 0) {
      const hasDefaultInitialRegion = () => enabledRegions.find((i) => i.region.id === USA_REGION);
      // If the user has a region saved that is no longer available, set the region to the first available one for the user
      console.log(hasDefaultInitialRegion());
      if (hasDefaultInitialRegion()) {
        localStorage.setItem(key, USA_REGION);
        setSelectedRegion(USA_REGION);
      } else {
        localStorage.setItem(key, enabledRegions[0].region.id);
        setSelectedRegion(enabledRegions[0].region.id);
      }
      window.location.reload();
    } else if (!found && enabledRegions.length === 0 && initialRegion !== selectedRegion) {
      // If the user has no region available, show the default US region
      localStorage.setItem(key, initialRegion);
      setSelectedRegion(initialRegion);
      window.location.reload();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (typeof account === 'object' && typeof tenant === 'object') {
      let storedRegion = localStorage.getItem(key);
      if (!storedRegion) {
        localStorage.setItem(key, initialRegion);
        storedRegion = initialRegion;
        setSelectedRegion(storedRegion);
      }
      setUpRegions();
    }
  }, [account, tenant]);

  const flagCode = getCountryISO2(selectedRegion);

  return (
    <Box sx={{ minWidth: '40px', maxWidth: '40px' }}>
      {loading && selectedRegion && (
        <IconButton disabled>
          {selectedRegion !== ALL_REGIONS && (
            <img
              style={{ filter: 'drop-shadow(0px 0px 0px black)' }}
              alt={`${selectedRegion} flag`}
              src={`https://flagsapi.com/${flagCode}/flat/${size}.png`}
            />
          )}
          {selectedRegion === ALL_REGIONS && <Typography>ALL</Typography>}
        </IconButton>
      )}
      {!loading && (
        <>
          <IconButton
            sx={{
              borderRadius: 1,
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            onClick={handleMenu}
            data-cy="region_button"
          >
            {selectedRegion !== ALL_REGIONS && (
              <img
                style={{ filter: 'drop-shadow(0px 0px 0px black)' }}
                alt={`${selectedRegion} flag`}
                src={`https://flagsapi.com/${flagCode}/flat/${size}.png`}
              />
            )}
            {selectedRegion === ALL_REGIONS && <Typography>ALL</Typography>}
            {!anchorEl && <CaretDown />}
            {anchorEl && (
              <Box sx={{ m: 0, p: 0, transform: 'rotate(180deg) translateY(2px)' }}>
                <Icon>
                  <CaretDown />
                </Icon>
              </Box>
            )}
          </IconButton>
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ dense: true }}
          >
            <MenuItem disabled value="">
              Select a seller region...
            </MenuItem>
            {regions.map((r) => (
              <MenuItem
                key={r.region.id}
                value={r.region.id}
                onClick={() => handleMenuSelect(r.region.id)}
                disabled={!r.enabled}
                data-cy={`region_${r.region.id}`}
                sx={{paddingBottom: '3x' }}
              >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {r.id !== ALL_REGIONS && (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <img
                        style={{
                          minWidth: '24px',
                          minHeight: '24px',
                          filter: 'drop-shadow(0px 0px 0px black)',
                        }}
                        alt={`${r.region.id} flag`}
                        src={`https://flagsapi.com/${r.region.iso}/flat/24.png`}
                      />
                    </Box>
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>
                      {r.region.name}
                    </Box>
                    <Box sx={{mt: '-4px', fontSize: '10px', color: 'greys.grey'}}>
                      {asinCounts[r.region.id] ?
                        `${asinCounts[r.region.id].toLocaleString('en-US', { maximumFractionDigits: 0 })} ASIN${asinCounts[r.region.id] === 1 ? '' : 's'}`
                        : ''
                      }
                    </Box>
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default AmazonRegionDropdown;
